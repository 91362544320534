import React, { createRef, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Alerts from "../../../helpers/Alerts";
import { initializeActionStatus, selectPropertyStatus } from "../propertySlice";
import Spinners from "../../../helpers/Spinners";
import { selectUserList } from "../../account/user/userSlice";

interface UnityDialogProps {
  unity: IUnity | undefined;
  actionType: string;
  title: string;
  dialogId: string;
  handleAddOrUpdateUnity: () => void;
  initializeUnity: () => void;
  handleChange: (objectKey: string, value: any) => void;
}

const UnityDialog: React.FC<UnityDialogProps> = (props) => {
  const {
    unity,
    actionType,
    title,
    dialogId,
    handleAddOrUpdateUnity,
    initializeUnity,
    handleChange,
  } = props;

  const dispatch = useAppDispatch();
  const status = useAppSelector(selectPropertyStatus);
  const users = useAppSelector(selectUserList);
  const closeDialogRef = createRef<HTMLSpanElement>();

  useEffect(() => {
    if (status === "success") {
      closeDialogRef.current?.click();
    }
  }, [status]);

  const formValidate = (): boolean => {
    return !unity ||
      unity.name === undefined ||
      unity.name === '' ||
      unity.rentAmount === undefined ||
      unity.rentAmount === 0 ||
      unity.agentId === undefined ||
      unity.agentId === ''
  }

  return (
    <>
      <div
        className="modal fade"
        id={dialogId}
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog dialog">
          <div className="modal-content dialog-content">
            <span
              ref={closeDialogRef}
              className="close-btn absolute z-10 ltr:right-6 rtl:left-6"
              role="button"
              data-bs-dismiss="modal"
              onClick={() => initializeUnity()}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h5 className="mb-4">{title}</h5>
            <div>
              {actionType === 'delete' ? (
                <p>Êtes-vous sûr de vouloir supprimer l'unité <strong>{unity?.name}</strong>?</p>
              ) :
                (
                  <form action="#">
                    <div className="form-container vertical">
                      <div className="form-item vertical">
                        <label className="form-label mb-2">Nom *</label>
                        <div>
                          <div className="input-group">
                            <input
                              className="input"
                              type="text"
                              name="name"
                              placeholder="Nom"
                              value={unity?.name}
                              onChange={(e) =>
                                handleChange("name", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-item vertical">
                        <label className="form-label mb-2">Montant *</label>
                        <div>
                          <div className="input-group">
                            <input
                              className="input"
                              type="number"
                              name="rentAmount"
                              placeholder="Montant"
                              value={unity?.rentAmount}
                              onChange={(e) =>
                                handleChange("rentAmount", Number(e.target.value))
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-item vertical">
                        <label className="form-label mb-2">Agent *</label>
                        <div>
                          <div className="input-group">
                            <select
                              className="input"
                              name="agentId"
                              value={unity?.agentId}
                              onChange={(e) =>
                                handleChange("agentId", e.target.value)
                              }
                            >
                              <option
                                value=""
                                label="Choisissez une option"
                              ></option>
                              {users?.filter((user) => user.roles?.some((role) => role.name === "Agent")).map((agent, index) => (
                                <option
                                  key={agent.id}
                                  value={agent.id}
                                  label={`${agent.firstName} ${agent.lastName}`}
                                />
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-item vertical">
                        <label className="form-label mb-2">
                          Pourcentage de l'agent
                        </label>
                        <div>
                          <div className="input-group">
                            <input
                              className="input"
                              type="number"
                              name="agentPercetange"
                              placeholder="Pourcentage de l'agent"
                              value={unity?.agentPercetange}
                              onChange={(e) =>
                                handleChange("agentPercetange", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                )}

            </div>
            <div className="text-right mt-6">
              <button
                className="btn btn-plain ltr:mr-2 rtl:ml-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  dispatch(initializeActionStatus());
                  initializeUnity();
                }}
              >
                {actionType === 'delete' ? 'Non' : 'Annuler'}
              </button>
              <button
                className="btn btn-solid"
                onClick={handleAddOrUpdateUnity}
                disabled={actionType === 'delete' ? status === 'pending' : (status === "pending" || formValidate())}
              >
                <span className="flex items-center justify-center">
                  {actionType === 'delete' ? 'Oui' : 'Sauvegarder'}
                  {status === "pending" && <Spinners sizeFromProps={30} />}
                </span>
              </button>
            </div>
            {status === "failed" && (
              <Alerts
                type={"alert-danger"}
                message={
                  "Erreur lors de cette opération. Veuillez reessayer!!!"
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UnityDialog;
