import React from "react";
import logo from "./logo.svg";
import { Counter } from "./features/counter/Counter";
import "./App.css";
import MainLayout from "./shared/layout/MainLayout";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { UsersPage } from "./features/account/user/UsersPage";
import { useAppSelector } from "./app/hooks";
import { selectIsLogged } from "./features/account/user/userSlice";
import { SigninPage } from "./features/account/user/SigninPage";
import { TenantsPage } from "./features/tenant/TenantsPage";
import { HomePage } from "./features/home/HomePage";
import TenantEdit from "./features/tenant/components/TenantEdit";
import { TenantDetails } from "./features/tenant/components/TenantDetails";
import LandlordEdit from "./features/landlords/components/LandlordEdit";
import { LandlordDetails } from "./features/landlords/components/LandlordDetail";
import { LandlordsPage } from "./features/landlords/LandlordsPage";
import { LeasesPage } from "./features/leases/LeasesPage";
import { PropertiesPage } from "./features/properties/PropertiesPage";
import PropertyEdit from "./features/properties/components/PropertyEdit";
import { PropertyDetails } from "./features/properties/components/PropertyDetail";
import { UserDetails } from "./features/account/user/components/UserDetail";
import { AgencyAccountOperationPage } from "./features/accountOperation/AgencyAccountOperationPage";
import { LandlordAccountOperationPage } from "./features/accountOperation/LandlordAccountOperationPage";
import { AgentAccountOperationPage } from "./features/accountOperation/AgentAccountOperationPage";
import { AccountJournalPage } from "./features/accountOperation/AccountJournalPage";
import { AgencyDetail } from "./features/agency/AgencyDetail";

function App() {
  const logged = useAppSelector(selectIsLogged);

  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route
            path="/"
            element={logged ? <HomePage /> : <Navigate to="/login" />}
          />
          <Route
            path="/users"
            element={logged ? <UsersPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/users/details/:id"
            element={logged ? <UserDetails /> : <Navigate to="/login" />}
          />
          <Route path="/login" element={<SigninPage />} />
          <Route
            path="/tenants/list"
            element={logged ? <TenantsPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/tenants/details/:id"
            element={logged ? <TenantDetails /> : <Navigate to="/login" />}
          />
          <Route
            path="/tenants/create"
            element={logged ? <TenantEdit /> : <Navigate to="/login" />}
          />
          <Route
            path="/agency/details"
            element={logged ? <AgencyDetail /> : <Navigate to="/login" />}
          />
          <Route
            path="/tenants/edit/:id"
            element={logged ? <TenantEdit /> : <Navigate to="/login" />}
          />
          <Route
            path="/landlords/list"
            element={logged ? <LandlordsPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/landlords/details/:id"
            element={logged ? <LandlordDetails /> : <Navigate to="/login" />}
          />
          <Route
            path="/landlords/create"
            element={logged ? <LandlordEdit /> : <Navigate to="/login" />}
          />
          <Route
            path="/landlords/edit/:id"
            element={logged ? <LandlordEdit /> : <Navigate to="/login" />}
          />
          <Route
            path="/leases/list"
            element={logged ? <LeasesPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/tenants/details"
            element={logged ? <TenantDetails /> : <Navigate to="/login" />}
          />
          <Route
            path="/leases/create"
            element={logged ? <TenantEdit /> : <Navigate to="/login" />}
          />
          <Route
            path="/leases/edit"
            element={logged ? <TenantEdit /> : <Navigate to="/login" />}
          />
          <Route
            path="/properties/list"
            element={logged ? <PropertiesPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/properties/create"
            element={logged ? <PropertyEdit /> : <Navigate to="/login" />}
          />
          <Route
            path="/properties/edit/:id"
            element={logged ? <PropertyEdit /> : <Navigate to="/login" />}
          />
          <Route
            path="/properties/details/:id"
            element={logged ? <PropertyDetails /> : <Navigate to="/login" />}
          />
          <Route
            path="/agencyAccountOperations"
            element={
              logged ? <AgencyAccountOperationPage /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/landlordAccountOperations"
            element={
              logged ? (
                <LandlordAccountOperationPage />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/agentAccountOperations"
            element={
              logged ? <AgentAccountOperationPage /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/accountOperations"
            element={logged ? <AccountJournalPage /> : <Navigate to="/login" />}
          />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;
