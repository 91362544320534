import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../../app/store";
import {
  createUser,
  deleteUser,
  getUsers,
  getUserChartData,
  makeDeposit,
  signin,
  updateUser,
  getUserAccountOperations,
} from "./userAPI";
import { jwtDecode, JwtPayload } from "jwt-decode";
import {
  sortedArray,
  updateArrayByItemId,
} from "../../../helpers/objectMapper";

const isLogged = (): boolean => {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  if (user !== null && user !== undefined) {
    let decodedToken: JwtPayload = jwtDecode(user.accessToken!);
    if (decodedToken !== null && decodedToken !== undefined) {
      // console.log('decoded user', decodedToken);

      let currentDate = new Date();
      //JWT exp is in seconds
      if (decodedToken.exp! * 1000 < currentDate.getTime()) {
        return false;
      } else {
        return true;
      }
    }
  }
  return false;
};

const getAuthUserPermissions = (): string[] => {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  if (user !== null && user !== undefined) {
    let decodedToken = jwtDecode(user.accessToken!) as any;
    if (decodedToken !== null && decodedToken !== undefined) {
      return decodedToken.permissions.split('|');
    }
  }
  return [];
};

export interface UserState {
  userList: IUser[];
  logged: boolean;
  authUser: IUser | undefined;
  authUserPermissions: string[];
  selectedUser: IUser | null;
  userChartData?: IChartData;
  status: "pending" | "success" | "failed" | "";
  accountOperationsStatus: "pending" | "success" | "failed" | "";
  accountOperations?: IAccountOperation[];
}

const initialState: UserState = {
  userList: [],
  logged: isLogged(),
  authUser: JSON.parse(localStorage.getItem("user")!),
  authUserPermissions: getAuthUserPermissions(),
  selectedUser: null,
  userChartData: undefined,
  status: "",
  accountOperationsStatus: "",
  accountOperations: [],
};

export const getUsersAsync = createAsyncThunk("user/getUsers", async () => {
  const response = await getUsers();
  return response.data;
});

export const signinAsync = createAsyncThunk(
  "user/signin",
  async (data: ISigninData) => {
    const response = await signin(data);
    return response.data;
  }
);

export const createUserAsync = createAsyncThunk(
  "user/create",
  async (data: ICreateOrUpdateUserData) => {
    const response = await createUser(data);
    return response.data;
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/update",
  async (data: ICreateOrUpdateUserData) => {
    const response = await updateUser(data);
    return response.data;
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/delete",
  async (userId: string) => {
    const response = await deleteUser(userId);
    return response.data;
  }
);

export const makeDepositAsync = createAsyncThunk(
  "user/makeDeposit",
  async (data: IMakeDeposit) => {
    const response = await makeDeposit(data);
    return response.data;
  }
);

export const getUserChartDataAsync = createAsyncThunk("user/getUserChartData", async (userId: string) => {
  const response = await getUserChartData(userId);
  return response.data;
});

export const getUserAccountOperationDataAsync = createAsyncThunk("user/getUserAccountOperations", async (userId: string) => {
  const response = await getUserAccountOperations(userId);
  return response.data;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signout: (state) => {
      state.logged = false;
      state.authUser = undefined;
    },
    initializeActionStatus: (state) => {
      state.status = "";
    },
    setSelectedUser: (state, action: PayloadAction<IUser>) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.userList = sortedArray(action.payload) as IUser[];
      })
      .addCase(getUsersAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(signinAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(signinAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.authUser = action.payload;
        state.logged = true;
      })
      .addCase(signinAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createUserAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.status = "success";
        let user = action.payload;
        // state.userList = action.payload;
      })
      .addCase(createUserAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.userList = action.payload;
      })
      .addCase(updateUserAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteUserAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.userList = action.payload;
      })
      .addCase(deleteUserAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(makeDepositAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(makeDepositAsync.fulfilled, (state, action) => {
        state.userList = updateArrayByItemId(
          [...state.userList],
          action.payload
        ) as IUser[];
        state.status = "success";
      })
      .addCase(makeDepositAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getUserChartDataAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getUserChartDataAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.userChartData = action.payload;
      })
      .addCase(getUserChartDataAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getUserAccountOperationDataAsync.pending, (state) => {
        state.accountOperationsStatus = "pending";
      })
      .addCase(getUserAccountOperationDataAsync.fulfilled, (state, action) => {
        state.accountOperationsStatus = "success";
        state.accountOperations = action.payload;
      })
      .addCase(getUserAccountOperationDataAsync.rejected, (state) => {
        state.accountOperationsStatus = "failed";
      });
  },
});

export const { signout, initializeActionStatus, setSelectedUser } =
  userSlice.actions;

export const selectUserList = (state: RootState) => state.user.userList;
export const selectIsLogged = (state: RootState) => state.user.logged;
export const selectAuthUser = (state: RootState) => state.user.authUser;
export const selectAuthUserPermissions = (state: RootState) => state.user.authUserPermissions;
export const selectSelectedUser = (state: RootState) => state.user.selectedUser;
export const selectUserChartData = (state: RootState) => state.user.userChartData;
export const selectUserAccountOperations = (state: RootState) => state.user.accountOperations;
export const selectUserStatus = (state: RootState) => state.user.status;
export const selectAccountOperationsStatus = (state: RootState) => state.user.accountOperationsStatus;


export default userSlice.reducer;
