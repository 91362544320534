import { rejects } from "assert";
import axios from "axios";
import {
  mapFormDataToObject,
  mapObjectToFormData,
} from "../../helpers/objectMapper";
import { api } from "../../services/apiHelpers";

const axiosHeadersConfig = () => {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  if (user.accessToken) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + user.accessToken;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export function getLandlords() {
  axiosHeadersConfig();
  return new Promise<{ data: ILandlord[]; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + "/Landlords/list")
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function createLandlord(data: FormData) {
  return new Promise<{ data: ILandlord; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Landlords/create", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function updateLandlord(data: FormData) {
  return new Promise<{ data: ILandlord; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Landlords/update", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function deleteLandlord(landlordId: string) {
  return new Promise<{ data: string; status: number }>((resolve, reject) => {
    axios
      .delete(api + `/Landlords/${landlordId}`)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function makeDeposit(data: IMakeDeposit) {
  return new Promise<{ data: ILandlord; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Landlords/makeDeposit", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function getLandlordChartData(id: string) {
  axiosHeadersConfig();
  return new Promise<{ data: IChartData; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Landlords/charts/accountOperations/${id}`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function getLandlordAccountOperations(id: string) {
  axiosHeadersConfig();
  return new Promise<{ data: IAccountOperation[]; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Landlords/accountOperations/${id}`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}
export function getLandlordsPropertiesList(id: string) {
  axiosHeadersConfig();
  return new Promise<{ data: ILandlord[]; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Landlords/properties/list/${id}`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}