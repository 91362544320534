import React, { createRef, useEffect, useState } from 'react';
import Select from "react-select";
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Alerts from '../../../../helpers/Alerts';
import Spinners from '../../../../helpers/Spinners';
import { selectUserStatus, initializeActionStatus } from '../userSlice';


interface UserDialogProps {
    title: string;
    actionType: string;
    userToEdit?: ICreateOrUpdateUserData;
    dialogId: string;
    showDialog: boolean;
    roleList?: IRole[];
    handleCreateUser?: (user: ICreateOrUpdateUserData) => void;
    handleEditUser?: (user: ICreateOrUpdateUserData) => void;
    handleDeleteUser?: (userId: string) => void;
}

const UserDialog: React.FC<UserDialogProps> = (props) => {
    const {
        title,
        actionType,
        userToEdit,
        dialogId,
        showDialog,
        roleList,
        handleCreateUser,
        handleEditUser,
        handleDeleteUser
    } = props;

    const dispatch = useAppDispatch();
    const status = useAppSelector(selectUserStatus);

    const closeDialogRef = createRef<HTMLSpanElement>();
    const [user, setUser] = useState<ICreateOrUpdateUserData | null>(null);

    useEffect(() => {
        if (userToEdit && actionType && (actionType === 'edit' || actionType === 'delete')) {
            setUser((prevState: any) => {
                return {
                    ...prevState,
                    id: userToEdit.id,
                    lastName: userToEdit.lastName,
                    firstName: userToEdit.firstName,
                    roles: userToEdit.roles,
                    email: userToEdit.email,
                    phoneNumber2: userToEdit.phoneNumber2,
                    password: userToEdit.password,
                    phoneNumber1: userToEdit.phoneNumber1,
                    address: userToEdit.address,
                }
            });
        }
    }, [userToEdit, actionType]);

    useEffect(() => {
        if (!showDialog) {
            closeDialogRef.current?.click();
        }
    }, [closeDialogRef, showDialog]);

    useEffect(() => {
        if (status === 'success') {
            closeDialogRef.current?.click();
        }
    }, [status])

    const submit = (e: React.FormEvent) => {
        e.preventDefault();
        if (actionType === 'add') {
            handleCreateUser!(user!);
        }
        else if (actionType === 'edit') {
            handleEditUser!(user!);
        }
        else if (actionType === 'delete') {
            handleDeleteUser!(user?.id!);
        }

    };

    const validateFormWithoutPassword = (): boolean => {
        return (
            user === null ||
            user.firstName === undefined ||
            user.firstName === "" ||
            user.lastName === undefined ||
            user.lastName === "" ||
            user.email === undefined ||
            user.email === "" ||
            user.password === undefined ||
            user.password === "" ||
            user.phoneNumber1 === undefined ||
            user.phoneNumber1 === "" ||
            user.roles === undefined ||
            user.roles.length === 0
        );
    }

    const validatePassword = (): boolean => {
        return (
            user === null ||
            user.password === undefined ||
            user.password === ""
        )
    };

    const formIsInvalid = (): boolean => {
        return actionType == 'add' ? validateFormWithoutPassword() || validatePassword() : validateFormWithoutPassword();
    };

    const initializeForm = () => {
        if (actionType === "add") {
            setUser({
                id: "",
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                phoneNumber1: "",
                phoneNumber2: "",
                address: "",
                roles: [],
            });
        }
    };

    function getDialogButtonActions() {
        return (
            <>
                {
                    (actionType === 'add' || actionType === 'edit' || actionType === '') ?
                        <div className="text-right mt-6">
                            <button
                                className="btn btn-plain ltr:mr-2 rtl:ml-2"
                                data-bs-dismiss="modal"
                                onClick={() => dispatch(initializeActionStatus())}
                            >
                                Annuler
                            </button>
                            <button
                                className="btn btn-solid"
                                onClick={submit}
                                disabled={formIsInvalid()}
                            >
                                <span className="flex items-center justify-center">
                                    Sauvegarder
                                    {status === 'pending' && (
                                        <Spinners sizeFromProps={30} />
                                    )}
                                </span>
                            </button>
                        </div> :
                        actionType === 'delete' && (
                            <div className="text-right mt-6">
                                <button
                                    className="btn btn-plain ltr:mr-2 rtl:ml-2"
                                    data-bs-dismiss="modal"
                                    onClick={() => dispatch(initializeActionStatus())}
                                >
                                    Non
                                </button>
                                <button
                                    className="btn btn-solid"
                                    onClick={submit}
                                    disabled={status === 'pending'}
                                >
                                    <span className="flex items-center justify-center">
                                        Oui
                                        {status === 'pending' && (
                                            <Spinners sizeFromProps={30} />
                                        )}
                                    </span>
                                </button>
                            </div>
                        )
                }
            </>
        )
    }

    return (
        <>
            <div className="modal fade"
                id={dialogId}
                tabIndex={-1}
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog dialog">
                    <div className="modal-content dialog-content">
                        <span
                            ref={closeDialogRef}
                            className="close-btn absolute z-10 ltr:right-6 rtl:left-6"
                            role="button" data-bs-dismiss="modal"
                            onClick={() => dispatch(initializeActionStatus())}
                        >
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </span>
                        <h5 className="mb-4">{title}</h5>
                        <div>
                            {actionType === 'delete' ? (
                                <p>Êtes-vous sûr de vouloir supprimer l'utilisateur <strong>{user?.firstName} {user?.lastName}</strong>?</p>
                            ) : (
                                <form>

                                    <div className="form-item vertical">
                                        <div className="form-item vertical">
                                            <label className="form-label mb-2">Nom *</label>
                                            <input
                                                className="input"
                                                type="text"
                                                name="lastName"
                                                value={user?.lastName}
                                                onChange={(e) => {
                                                    let val = e.target?.value;
                                                    setUser((prevState: any) => { return { ...prevState, lastName: val } });
                                                }}
                                            />
                                        </div>
                                        <div className="form-item vertical">
                                            <label className="form-label mb-2">Prénom *</label>
                                            <input
                                                className="input"
                                                type="text"
                                                name="firstName"
                                                value={user?.firstName}
                                                onChange={(e) => {
                                                    let val = e.target?.value;
                                                    setUser((prevState: any) => { return { ...prevState, firstName: val } });
                                                }}
                                            />
                                        </div>
                                        <div className="form-item vertical">
                                            <label className="form-label mb-2">Email *</label>
                                            <input
                                                className="input"
                                                type="email"
                                                name="email"
                                                value={user?.email}
                                                onChange={(e) => {
                                                    let val = e.target?.value;
                                                    setUser((prevState: any) => { return { ...prevState, email: val } });
                                                }}
                                            />
                                        </div>
                                        {actionType === "add" && (
                                            <div className="form-item vertical">
                                                <label className="form-label mb-2">Mot de passe *</label>
                                                <input
                                                    className="input"
                                                    type="password"
                                                    name="password"
                                                    value={user?.password}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setUser((prevState: any) => { return { ...prevState, password: val } });
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div className="form-item vertical">
                                            <label className="form-label mb-2">Numéro de téléphone 1 *</label>
                                            <input
                                                className="input"
                                                type="text"
                                                name="phoneNumber1"
                                                value={user?.phoneNumber1}
                                                onChange={(e) => {
                                                    let val = e.target?.value;
                                                    setUser((prevState: any) => { return { ...prevState, phoneNumber1: val } });
                                                }}
                                            />
                                        </div>
                                        <div className="form-item vertical">
                                            <label className="form-label mb-2">Numéro de téléphone 2 </label>
                                            <input
                                                className="input"
                                                type="text"
                                                name="phoneNumber2"
                                                value={user?.phoneNumber2}
                                                onChange={(e) => {
                                                    let val = e.target?.value;
                                                    setUser((prevState: any) => { return { ...prevState, phoneNumber2: val } });
                                                }}
                                            />
                                        </div>
                                        <div className="form-item vertical">
                                            <label className="form-label mb-2">Adresse </label>
                                            <input
                                                className="input"
                                                type="text"
                                                name="address"
                                                value={user?.address}
                                                onChange={(e) => {
                                                    let val = e.target?.value;
                                                    setUser((prevState: any) => { return { ...prevState, address: val } });
                                                }}
                                            />
                                        </div>
                                        <div className="form-item vertical">
                                            <label className="form-label mb-2">Roles *</label>
                                            <div>
                                                <Select
                                                    isMulti
                                                    value={roleList?.map((role) => ({ value: role.id, label: role.name })).filter((opt) =>
                                                        user?.roles?.some((r) => r === opt.value)
                                                    )}
                                                    options={roleList?.map((role) => ({ value: role.id, label: role.name }))}
                                                    onChange={(option) => {
                                                        setUser((prevState: any) => { return { ...prevState, roles: option.map(opt => opt.value) } })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                        {getDialogButtonActions()}
                        {status === 'failed' && (
                            <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserDialog;
