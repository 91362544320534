import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import {
  addNewItemToObjectArray,
  mapObjectToFormData,
  removeItemFromObjectArray,
  sortedArray,
  updateArrayByItemId,
} from "../../helpers/objectMapper";
import { getHomeChartData } from "./homeAPI";

export interface HomeState {
  status: "pending" | "success" | "failed" | "";
  homeData?: IHomeData;
}

const initialState: HomeState = {
  status: "",
  homeData: undefined
};

export const getHomeChartDataAsync = createAsyncThunk("Pages/dashboard", async () => {
  const response = await getHomeChartData();
  return response.data;
}
);


export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    initializeActionStatus: (state) => {
      state.status = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeChartDataAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getHomeChartDataAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.homeData = action.payload;
      })
      .addCase(getHomeChartDataAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { initializeActionStatus, } = homeSlice.actions;

export const selectHomeData = (state: RootState) => state.home.homeData;
export const selectHomeStatus = (state: RootState) => state.home.status;

export default homeSlice.reducer;
