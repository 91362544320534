import React, { useEffect, useState } from "react";
import Spinners from "../../../helpers/Spinners";

interface BasicInformationSectionProps {
  property: IProperty;
  landLords: ILandlord[];
  saving: boolean;
  activeNav?: (itemId: string, sectionId: string) => void;
  handleChange: (objectKey: string, value: any) => void;
  submit: () => void;
}

const BasicInformationSection: React.FC<BasicInformationSectionProps> = (
  props
) => {
  const { property, saving, handleChange, activeNav, submit, landLords } = props;

  useEffect(() => {
    if (property && property.type) {
      activeSelectedCard(property.type!);
    }
    console.log('property change', property);

  }, [property]);

  const activeSelectedCard = (cardId: string) => {
    disableCard();
    activeCard(cardId);
  };

  const disableCard = () => {
    let cards = document.getElementsByClassName("property-type");
    for (let index = 0; index < cards.length; index++) {
      const element = cards[index];
      element.classList.remove("ring-1");
      element.classList.remove("ring-primary-600");
      element.classList.remove("border-primary-600");
    }
    let cardIcons = document.getElementsByClassName("property-type-card");
    for (let index = 0; index < cardIcons.length; index++) {
      const element = cardIcons[index];
      element.classList.remove("text-primary-600");
    }
  };

  const activeCard = (cardId: string) => {
    let activeSelectedCard = document.getElementById(cardId);
    activeSelectedCard?.classList.add("ring-1");
    activeSelectedCard?.classList.add("ring-primary-600");
    activeSelectedCard?.classList.add("border-primary-600");
    activeSelectedCard
      ?.getElementsByClassName("property-type-card")[0]
      .classList.add("text-primary-600");
  };

  const formValidate = (): boolean => {
    return !property ||
      property.type === undefined ||
      property.type === '' ||
      property.landlordId === undefined ||
      property.landlordId === '' ||
      property.name === undefined ||
      property.name === '' ||
      property.agencyPercetange === undefined ||
      property.agencyPercetange === 0
  }

  return (
    <>
      <div id="basic-information-section" className="content-section">
        <div className="mb-8">
          <h3 className="mb-2">Information de base</h3>
          <p>Saisissez les informations de base du bien immobilier</p>
        </div>
        <form action="#">
          <div className="form-container vertical">
            <div className="form-item vertical">
              <label className="form-label mb-2">Type du bien *</label>
              <div>
                <div className="segment flex xl:items-center flex-col xl:flex-row gap-4">
                  <div
                    id="MaisonFamiliale"
                    className="property-type flex justify-between items-center border rounded-md  border-gray-200 dark:border-gray-600 py-5 px-4 cursor-pointer select-none w-100 w-full xl:w-[260px]"
                    onClick={() => {
                      handleChange("type", "MaisonFamiliale");
                      activeSelectedCard("MaisonFamiliale");
                    }}
                  >
                    <div className="flex items-center">
                      <span className="property-type-card inline-flex text-4xl mr-2">
                        <svg
                          viewBox="0 0 480 480"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                        >
                          <path
                            id="XMLID_1781_"
                            d="M317.851,360c0,5.522-4.477,10-10,10H142.656c-5.523,0-10-4.478-10-10s4.477-10,10-10h165.195
                            C313.374,350,317.851,354.478,317.851,360z M320.354,190c0,55.141-44.859,100-99.997,100h-0.218c-55.138,0-99.997-44.859-99.997-100
                            S165,90,220.139,90h0.218C275.495,90,320.354,134.859,320.354,190z M140.763,180h39.45v-50c0-3.461,0.442-6.821,1.272-10.026
                            C159.625,132.09,144.001,154.149,140.763,180z M181.485,260.026c-0.831-3.205-1.272-6.565-1.272-10.026v-50h-39.45
                            C144.001,225.851,159.626,247.91,181.485,260.026z M240.283,200h-40.071v50c0,11.028,8.972,20,19.999,20
                            c11.1,0,20.072-8.972,20.072-20V200z M240.283,130c0-11.028-8.971-20-19.999-20c-11.1,0-20.072,8.972-20.072,20v50h40.071V130z
                                M299.732,200h-39.45v50c0,3.461-0.442,6.821-1.272,10.026C280.87,247.91,296.494,225.851,299.732,200z M299.732,180
                            c-3.238-25.851-18.863-47.91-40.722-60.026c0.831,3.205,1.272,6.565,1.272,10.026v50H299.732z M476.933,140.264l-96.509,232.907V430
                            c0,27.57-22.429,50-49.999,50H29.999C13.458,480,0,466.542,0,450V30C0,13.458,13.458,0,29.999,0h300.427
                            c27.57,0,49.999,22.43,49.999,50v7.07l74.825,30.932c9.881,4.084,17.579,11.77,21.675,21.64
                            C481.022,119.514,481.025,130.39,476.933,140.264z M60.071,20H29.999c-5.514,0-10,4.486-10,10v420c0,5.514,4.486,10,10,10h30.072V20
                            z M360.425,50c0-16.542-13.458-30-29.999-30H80.07v440h250.356c16.542,0,29.999-13.458,29.999-30V151.84
                            c-0.161-1.017-0.166-2.064,0-3.108V64.02c-0.005-0.178-0.005-0.354,0-0.533V50z M458.453,117.308
                            c-2.048-4.936-5.899-8.779-10.844-10.823l-67.185-27.772v64.938l16.443,6.798c5.104,2.109,7.531,7.957,5.421,13.062
                            c-2.11,5.104-7.959,7.529-13.062,5.421l-8.802-3.639v155.634l55.058-132.873l-10.636-4.397c-5.104-2.109-7.531-7.958-5.421-13.062
                            c2.111-5.104,7.957-7.532,13.062-5.421l10.653,4.403l15.318-36.969C460.502,127.674,460.5,122.24,458.453,117.308z"
                          ></path>
                        </svg>
                      </span>
                      <h6>Maison familiale</h6>
                    </div>
                    {property && property.type === "MaisonFamiliale" && (
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="text-primary-600 text-2xl"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    )}
                  </div>
                  <div
                    id="Appartements"
                    className="property-type flex justify-between items-center border rounded-md  border-gray-200 dark:border-gray-600 py-5 px-4 cursor-pointer select-none w-100 w-full xl:w-[260px]"
                    onClick={() => {
                      handleChange("type", "Appartements");
                      activeSelectedCard("Appartements");
                    }}
                  >
                    <div className="flex items-center">
                      <span className="property-type-card inline-flex text-4xl mr-2">
                        <svg
                          viewBox="0 0 512 512"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                        >
                          <g>
                            <g>
                              <g>
                                <path
                                  d="M458.667,341.333h-85.333c-5.888,0-10.667,4.779-10.667,10.667c0,5.888,4.779,10.667,10.667,10.667h85.333
                                  c5.888,0,10.667-4.779,10.667-10.667C469.333,346.112,464.555,341.333,458.667,341.333z"
                                ></path>
                                <path
                                  d="M309.333,234.667h64c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667h-64
                                  c-5.888,0-10.667,4.779-10.667,10.667S303.445,234.667,309.333,234.667z"
                                ></path>
                                <path
                                  d="M416,234.667h42.667c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667H416
                                  c-5.888,0-10.667,4.779-10.667,10.667S410.112,234.667,416,234.667z"
                                ></path>
                                <path
                                  d="M309.333,298.667h149.333c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667H309.333
                                  c-5.888,0-10.667,4.779-10.667,10.667S303.445,298.667,309.333,298.667z"
                                ></path>
                                <path
                                  d="M309.333,362.667h21.333c5.888,0,10.667-4.779,10.667-10.667c0-5.888-4.779-10.667-10.667-10.667h-21.333
                                  c-5.888,0-10.667,4.779-10.667,10.667C298.667,357.888,303.445,362.667,309.333,362.667z"
                                ></path>
                                <path
                                  d="M458.667,64H53.333C23.936,64,0,87.936,0,117.333v277.333C0,424.064,23.936,448,53.333,448h405.333
                                  C488.064,448,512,424.064,512,394.667V117.333C512,87.936,488.064,64,458.667,64z M490.667,394.667c0,17.643-14.357,32-32,32
                                  H53.333c-17.643,0-32-14.357-32-32V149.333h469.333V394.667z M490.667,128H21.333v-10.667c0-17.643,14.357-32,32-32h405.333
                                  c17.643,0,32,14.357,32,32V128z"
                                ></path>
                                <path
                                  d="M53.333,405.333l213.333-0.021c5.888,0,10.667-4.779,10.667-10.667c0-26.731-18.112-49.941-44.011-56.427l-28.608-7.168
                                  c19.499-4.395,29.803-10.368,30.315-10.688c2.965-1.749,4.757-4.928,5.056-8.363c0.299-3.413-1.195-6.891-3.819-9.131
                                  c-0.149-0.128-14.549-12.971-14.549-56.597c0-44.011-11.392-66.325-33.856-66.325h-0.384c-7.147-6.4-13.824-9.28-27.456-9.28
                                  c-19.328,0-61.675,19.029-61.675,75.584c0,43.499-14.315,56.405-14.293,56.405c-2.859,2.112-4.437,5.525-4.267,9.067
                                  c0.192,3.541,2.112,6.763,5.163,8.597c0.512,0.299,10.709,6.336,30.315,10.752l-28.544,7.147
                                  c-25.941,6.485-44.053,29.696-44.053,56.448c0,2.837,1.109,5.568,3.115,7.552C47.787,404.203,50.496,405.333,53.333,405.333z
                                  M91.861,358.891l38.229-9.557c3.819-0.939,6.827-3.925,7.765-7.744l3.968-15.829c0.747-2.923,0.192-6.037-1.493-8.555
                                  c-1.707-2.496-4.373-4.16-7.381-4.587c-11.072-1.536-19.691-3.84-25.813-5.909c6.08-10.923,12.523-29.739,12.523-60.459
                                  C119.659,201.429,153.365,192,160,192c9.493,0,10.56,1.067,15.616,6.144c2.005,1.984,4.715,3.115,7.552,3.115h4.629
                                  c6.229,0,12.523,13.909,12.523,44.992c0,30.677,6.443,49.493,12.501,60.416c-6.144,2.069-14.784,4.395-25.813,5.931
                                  c-2.987,0.427-5.675,2.091-7.36,4.608c-1.685,2.496-2.24,5.611-1.493,8.555l3.968,15.851c0.96,3.797,3.947,6.805,7.765,7.744
                                  l38.229,9.557c12.843,3.221,22.656,12.907,26.325,25.088l-188.885-0.021C69.248,371.819,79.061,362.133,91.861,358.891z"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <h6>Appartements</h6>
                    </div>
                    {property && property.type === "Appartements" && (
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="text-primary-600 text-2xl"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    )}
                  </div>
                  <div
                    id="Autre"
                    className="property-type flex justify-between items-center border rounded-md  border-gray-200 dark:border-gray-600 py-5 px-4 cursor-pointer select-none w-100 w-full xl:w-[260px]"
                    onClick={() => {
                      handleChange("type", "Autre");
                      activeSelectedCard("Autre");
                    }}
                  >
                    <div className="flex items-center">
                      <span className="property-type-card inline-flex text-4xl mr-2">
                        <svg
                          viewBox="0 0 512 512"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                        >
                          <g>
                            <g>
                              <g>
                                <path
                                  d="M196.437,218.56c-0.917,11.499-6.827,23.424-15.403,31.125c-2.923,2.624-4.181,6.677-3.221,10.517l4.352,17.429
                                    c0.96,3.819,3.925,6.805,7.765,7.765l38.208,9.536c5.269,1.301,10.176,3.84,14.592,7.552c4.523,3.776,11.243,3.2,15.04-1.344
                                    c3.755-4.523,3.157-11.243-1.344-15.04c-6.912-5.781-14.677-9.771-23.104-11.904l-32.021-7.979l-1.344-5.376
                                    c8.299-9.173,14.208-20.885,16.64-33.045c5.696-2.944,9.856-8.555,10.688-15.275l2.304-18.517
                                    c0.704-5.611-1.024-11.307-4.757-15.552c-1.152-1.323-2.475-2.475-3.904-3.435l0.555-11.328l1.941-1.963
                                    c5.504-5.824,12.949-18.325,1.173-36.309c-5.589-8.533-17.088-18.752-40.192-18.752c-6.805,0-22.144,0-37.035,9.365
                                    c-43.904,1.536-49.067,25.195-49.067,43.051c0,3.52,0.64,10.112,1.237,15.616c-1.579,1.003-3.008,2.24-4.288,3.669
                                    c-3.797,4.288-5.547,10.005-4.821,15.637l2.304,18.56c0.875,6.955,5.291,12.715,11.797,15.552
                                    c2.389,11.648,7.979,22.891,15.765,31.851l-1.557,6.272l-32.021,7.979c-25.92,6.485-44.053,29.696-44.053,56.448
                                    c0,5.888,4.779,10.667,10.667,10.667h150.549c5.888,0,10.667-4.779,10.667-10.667S209.771,320,203.883,320L65.6,319.979
                                    c3.648-12.181,13.483-21.867,26.325-25.067l38.229-9.536c3.819-0.96,6.805-3.925,7.765-7.765l4.544-18.197
                                    c0.939-3.733-0.235-7.701-3.072-10.347c-8.299-7.829-13.781-19.243-14.699-30.549c-0.448-5.547-5.184-8.597-10.752-8.597
                                    l-2.752-17.003c3.072,0,5.973-1.323,8-3.605c2.027-2.325,2.965-5.355,2.581-8.405c-0.811-6.443-2.112-18.069-2.112-21.845
                                    c0-11.029,0-21.461,31.189-21.781c2.176-0.021,4.309-0.704,6.101-2.005C167.104,128,178.795,128,184.405,128
                                    c16.384,0,20.843,6.827,22.4,9.109c4.331,6.656,2.56,8.533,1.365,9.813l-4.629,4.629c-1.877,1.877-2.987,4.373-3.115,7.019
                                    l-1.152,23.232c-0.128,2.901,0.213,4.907,2.219,7.04c1.984,2.112,4.053,2.517,6.955,2.56l-1.387,17.323
                                    C201.493,208.725,196.885,212.992,196.437,218.56z"
                                ></path>
                                <path
                                  d="M309.333,170.667h64c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667h-64
                                                                                c-5.888,0-10.667,4.779-10.667,10.667S303.445,170.667,309.333,170.667z"
                                ></path>
                                <path
                                  d="M458.667,0H53.333C23.915,0,0,23.915,0,53.333v277.333C0,360.085,23.915,384,53.333,384h157.824
                                  c5.909,0,10.667-4.779,10.667-10.667c0-5.888-4.779-10.667-10.667-10.667H53.333c-17.643,0-32-14.357-32-32V85.333h469.333
                                  v209.28c0,5.888,4.779,10.667,10.667,10.667c5.888,0,10.667-4.779,10.667-10.667V53.333C512,23.915,488.085,0,458.667,0z
                                  M490.667,64H21.333V53.333c0-17.643,14.357-32,32-32h405.333c17.643,0,32,14.357,32,32V64z"
                                ></path>
                                <path
                                  d="M458.667,170.667c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667H416
                                  c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667H458.667z"
                                ></path>
                                <path
                                  d="M512,341.333c0-8.917-6.635-15.147-16.128-15.147c-5.355,0-14.528,2.752-22.421,7.296
                                  c-10.432-22.016-27.499-41.536-39.723-47.616c-8.085-4.032-35.627-8.533-60.395-8.533c-24.768,0-52.309,4.501-60.416,8.533
                                  c-12.181,6.08-29.248,25.6-39.701,47.616c-7.915-4.544-17.088-7.296-22.443-7.296c-4.053,0-7.531,1.045-10.325,3.157
                                  c-3.712,2.795-5.781,7.061-5.781,11.989c0,16.64,12.907,28.181,14.4,29.44c1.152,0.981,2.475,1.6,3.84,2.027
                                  c-5.099,14.144-7.573,29.845-7.573,43.2c0,15.936,1.472,43.115,10.667,57.003v17.664c0,11.968,9.365,21.333,21.333,21.333h21.333
                                  c11.968,0,21.333-9.365,21.333-21.333v-7.232c12.885,3.221,32.981,7.232,53.333,7.232c20.352,0,40.448-4.011,53.333-7.232v7.232
                                  c0,11.968,9.365,21.333,21.333,21.333h21.333c11.968,0,21.333-9.365,21.333-21.333v-17.664
                                  c9.195-13.888,10.667-41.067,10.667-57.003c0-13.355-2.475-29.056-7.573-43.2c1.365-0.405,2.688-1.024,3.84-2.027
                                  C499.093,369.515,512,357.973,512,341.333z M322.411,304.981c3.989-1.835,26.752-6.315,50.923-6.315s46.933,4.48,50.859,6.272
                                  c8.085,4.053,26.859,25.323,34.325,48.512c-20.053,2.816-67.456,9.216-85.184,9.216c-17.728,0-65.152-6.4-85.205-9.216
                                  C295.595,330.261,314.368,308.992,322.411,304.981z M262.165,353.813c-0.683-0.683-1.728-1.792-2.965-3.605
                                  c1.408,0.661,2.752,1.408,4.011,2.155C262.869,352.853,262.507,353.301,262.165,353.813z M298.667,490.667h-21.333V480h21.333
                                  V490.667z M469.333,490.667H448V480h21.333V490.667z M474.24,458.667h-36.907c-1.131,0-2.261,0.192-3.371,0.533
                                  c-0.299,0.107-30.848,10.133-60.629,10.133c-29.781,0-60.331-10.027-60.629-10.112c-0.363-0.107-0.725-0.021-1.088-0.085
                                  c-0.747-0.171-1.472-0.469-2.283-0.469h-36.949c-2.795-6.379-5.739-21.077-5.739-42.645c0-3.392,0.256-6.997,0.64-10.645
                                  c14.336,0.405,31.381,5.248,31.381,10.624c0,5.888,4.779,10.667,10.667,10.667S320,421.888,320,416
                                  c0-26.069-35.2-30.869-48.533-31.765c1.195-3.904,2.624-7.573,4.245-11.029c0.021,0,0.043,0.021,0.064,0.021
                                  c2.987,0.448,73.408,10.773,97.557,10.773c24.149,0,94.571-10.325,97.557-10.773c0.021,0,0.021-0.021,0.043-0.021
                                  c1.621,3.456,3.051,7.125,4.245,11.029c-13.333,0.896-48.512,5.717-48.512,31.765c0,5.888,4.779,10.667,10.667,10.667
                                  c5.888,0,10.667-4.779,10.667-10.667c0-5.483,16.939-10.176,31.36-10.624c0.384,3.648,0.64,7.253,0.64,10.624
                                  C480,437.589,477.035,452.288,474.24,458.667z M484.48,353.792c-0.341-0.491-0.683-0.96-1.045-1.429
                                  c1.259-0.747,2.624-1.515,4.011-2.176C486.4,351.765,485.333,352.939,484.48,353.792z"
                                ></path>
                                <path
                                  d="M309.333,234.667h149.333c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667H309.333
                                  c-5.888,0-10.667,4.779-10.667,10.667S303.445,234.667,309.333,234.667z"
                                ></path>
                                <path
                                  d="M394.667,405.333H352c-5.888,0-10.667,4.779-10.667,10.667c0,5.888,4.779,10.667,10.667,10.667h42.667
                                  c5.888,0,10.667-4.779,10.667-10.667C405.333,410.112,400.555,405.333,394.667,405.333z"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <h6>Autre</h6>
                    </div>
                    {property && property.type === "Autre" && (
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="text-primary-600 text-2xl"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="md:grid grid-cols-1 gap-4">
              <div className="form-item vertical">
                <label className="form-label mb-2">Nom du propriétaire *</label>
                <div>
                  <select
                    className="input"
                    name="landlordId"
                    value={property?.landlordId}
                    onChange={(e) => handleChange("landlordId", e.target.value)}
                  >
                    <option value="" label="Choisissez une option"></option>
                    {landLords?.map((landlord, index) => (
                      <option
                        key={landlord.id}
                        value={landlord.id}
                        label={`${landlord.firstName} ${landlord.lastName}`}
                      />
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="md:grid grid-cols-2 gap-4">
              <div className="form-item vertical">
                <label className="form-label mb-2">Nom du bien *</label>
                <div>
                  <input
                    className="input"
                    type="text"
                    name="name"
                    placeholder="Nom du bien"
                    value={property?.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </div>
              </div>
              <div className="form-item vertical">
                <label className="form-label mb-2">Pourcentage de l'agence *</label>
                <div>
                  <input
                    className="input"
                    type="number"
                    name="agencyPercetange"
                    placeholder="Pourcentage de l'agence"
                    value={property?.agencyPercetange}
                    onChange={(e) => handleChange("agencyPercetange", Number(e.target.value))}
                  />
                </div>
              </div>
            </div>
            <div className="md:grid grid-cols-2 gap-4">
              <div className="form-item vertical">
                <label className="form-label mb-2">Adresse</label>
                <div>
                  <div className="input-group">
                    <input
                      className="input"
                      type="text"
                      name="addressLine"
                      placeholder="Adresse"
                      value={property?.addressLine}
                      onChange={(e) =>
                        handleChange("addressLine", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-item vertical">
                <label className="form-label mb-2">Commune</label>
                <div>
                  <div className="input-group">
                    <input
                      className="input"
                      type="text"
                      name="town"
                      placeholder="Commune"
                      value={property?.town}
                      onChange={(e) => handleChange("town", e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:grid grid-cols-2 gap-4">
              <div className="form-item vertical">
                <label className="form-label mb-2">Ville</label>
                <div>
                  <div className="input-group">
                    <input
                      className="input"
                      type="text"
                      name="city"
                      placeholder="Ville"
                      value={property?.city}
                      onChange={(e) => handleChange("city", e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-item vertical">
                <label className="form-label mb-2">Pays</label>
                <div>
                  <div className="input-group">
                    <input
                      className="input"
                      type="text"
                      name="country"
                      placeholder="Pays"
                      value={property?.country}
                      onChange={(e) => handleChange("country", e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <button
                className="btn btn-solid"
                type="button"
                onClick={submit}
                disabled={formValidate() || saving}
              >
                <span className="flex items-center justify-center">
                  Sauvegarder
                  {saving && (
                    <Spinners sizeFromProps={30} />
                  )}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default BasicInformationSection;
