import React, { createRef, useEffect, useState } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Alerts from "../../../helpers/Alerts";
// import { initializeActionStatus, selectPropertyStatus } from "../propertySlice";
import Spinners from "../../../helpers/Spinners";
import { selectUserList } from "../../account/user/userSlice";
import { selectTenantStatus } from "../tenantSlice";
import moment from 'moment';

interface TenantPaymentDialogProps {
  tenant: ITenant;
  payment: IPayment | undefined;
  title: string;
  dialogId: string;
  handleChange: (objectKey: string, value: any) => void;
  initializePayment: () => void;
  handleRentPayment?: (payment: IPayment) => void;
}

const TenantPaymentDialog: React.FC<TenantPaymentDialogProps> = (props) => {
  const {
    tenant,
    payment,
    title,
    dialogId,
    handleChange,
    initializePayment,
    handleRentPayment,
  } = props;

  const dispatch = useAppDispatch();
  const status = useAppSelector(selectTenantStatus);
  const users = useAppSelector(selectUserList);
  const closeDialogRef = createRef<HTMLSpanElement>();

  const [showPercetangeError, setShowPercetangeError] =
    useState<boolean>(false);

  useEffect(() => {
    if (status === "success") {
      closeDialogRef.current?.click();
    }
  }, [status]);

  useEffect(() => {
    let selectedLease = tenant?.leases?.find((l) => l.id === payment?.leaseId);
    if (selectedLease) {
      if (selectedLease.property?.agencyPercetange! <= 0) {
        setShowPercetangeError(true);
      } else {
        setShowPercetangeError(false);
      }
    }
  }, [payment]);

  const formValidate = (): boolean => {
    return (
      !payment ||
      payment.leaseId === undefined ||
      payment.leaseId === "" ||
      payment.amount === undefined ||
      payment.amount === 0 ||
      payment.year === undefined ||
      payment.year === 0 ||
      payment.months === undefined ||
      payment.months.length === 0 ||
      payment.paymentMethod === undefined ||
      payment.paymentMethod === ""
    );
  };

  const monthOptions: any[] = [
    { value: "Janvier", label: "Janvier" },
    { value: "Février", label: "Février" },
    { value: "Mars", label: "Mars" },
    { value: "Avril", label: "Avril" },
    { value: "Mai", label: "Mai" },
    { value: "Juin", label: "Juin" },
    { value: "Juillet", label: "Juillet" },
    { value: "Août", label: "Août" },
    { value: "Septembre", label: "Septembre" },
    { value: "Octobre", label: "Octobre" },
    { value: "Novembre", label: "Novembre" },
    { value: "Decembre", label: "Decembre" },
  ];

  const paymentMethodOptions: any[] = [
    { value: "Cash", label: "Comptant" },
    { value: "OrangeMoney", label: "Orange Money" },
    { value: "MoovMoney", label: "Moov Money" },
    { value: "Wave", label: "Wave" },
    { value: "SamaMoney", label: "Sama Money" },
    { value: "Cheque", label: "Chèque" },
  ];


  const getYearOptions = (): any[] => {
    let result: any[] = [];
    let currentYear = moment().year();
    result.push({ label: String(currentYear + 1), value: currentYear + 1 });
    result.push({ label: String(currentYear), value: currentYear });
    for (let index = 1; index < 3; index++) {
      result.push({ label: String(currentYear - index), value: currentYear - index });
    }
    return result;
  }

  const handleRent = () => {
    handleRentPayment!(payment!);
  };

  return (
    <>
      <div
        className="modal fade"
        id={dialogId}
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog dialog">
          <div className="modal-content dialog-content">
            <span
              ref={closeDialogRef}
              className="close-btn absolute z-10 ltr:right-6 rtl:left-6"
              role="button"
              data-bs-dismiss="modal"
              onClick={() => initializePayment()}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h5 className="mb-4">{title}</h5>
            <div>
              <form action="#">
                <div className="form-container vertical">
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Unité *</label>
                    <div>
                      <div className="input-group">
                        <select
                          className="input"
                          name="leaseId"
                          value={payment?.leaseId}
                          onChange={(e) => {
                            handleChange("leaseId", e.target.value);
                          }}
                        >
                          <option
                            value=""
                            label="Choisissez une option"
                          ></option>
                          {tenant?.leases?.map((lease, index) => (
                            <option
                              key={lease.id}
                              value={lease.id}
                              label={`${lease.number} - ${lease.property?.name} - ${lease.unit?.name}`}
                            />
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Montant *</label>
                    <div>
                      <div className="input-group">
                        <input
                          className="input"
                          type="number"
                          name="amount"
                          placeholder="Montant"
                          value={payment?.amount}
                          onChange={(e) =>
                            handleChange("amount", Number(e.target.value))
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">
                      Date de paiement *
                    </label>
                    <div>
                      <div className="input-group">
                        <input
                          className="input"
                          type="date"
                          name="paymentDate"
                          placeholder="Date de paiement"
                          value={payment?.paymentDate}
                          onChange={(e) =>
                            handleChange("paymentDate", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">
                      Mode de paiement *
                    </label>
                    <div>
                      <div className="input-group">
                        <select
                          className="input"
                          name="paymentMethod"
                          value={payment?.paymentMethod}
                          onChange={(e) =>
                            handleChange("paymentMethod", e.target.value)
                          }
                        >
                          <option
                            value=""
                            label="Choisissez une option"
                          ></option>
                          {paymentMethodOptions?.map((op, index) => (
                            <option
                              key={index}
                              value={op.value}
                              label={op.label}
                            />
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2"> Commentaires </label>
                    <div>
                      <div className="input-group">
                        <textarea
                          className="input input-textarea"
                          value={payment?.comments}
                          name="Comments"
                          placeholder="Mettez un commentaire ici"
                          onChange={(e) =>
                            handleChange("comments", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Année *</label>
                    <div>
                      <div className="input-group">
                        <select
                          className="input"
                          name="year"
                          value={payment?.year}
                          defaultValue={moment().year()}
                          onChange={(e) =>
                            handleChange("year", Number(e.target.value))
                          }
                        >
                          {getYearOptions()?.map((op, index) => (
                            <option
                              key={index}
                              value={op.value}
                              label={op.label}
                            />
                          ))}
                        </select>
                      </div>
                      {/* <Select
                        value={payment?.year}
                        options={getYearOptions()}
                        onChange={(option) => {
                          let val = option?.valueOf() as any
                          handleChange("year", val.value as number)
                        }
                        }
                        required
                      /> */}
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Mois *</label>
                    <div>
                      <Select
                        isMulti
                        value={
                          payment?.months?.map((m) => ({ value: m, label: m }))!
                        }
                        options={monthOptions}
                        onChange={(option) =>
                          handleChange(
                            "months",
                            option.map((opt) => opt.value)
                          )
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="text-right mt-6">
              <button
                className="btn btn-plain ltr:mr-2 rtl:ml-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  // dispatch(initializeActionStatus());
                  initializePayment();
                }}
              >
                Annuler
              </button>
              <button
                className="btn btn-solid"
                onClick={handleRent}
                disabled={
                  status === "pending" || formValidate() || showPercetangeError
                }
              >
                <span className="flex items-center justify-center">
                  Sauvegarder
                  {status === "pending" && <Spinners sizeFromProps={30} />}
                </span>
              </button>
            </div>
            {(status === "failed" || showPercetangeError) && (
              <Alerts
                type={"alert-danger"}
                message={
                  showPercetangeError
                    ? "L'agence et l'agent doivent avoir des pourcentages définit au niveau de bien et unité"
                    : "Erreur lors de cette opération. Veuillez reessayer!!!"
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantPaymentDialog;
