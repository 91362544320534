import Footer from "./Footer";
import Sidebar from "./Sidebar";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "./Header";

interface LayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<LayoutProps> = ({ children }) => {
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  return (
    <>
      {!usePathname().includes("login") ? (
        <div className="app-layout-modern flex flex-auto flex-col">
          <div className="flex flex-auto min-w-0">
            <Sidebar />
            <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-white dark:bg-gray-800 border-l border-gray-200 dark:border-gray-700">
              <Header />
              <div className="h-full flex flex-auto flex-col justify-between">
                {children}
                <Footer />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="app-layout-blank flex flex-auto flex-col h-[100vh]">{children}</div>
      )}
    </>
  );
};

export default MainLayout;
