import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AccountSummaryProps {
    person?: any;
    propertyName: string;
    deleteTarget: string;
    openDialog?: (actionType: string) => void;
}


const AccountSummary: React.FC<AccountSummaryProps> = (props) => {

    const { person, propertyName, deleteTarget, openDialog } = props;

    const navigate = useNavigate();


    return (
        <div className="card card-layout-frame">
            <div className="card-body">
                <div className="flex flex-col xl:justify-between h-full 2xl:min-w-[360px] mx-auto">
                    <div className="flex xl:flex-col">
                        <h4 className="font-bold">
                            {propertyName !== 'properties' ? person?.firstName + ' ' + person?.lastName : person?.name}
                        </h4>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-y-7 gap-x-4 mt-8">
                        {propertyName === 'properties' && (
                            <div>
                                <span>Propriétaire</span>
                                <p className="text-gray-700 dark:text-gray-200 font-semibold">
                                    {person?.landlord?.firstName + ' ' + person?.landlord?.lastName}
                                </p>
                            </div>
                        )}
                        <div>
                            <span>Adresse</span>
                            <p className="text-gray-700 dark:text-gray-200 font-semibold">
                                {person?.addressLine}
                            </p>
                        </div>
                        <div>
                            <span>Commune</span>
                            <p className="text-gray-700 dark:text-gray-200 font-semibold">
                                {person?.town}
                            </p>
                        </div>
                        <div>
                            <span>Ville</span>
                            <p className="text-gray-700 dark:text-gray-200 font-semibold">
                                {person?.city}
                            </p>
                        </div>
                        <div>
                            <span>Pays</span>
                            <p className="text-gray-700 dark:text-gray-200 font-semibold">
                                {person?.country}
                            </p>
                        </div>
                        {propertyName !== 'properties' && (
                            <>
                                <div>
                                    <span>Numéro de téléphone</span>
                                    <p className="text-gray-700 dark:text-gray-200 font-semibold">
                                        {person?.phoneNumber1!}
                                    </p>
                                </div>
                                <div>
                                    <span>Numéro de téléphone 2</span>
                                    <p className="text-gray-700 dark:text-gray-200 font-semibold">
                                        {person?.phoneNumber2!}
                                    </p>
                                </div>
                            </>
                        )}

                    </div>
                    <div className="mt-4 flex flex-col xl:flex-row gap-2">
                        <button
                            className="btn btn-default w-full"
                            data-bs-toggle="modal"
                            data-bs-target={`#${deleteTarget}`}
                            onClick={() => {
                                if (propertyName === 'users') {
                                    openDialog!('delete');
                                }
                            }}
                        >
                            <span className="flex items-center justify-center">
                                <span className="text-lg">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        ></path>
                                    </svg>
                                </span>
                                <span className="ltr:ml-1 rtl:mr-1">Delete</span>
                            </span>
                        </button>
                        {propertyName === 'users' ? (
                            <button
                                className="btn btn-solid w-full"
                                data-bs-toggle="modal"
                                data-bs-target="#editUser"
                                onClick={() => {
                                    openDialog!('edit');
                                }}
                            >
                                <span className="flex items-center justify-center">
                                    <span className="text-lg">
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                                            <path
                                                fillRule="evenodd"
                                                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span className="ltr:ml-1 rtl:mr-1">Edit</span>
                                </span>
                            </button>
                        ) : propertyName === "landlords" ?
                            (
                                <button
                                    className="btn btn-solid w-full"
                                    onClick={() => navigate(`/${propertyName}/edit/${person?.id}`)}
                                >
                                    <span className="flex items-center justify-center">
                                        <span className="text-lg">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 20 20"
                                                aria-hidden="true"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                                                <path
                                                    fillRule="evenodd"
                                                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="ltr:ml-1 rtl:mr-1">Edit</span>
                                    </span>
                                </button>
                            )
                            : (
                                <button
                                    className="btn btn-solid w-full"
                                    onClick={() => navigate(`/${propertyName}/edit/${person?.id}`)}
                                >
                                    <span className="flex items-center justify-center">
                                        <span className="text-lg">
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 20 20"
                                                aria-hidden="true"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                                                <path
                                                    fillRule="evenodd"
                                                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="ltr:ml-1 rtl:mr-1">Edit</span>
                                    </span>
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSummary