import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import {
  addNewItemToObjectArray,
  mapObjectToFormData,
  removeItemFromObjectArray,
  sortedArray,
  updateArrayByItemId,
} from "../../helpers/objectMapper";
import {
  addPayment,
  createTenant,
  deleteTenant,
  getTenants,
  updateTenant,
  getTenantChartData,
  getTenantRentPayments,
  updateRentPayment,
} from "./tenantAPI";

export interface TenantState {
  tenantList: ITenant[];
  selectedTenant: ITenant | null;
  tenantChartData?: IChartData;
  status: "pending" | "success" | "failed" | "";
  rentPaymentsStatus: "pending" | "success" | "failed" | "";
  tenantRentPayments?: IPayment[];
}

const initialState: TenantState = {
  tenantList: [],
  selectedTenant: null,
  tenantChartData: undefined,
  status: "",
  tenantRentPayments: [],
  rentPaymentsStatus: "",
};

export const getTenantsAsync = createAsyncThunk(
  "tenant/getTenants",
  async () => {
    const response = await getTenants();
    return response.data;
  }
);

export const createTenantAsync = createAsyncThunk(
  "tenant/create",
  async (data: ITenant) => {
    let formData = mapObjectToFormData(data);
    if (data.idFrontFile !== null && data.idFrontFile !== undefined) {
      formData.append("idFrontFile", data.idFrontFile!);
    }
    if (data.idBackFile !== null && data.idBackFile !== undefined) {
      formData.append("idBackFile", data.idBackFile!);
    }
    const response = await createTenant(formData);
    return response.data;
  }
);

export const updateTenantAsync = createAsyncThunk(
  "tenant/update",
  async (data: ITenant) => {
    let formData = mapObjectToFormData(data);
    if (data.idFrontFile !== null && data.idFrontFile !== undefined) {
      formData.append("idFrontFile", data.idFrontFile!);
      formData.delete("idFrontFileUrl");
    }
    if (data.idBackFile !== null && data.idBackFile !== undefined) {
      formData.append("idBackFile", data.idBackFile!);
      formData.delete("idBackFileUrl");
    }
    const response = await updateTenant(formData);
    return response.data;
  }
);

export const deleteTenantAsync = createAsyncThunk(
  "tenant/delete",
  async (tenantId: string) => {
    const response = await deleteTenant(tenantId);
    return response.data;
  }
);

export const addPaymentAsync = createAsyncThunk(
  "tenant/addPayment",
  async (data: IPayment) => {
    const response = await addPayment(data);
    return response.data;
  }
);

export const getTenantChartDataAsync = createAsyncThunk(
  "tenant/getTenantChartData",
  async (tenantId: string) => {
    const response = await getTenantChartData(tenantId);
    return response.data;
  }
);

export const getTenantRentPaymentsAsync = createAsyncThunk(
  "tenant/getTenantRentPayments",
  async (tenantId: string) => {
    const response = await getTenantRentPayments(tenantId);
    return response.data;
  }
);

export const updateTenantRentPaymentAsync = createAsyncThunk(
  "tenants/rentPayments/update",
  async (data: IUpdatePaymentData) => {
    const response = await updateRentPayment(data);
    return response.data;
  }
);

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    initializeActionStatus: (state) => {
      state.status = "";
    },
    setSelectedTenant: (state, action: PayloadAction<ITenant>) => {
      state.selectedTenant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTenantsAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getTenantsAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.tenantList = sortedArray(action.payload) as ITenant[];
      })
      .addCase(getTenantsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createTenantAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createTenantAsync.fulfilled, (state, action) => {
        state.tenantList = addNewItemToObjectArray(
          [...state.tenantList],
          action.payload
        ) as ITenant[];
        state.status = "success";
      })
      .addCase(createTenantAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateTenantRentPaymentAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateTenantRentPaymentAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.tenantRentPayments = action.payload;
      })
      .addCase(updateTenantRentPaymentAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateTenantAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateTenantAsync.fulfilled, (state, action) => {
        state.tenantList = updateArrayByItemId(
          [...state.tenantList],
          action.payload
        ) as ITenant[];
        state.status = "success";
      })
      .addCase(updateTenantAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteTenantAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(deleteTenantAsync.fulfilled, (state, action) => {
        state.tenantList = removeItemFromObjectArray(
          [...state.tenantList],
          action.payload
        ) as ITenant[];
        state.status = "success";
      })
      .addCase(deleteTenantAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(addPaymentAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(addPaymentAsync.fulfilled, (state, action) => {
        state.tenantList = updateArrayByItemId(
          [...state.tenantList],
          action.payload
        ) as ITenant[];
        state.status = "success";
      })
      .addCase(addPaymentAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getTenantChartDataAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getTenantChartDataAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.tenantChartData = action.payload;
      })
      .addCase(getTenantChartDataAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getTenantRentPaymentsAsync.pending, (state) => {
        state.rentPaymentsStatus = "pending";
      })
      .addCase(getTenantRentPaymentsAsync.fulfilled, (state, action) => {
        state.rentPaymentsStatus = "success";
        state.tenantRentPayments = action.payload;
      })
      .addCase(getTenantRentPaymentsAsync.rejected, (state) => {
        state.rentPaymentsStatus = "failed";
      });
  },
});

export const { initializeActionStatus, setSelectedTenant } =
  tenantSlice.actions;

export const selectTenantList = (state: RootState) => state.tenant.tenantList;
export const selectSelectedTenant = (state: RootState) =>
  state.tenant.selectedTenant;
export const selectTenantRentPayments = (state: RootState) =>
  state.tenant.tenantRentPayments;
export const selectTenantChartData = (state: RootState) =>
  state.tenant.tenantChartData;
export const selectTenantStatus = (state: RootState) => state.tenant.status;

export default tenantSlice.reducer;
