import React, { ChangeEvent, createRef, useEffect, useState } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Alerts from "../../../helpers/Alerts";
import Spinners from "../../../helpers/Spinners";
import { selectLeaseStatus, initializeActionStatus } from "../leaseSlice";
import { selectPropertyList } from "../../properties/propertySlice";
import { selectLandlordList } from "../../landlords/landlordSlice";
import { selectTenantList } from "../../tenant/tenantSlice";
import { Typeahead } from "react-bootstrap-typeahead";
// import { Option } from "react-bootstrap-typeahead/types/types";

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-bootstrap-typeahead/css/Typeahead.css';
// import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

interface LeaseDialogProps {
  lease: ILease;
  title: string;
  actionType: string;
  dialogId: string;
  showDialog: boolean;
  initializeLease: () => void;
  closeDialog: () => void;
  handleCreateLease?: (lease: ILease) => void;
  handleChange: (objectKey: string, value: any) => void;
  handleTerminateLease?: (lease: ILease) => void;
}

interface Option {
  id: string;
  label: string;
}

const LeaseDialog: React.FC<LeaseDialogProps> = (props) => {
  const {
    lease,
    title,
    actionType,
    dialogId,
    showDialog,
    handleChange,
    initializeLease,
    handleCreateLease,
    handleTerminateLease,
  } = props;

  const dispatch = useAppDispatch();
  const properties = useAppSelector(selectPropertyList);
  const landlords = useAppSelector(selectLandlordList);
  const tenants = useAppSelector(selectTenantList);
  const status = useAppSelector(selectLeaseStatus);

  const closeDialogRef = createRef<HTMLSpanElement>();
  const [showPercetangeError, setShowPercetangeError] =
    useState<boolean>(false);

  useEffect(() => {
    if (!showDialog) {
      closeDialogRef.current?.click();
    }
  }, [showDialog]);

  useEffect(() => {
    if (status === "success") {
      closeDialogRef.current?.click();
    }
  }, [status]);

  useEffect(() => {
    let selectedProperty = properties?.find((p) => p.id === lease?.propertyId);
    if (selectedProperty) {
      if (selectedProperty?.agencyPercetange! <= 0) {
        setShowPercetangeError(true);
      } else {
        setShowPercetangeError(false);
      }
    }
    if (lease?.unitId && lease?.unitId !== "") {
      let selectedUnit = selectedProperty?.units?.find(
        (u) => u.id === lease?.unitId!
      );
      // if (selectedUnit) {
      //   if (selectedUnit.agentPercetange! <= 0) {
      //     setShowPercetangeError(true);
      //   } else {
      //     setShowPercetangeError(false);
      //   }
      // }
    }
  }, [lease?.propertyId, lease?.unitId]);

  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    if (actionType === "add") {
      handleCreateLease!(lease!);
    }
    if (actionType === "delete") {
      handleTerminateLease!(lease!);
    }
  };

  const validateForm = (): boolean => {
    return (
      !lease ||
      lease.landlordId === undefined ||
      lease.landlordId === "" ||
      lease.propertyId === undefined ||
      lease.propertyId === "" ||
      lease.unitId === undefined ||
      lease.unitId === "" ||
      lease.tenantId === undefined ||
      lease.tenantId === "" ||
      lease.startDate === undefined ||
      lease.startDate === "" ||
      lease.rentAmount === undefined ||
      lease.rentAmount === 0 ||
      (lease.depositAmount !== undefined &&
        lease.depositAmount > 0 &&
        (lease.depositResponsible === undefined ||
          lease.depositResponsible === "" ||
          lease.depositPaymentMethod === undefined ||
          lease.depositPaymentMethod === "")) ||
      (lease.agencyFees !== undefined &&
        lease.agencyFees > 0 &&
        (lease.agencyFeesPaymentMethod === undefined ||
          lease.agencyFeesPaymentMethod === ""))
    );
  };

  const validateDeleteForm = (): boolean => {
    return (
      !lease ||
      (lease.depositAmount !== undefined &&
        lease.depositAmount > 0 &&
        (lease.refundPaymentMethod === undefined ||
          lease.refundPaymentMethod === "" ||
          lease.fullyRefundDeposit === undefined))
    );
  };

  const getPropertiesByLandlordId = (landlordId: string): IProperty[] => {
    return properties?.filter(
      (property, index) => property.landlord?.id === landlordId
    )!;
  };

  const getUnitsByPropertyId = (propertyId: string): IUnity[] => {
    const property = properties.find((property) => property.id === propertyId);
    if (property) {
      const availableUnits = property.units!.filter(
        (unit) => unit.isOccupied === false
      );
      return availableUnits;
    }
    return [];
  };

  const getTenantOptions = (): Option[] => {
    let options: Option[] = [];
    tenants.forEach((element) => {
      if (
        element.firstName != undefined &&
        element.lastName &&
        element.phoneNumber1
      )
        options.push({
          id: element.id!,
          label:
            element.firstName +
            " " +
            element.lastName +
            " - " +
            element.phoneNumber1,
        });
    });
    return options;
  };

  // const getUnitsByPropertyId = (propertyId: string): IUnity[] => {
  //   return properties
  //     ?.find((property) => property.id === propertyId)
  //     ?.units?.map((unit, inex) => unit)!;
  // };

  function getDialogButtonActions() {
    return (
      <>
        {actionType === "add" || actionType === "edit" || actionType === "" ? (
          <div className="text-right mt-6">
            <button
              className="btn btn-plain ltr:mr-2 rtl:ml-2"
              data-bs-dismiss="modal"
              onClick={() => {
                initializeLease();
                dispatch(initializeActionStatus());
              }}
            >
              Annuler
            </button>
            <button
              className="btn btn-solid"
              onClick={submit}
              disabled={
                status === "pending" || validateForm() || showPercetangeError
              }
            >
              <span className="flex items-center justify-center">
                Sauvegarder
                {status === "pending" && <Spinners sizeFromProps={30} />}
              </span>
            </button>
          </div>
        ) : (
          actionType === "delete" && (
            <div className="text-right mt-6">
              <button
                className="btn btn-plain ltr:mr-2 rtl:ml-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  initializeLease();
                  dispatch(initializeActionStatus());
                }}
              >
                Non
              </button>
              <button
                className="btn btn-solid"
                onClick={submit}
                disabled={
                  status === "pending" ||
                  validateDeleteForm() ||
                  showPercetangeError
                }
              >
                <span className="flex items-center justify-center">
                  Oui
                  {status === "pending" && <Spinners sizeFromProps={30} />}
                </span>
              </button>
            </div>
          )
        )}
      </>
    );
  }

  const monthOptions: any[] = [
    { value: "Janvier", label: "Janvier" },
    { value: "Février", label: "Février" },
    { value: "Mars", label: "Mars" },
    { value: "Avril", label: "Avril" },
    { value: "Mai", label: "Mai" },
    { value: "Juin", label: "Juin" },
    { value: "Juillet", label: "Juillet" },
    { value: "Août", label: "Août" },
    { value: "Septembre", label: "Septembre" },
    { value: "Octobre", label: "Octobre" },
    { value: "Novembre", label: "Novembre" },
    { value: "Decembre", label: "Decembre" },
  ];

  const paymentMethodOptions: any[] = [
    { value: "Cash", label: "Comptant" },
    { value: "OrangeMoney", label: "Orange Money" },
    { value: "MoovMoney", label: "Moov Money" },
    { value: "Wave", label: "Wave" },
    { value: "SamaMoney", label: "Sama Money" },
    { value: "Cheque", label: "Chèque" },
  ];

  return (
    <>
      <div
        className="modal fade"
        id={dialogId}
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog dialog md:max-w-[700px]">
          <div className="modal-content dialog-content">
            <span
              ref={closeDialogRef}
              className="close-btn absolute z-10 ltr:right-6 rtl:left-6"
              role="button"
              data-bs-dismiss="modal"
              onClick={() => {
                initializeLease();
                dispatch(initializeActionStatus());
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h5 className="mb-4">{title}</h5>
            <div>
              {actionType === "delete" ? (
                <form>
                  <div className="form-item vertical">
                    <p>
                      Êtes-vous sûr de vouloir résilier ce contrat{" "}
                      <strong>{lease?.number}</strong>?
                    </p>
                  </div>
                  {lease?.depositAmount && lease.depositAmount > 0 ? (
                    <>
                      <div className="form-item vertical">
                        <div className="radio flex gap-4">
                          Souhaitez-vous rembourser le locataire en entièreté:
                          <label className="radio-label inline-flex">
                            <input
                              type="radio"
                              className="radio"
                              name="entireRefund"
                              value="oui"
                              checked={lease?.fullyRefundDeposit === true}
                              onChange={() =>
                                handleChange("fullyRefundDeposit", true)
                              }
                            />
                            Oui
                          </label>
                          <label className="radio-label inline-flex">
                            <input
                              type="radio"
                              className="radio"
                              name="entireRefund"
                              value="non"
                              checked={lease?.fullyRefundDeposit === false}
                              onChange={() =>
                                handleChange("fullyRefundDeposit", false)
                              }
                            />
                            Non
                          </label>
                        </div>
                      </div>
                      <div className="form-item vertical">
                        <p>
                          Ce contrat a une caution de{" "}
                          <strong>{lease?.depositAmount}</strong> auprès{" "}
                          {lease.depositResponsible === "Agency" ? (
                            <>
                              de l'<strong>Agence</strong>
                            </>
                          ) : lease.depositResponsible === "Landlord" ? (
                            <>
                              du <strong>Bailleur</strong>
                            </>
                          ) : (
                            <strong>{lease.depositResponsible}</strong>
                          )}
                          .
                        </p>
                      </div>
                      <div className="form-item vertical">
                        <label className="form-label mb-2">
                          Méthod de paiement du remboursement *{" "}
                        </label>
                        <div>
                          <select
                            className="input"
                            name="refundPaymentMethod "
                            value={lease?.refundPaymentMethod}
                            onChange={(e) =>
                              handleChange(
                                "refundPaymentMethod",
                                e.target.value
                              )
                            }
                          >
                            <option
                              value=""
                              label="Choisissez une option"
                            ></option>
                            {paymentMethodOptions?.map((op, index) => (
                              <option
                                key={index}
                                value={op.value}
                                label={op.label}
                              />
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </form>
              ) : (
                <form>
                  {/* <div className="md:grid grid-cols-2 gap-4">

                  </div> */}
                  <div className="md:grid grid-cols-2 gap-4">
                    <div className="form-item vertical">
                      <label className="form-label mb-2">
                        Nom du propriétaire *
                      </label>
                      <div>
                        <select
                          className="input"
                          name="landlordId"
                          value={lease?.landlordId!}
                          onChange={(e) =>
                            handleChange("landlordId", e.target.value)
                          }
                          disabled={actionType === "edit"}
                        >
                          <option value="">Choisissez un bailleur</option>
                          {landlords?.map((landlord, index) => (
                            <option
                              key={landlord.id}
                              value={landlord.id}
                              label={`${landlord.firstName} ${landlord.lastName}`}
                            />
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-item vertical">
                      <label className="form-label mb-2">Nom du bien *</label>
                      <div>
                        <select
                          className="input"
                          name="propertyId"
                          value={lease?.propertyId!}
                          onChange={(e) =>
                            handleChange("propertyId", e.target.value)
                          }
                          disabled={actionType === "edit"}
                        >
                          <option value="">
                            Choisissez la liste des biens
                          </option>
                          {getPropertiesByLandlordId(lease?.landlordId!)?.map(
                            (property, index) => (
                              <option
                                key={property.id}
                                value={property.id}
                                label={`${property.name}`}
                              />
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:grid grid-cols-2 gap-4">
                    <div className="form-item vertical">
                      <label className="form-label mb-2">
                        Nom de l'unité *
                      </label>
                      <div>
                        <select
                          className="input"
                          name="unitId"
                          value={lease?.unitId}
                          onChange={(e) =>
                            handleChange("unitId", e.target.value)
                          }
                          disabled={actionType === "edit"}
                        >
                          <option value="">
                            Choisissez la liste des unités
                          </option>
                          {getUnitsByPropertyId(lease?.propertyId!)?.map(
                            (unity, index) => (
                              <option
                                key={unity.id}
                                value={unity.id}
                                label={`${unity.name}`}
                              />
                            )
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="form-item vertical">
                      <label className="form-label mb-2">
                        Nom du locataire *
                      </label>
                      <div className="custom-type-ahead">
                        <Typeahead
                          labelKey={(opt: any) => `${opt?.label!}`}
                          options={getTenantOptions()}
                          onChange={(e: any) => {
                            if (e.length > 0) {
                              handleChange("tenantId", e[0].id);
                            }
                          }}
                          inputProps={{
                            className: "input",
                          }}
                          placeholder="Choisissez un locataire"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:grid grid-cols-2 gap-4">
                    <div className="form-item vertical">
                      <label className="form-label mb-2">Date de début *</label>
                      <div>
                        <input
                          className="input"
                          type="date"
                          name="startDate"
                          value={lease?.startDate}
                          onChange={(e) =>
                            handleChange("startDate", e.target.value)
                          }
                          disabled={actionType === "edit"}
                        />
                      </div>
                    </div>
                    <div className="form-item vertical">
                      <label className="form-label mb-2">Date de fin </label>
                      <div>
                        <input
                          className="input"
                          type="date"
                          name="endDate"
                          value={lease?.endDate}
                          onChange={(e) =>
                            handleChange("endDate", e.target.value)
                          }
                          disabled={actionType === "edit"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">
                      Montant du loyer *
                    </label>
                    <div>
                      <input
                        className="input"
                        type="number"
                        name="rentAmount"
                        value={lease?.rentAmount}
                        onChange={(e) =>
                          handleChange("rentAmount", Number(e.target.value))
                        }
                        disabled={actionType === "edit"}
                      />
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Montant de dépôt </label>
                    <div>
                      <input
                        className="input"
                        type="number"
                        name="depositAmount"
                        value={lease?.depositAmount}
                        onChange={(e) =>
                          handleChange("depositAmount", Number(e.target.value))
                        }
                        disabled={actionType === "edit"}
                      />
                    </div>
                  </div>
                  {lease?.depositAmount && lease.depositAmount > 0 ? (
                    <>
                      <div className="form-item vertical">
                        <label className="form-label mb-2">
                          Méthode de paiement de la caution *{" "}
                        </label>
                        <div>
                          <select
                            className="input"
                            name="depositPaymentMethod"
                            value={lease?.depositPaymentMethod}
                            onChange={(e) =>
                              handleChange(
                                "depositPaymentMethod",
                                e.target.value
                              )
                            }
                            disabled={actionType === "edit"}
                          >
                            <option
                              value=""
                              label="Choisissez une option"
                            ></option>
                            {paymentMethodOptions?.map((op, index) => (
                              <option
                                key={index}
                                value={op.value}
                                label={op.label}
                              />
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-item vertical">
                        <label className="form-label mb-2">
                          Responsable de dépôt *
                        </label>
                        <div>
                          <select
                            className="input"
                            name="depositResponsible"
                            value={lease?.depositResponsible}
                            onChange={(e) =>
                              handleChange("depositResponsible", e.target.value)
                            }
                            disabled={actionType === "edit"}
                          >
                            <option value="">
                              Choisissez le responsable de dépôt
                            </option>
                            <option value="Agency">Agence</option>
                            <option value="Landlord">Bailleur</option>
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Frais d'agence </label>
                    <div>
                      <input
                        className="input"
                        type="number"
                        name="agencyFees"
                        value={lease?.agencyFees}
                        onChange={(e) =>
                          handleChange("agencyFees", Number(e.target.value))
                        }
                        disabled={actionType === "edit"}
                      />
                    </div>
                  </div>
                  {lease?.agencyFees && lease.agencyFees > 0 ? (
                    <>
                      <div className="form-item vertical">
                        <label className="form-label mb-2">
                          Méthode de paiement des frais d'agence *{" "}
                        </label>
                        <div>
                          <select
                            className="input"
                            name="agencyFeesPaymentMethod"
                            value={lease?.agencyFeesPaymentMethod}
                            onChange={(e) =>
                              handleChange(
                                "agencyFeesPaymentMethod",
                                e.target.value
                              )
                            }
                            disabled={actionType === "edit"}
                          >
                            <option
                              value=""
                              label="Choisissez une option"
                            ></option>
                            {paymentMethodOptions?.map((op, index) => (
                              <option
                                key={index}
                                value={op.value}
                                label={op.label}
                              />
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </form>
              )}
            </div>
            {getDialogButtonActions()}
            {(status === "failed" || showPercetangeError) && (
              <Alerts
                type={"alert-danger"}
                message={
                  showPercetangeError
                    ? "L'agence et l'agent doivent avoir des pourcentages définit au niveau de bien et unité"
                    : "Erreur lors de cette opération. Veuillez reessayer!!!"
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaseDialog;
