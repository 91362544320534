import React, { useEffect, useState } from "react";

interface PaymentSummaryProps {
  payment?: IPaymentSummary;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = (props) => {
  const { payment } = props;
  const amountFormatted = new Intl.NumberFormat("fr-FR").format(payment?.amount!);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString("en-US", { month: "short" });

  return (
    <div className="card card-layout-frame">
      <div className="card-body">
        <h6 className="font-semibold mb-4 text-sm">{payment?.title}</h6>
        <div className="flex justify-between items-center">
          <div>
            <h3 className="font-bold">
              <span>
                {amountFormatted} {payment?.title?.includes("Unités") ? "" : "FCFA"}
              </span>
            </h3>
            <p>
              {payment?.description}{" "}
              <span className="font-semibold">
                {day} {month}
              </span>
            </p>
          </div>
          {/* <div className="tag gap-1 font-bold border-0 text-emerald-600 dark:text-emerald-400 bg-emerald-100 dark:bg-emerald-500/20 dark:text-emerald-100">
            <span>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
              </svg>
            </span>
            <span>12.5%</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
