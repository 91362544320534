import React, { createRef, useEffect, useState } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Alerts from "../../../helpers/Alerts";
import Spinners from "../../../helpers/Spinners";
import { selectTenantStatus, initializeActionStatus } from "../tenantSlice";

interface PaymentDialogProps {
  title: string;
  actionType: string;
  PaymentToEdit?: IUpdatePaymentData;
  dialogId: string;
  showDialog: boolean;
  handleEditPayment?: (user: IUpdatePaymentData) => void;
}

const TenantEditPaymentDialog: React.FC<PaymentDialogProps> = (props) => {
  const {
    title,
    actionType,
    PaymentToEdit,
    dialogId,
    showDialog,
    handleEditPayment,
  } = props;

  const dispatch = useAppDispatch();
  const status = useAppSelector(selectTenantStatus);

  const closeDialogRef = createRef<HTMLSpanElement>();
  const [payment, setPayment] = useState<IUpdatePaymentData | null>(null);
  const [isAmountValid, setIsAmountValid] = useState<boolean>(false);

  useEffect(() => {
    if (PaymentToEdit && actionType && actionType === "edit") {
      setPayment((prevState: any) => {
        return {
          ...prevState,
          id: PaymentToEdit.id,
          referenceNumber: PaymentToEdit.referenceNumber,
          leaseId: PaymentToEdit.leaseId,
          amount: PaymentToEdit.amount,
          comments: PaymentToEdit.comments,
        };
      });
      setIsAmountValid(!!PaymentToEdit.amount);
    }
  }, [PaymentToEdit, actionType]);

  useEffect(() => {
    if (!showDialog) {
      closeDialogRef.current?.click();
    }
  }, [closeDialogRef, showDialog]);

  useEffect(() => {
    if (status === "success") {
      closeDialogRef.current?.click();
    }
  }, [status]);

  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    if (actionType === "edit" && isAmountValid) {
      handleEditPayment!(payment!);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    setPayment((prevState: any) => {
      return { ...prevState, amount: val };
    });
    setIsAmountValid(val !== "" && parseFloat(val) !== 0);
  };

  function getDialogButtonActions() {
    if (actionType === "edit" || actionType === "") {
      return (
        <div className="text-right mt-6">
          <button
            className="btn btn-plain ltr:mr-2 rtl:ml-2"
            data-bs-dismiss="modal"
            onClick={() => dispatch(initializeActionStatus())}
          >
            Annuler
          </button>
          <button
            className="btn btn-solid"
            onClick={submit}
            disabled={!isAmountValid}
          >
            <span className="flex items-center justify-center">
              Sauvegarder
              {status === "pending" && <Spinners sizeFromProps={30} />}
            </span>
          </button>
        </div>
      );
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id={dialogId}
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog dialog">
          <div className="modal-content dialog-content">
            <span
              ref={closeDialogRef}
              className="close-btn absolute z-10 ltr:right-6 rtl:left-6"
              role="button"
              data-bs-dismiss="modal"
              onClick={() => dispatch(initializeActionStatus())}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h5 className="mb-4">
              {title} - {payment?.referenceNumber}
            </h5>
            <div>
              <form>
                <div className="form-item vertical">
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Amount *</label>
                    <input
                      className="input"
                      type="number"
                      name="amount"
                      value={payment?.amount}
                      onChange={handleAmountChange}
                    />
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Comments</label>
                    <textarea
                      className="input input-textarea"
                      name="comments"
                      placeholder="Mettez un commentaire ici"
                      defaultValue=""
                      onChange={(e) => {
                        let val = e.target?.value;
                        setPayment((prevState: any) => {
                          return { ...prevState, comments: val };
                        });
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            {getDialogButtonActions()}
            {status === "failed" && (
              <Alerts
                type={"alert-danger"}
                message={
                  "Erreur lors de cette opération. Veuillez reessayer!!!"
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantEditPaymentDialog;
