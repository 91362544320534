import { rejects } from "assert";
import axios from "axios";
import { api } from "../../../services/apiHelpers";


const axiosHeadersConfig = () => {
    const user: IUser = JSON.parse(localStorage.getItem("user")!)
    if (user.accessToken) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + user.accessToken;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
}

export function getRoles() {
    axiosHeadersConfig();
    return new Promise<{ data: IRole[], status: number }>((resolve) => {
        axios.get(api + "/Users/roles/list").then((res: any) => {
            if (res.data) {
                resolve({ data: res.data, status: res.status });
            }
        }).catch((error: any) => {
            console.error('error', error);
        });
    });
}

export function createRole(data: any) {
    return new Promise<{ data: IRole[], status: number }>((resolve) => {
        axios.post(api + "/Users/roles/create", data).then((res: any) => {
            if (res.data) {
                resolve({ data: res.data, status: res.status });
            }
        }).catch((error: any) => {
            console.error('error', error);
        });
    });
}