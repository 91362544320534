import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { createLease, getLeases, terminateLease } from "./leaseAPI";
import { jwtDecode, JwtPayload } from "jwt-decode";
import {
  addNewItemToObjectArray,
  removeItemFromObjectArray,
  sortedArray,
  updateArrayByItemId,
} from "../../helpers/objectMapper";

export interface LeaseState {
  leaseList: ILease[];
  status: "pending" | "success" | "failed" | "";
}

const initialState: LeaseState = {
  leaseList: [],
  status: "",
};

export const getLeasesAsync = createAsyncThunk("lease/getLeases", async () => {
  const response = await getLeases();
  return response.data;
});

export const createLeaseAsync = createAsyncThunk(
  "lease/create",
  async (data: ILease) => {
    const response = await createLease(data);
    return response.data;
  }
);
export const terminateLeaseAsync = createAsyncThunk(
  "lease/terminate",
  async (data: ILease) => {
    const response = await terminateLease(data);
    return response.data;
  }
);

// export const updateUserAsync = createAsyncThunk('lease/update', async (data: ILease) => {
//     const response = await updateLease(data);
//     return response.data;
// });

export const leaseSlice = createSlice({
  name: "lease",
  initialState,
  reducers: {
    initializeActionStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeasesAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getLeasesAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.leaseList = sortedArray(action.payload) as ILease[];
      })
      .addCase(getLeasesAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createLeaseAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createLeaseAsync.fulfilled, (state, action) => {
        state.leaseList = addNewItemToObjectArray(
          [...state.leaseList],
          action.payload
        ) as ILease[];
        state.status = "success";
      })
      .addCase(createLeaseAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(terminateLeaseAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(terminateLeaseAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.leaseList = updateArrayByItemId(
          [...state.leaseList],
          action.payload
        ) as ILease[];
      })
      .addCase(terminateLeaseAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { initializeActionStatus } = leaseSlice.actions;

export const selectLeaseList = (state: RootState) => state.lease.leaseList;
export const selectLeaseStatus = (state: RootState) => state.lease.status;

export default leaseSlice.reducer;
