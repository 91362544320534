import { rejects } from "assert";
import axios from "axios";
import { api } from "../../services/apiHelpers";

const axiosHeadersConfig = () => {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  if (user.accessToken) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + user.accessToken;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export function getAgencyAccountOperations() {
  axiosHeadersConfig();
  return new Promise<{ data: IAccountOperation[]; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + "/Accounting/agency/accountOperations")
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function getAgencyAccountOperationsBySearch(data: IAgencyAccountOperation) {
  axiosHeadersConfig();
  return new Promise<{ data: IAccountOperation[]; status: number }>(
    (resolve, reject) => {
      axios
        .post(api + "/Accounting/agency/accountOperations", data)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function displayLandlordAccountOperation(
  data: ILandlordAccountOperation
) {
  return new Promise<{ data: IAccountOperation[]; status: number }>(
    (resolve, reject) => {
      axios
        .post(api + "/Accounting/landlords/accountOperations", data)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function displayAgentAccountOperation(data: IAgentAccountOperation) {
  return new Promise<{ data: IAccountOperation[]; status: number }>(
    (resolve, reject) => {
      axios
        .post(api + "/Accounting/agents/accountOperations", data)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function displayAccountJournalOperations(data: IAccountJournal) {
  axiosHeadersConfig();
  return new Promise<{ data: IAccountOperation[]; status: number }>(
    (resolve, reject) => {
      axios
        .post(api + "/accounting/accountOperations", data)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function addAgencyExpense(data: IAddPropertyExpense) {
  return new Promise<{ data: IAccountOperation[]; status: number }>((resolve) => {
    axios
      .post(api + `/Accounting/agency/expenses/add`, data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}

export function getAgencyChartData() {
  axiosHeadersConfig();
  return new Promise<{ data: IChartData; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Accounting/agency/charts/accountOperations/`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}