import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Alerts from "../../../helpers/Alerts";
import Spinners from "../../../helpers/Spinners";
import { getRolesAsync, selectRoleList } from "../role/roleSlice";
import UserDialog from "./components/userDialog";
import {
  createUserAsync,
  deleteUserAsync,
  getUsersAsync,
  selectAuthUserPermissions,
  selectUserList,
  selectUserStatus,
  setSelectedUser,
  updateUserAsync,
} from "./userSlice";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { search } from "../../../helpers/objectMapper";

export function UsersPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUserList);
  const roles = useAppSelector(selectRoleList);
  const status = useAppSelector(selectUserStatus);
  const AuthUserPermissions = useAppSelector(selectAuthUserPermissions);
  const [editUser, setEditUser] = useState<ICreateOrUpdateUserData>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>("");
  const [title, setTitle] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<ILease[]>();
  const [filterText, setFilterText] = useState<string>("");

  useEffect(() => {
    if (roles && roles.length === 0) {
      dispatch(getRolesAsync());
    }
    if (users && users.length === 0) {
      dispatch(getUsersAsync());
    }
  }, []);

  useEffect(() => {
    if (status === "success") {
      setLoading(false);
    }
    if (status === "failed" && loading === true) {
      setShowError(true);
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const openDialog = (actionType: string) => {
    if (actionType === "add") {
      setActionType(actionType);
      setTitle("Ajouter un nouvel utilisateur");
    } else if (actionType === "edit") {
      setActionType(actionType);
      setTitle("Modifier un utilisateur");
    } else if (actionType === "delete") {
      setActionType(actionType);
      setTitle("Supprimer un utilisateur");
    }
    setShowDialog(true);
  };

  const handleCreateUser = (userData: ICreateOrUpdateUserData) => {
    dispatch(createUserAsync(userData!));
  };

  const handleEditUser = (userData: ICreateOrUpdateUserData) => {
    dispatch(updateUserAsync(userData!));
  };

  const handleDeleteUser = (userId: string) => {
    dispatch(deleteUserAsync(userId));
  };

  const userHasPermission = (action: string) => {
    if (AuthUserPermissions.some((permission) => permission === action)) {
      return true;
    }
    return false;
  };

  const columns = useMemo(
    () => [
      {
        name: "Prénom",
        selector: (row: any) => row.firstName,
        sortable: true,
      },
      {
        name: "Nom",
        selector: (row: any) => row.lastName,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row: any) => row.email,
        sortable: true,
      },
      {
        name: "Rôles",
        sortable: true,
        cell: (row: any) => (
          <div className="flex gap-2">
            {row.roles?.map((role: any, r_index: any) => (
              <div className="tag" key={"r" + r_index}>
                {role.name}
              </div>
            ))}
          </div>
        ),
      },
      {
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        right: true,
        cell: (row: any) => (
          <div className="flex justify-end text-lg">
            {/* show this when user role is agent */}
            {row.roles?.some((role: any) => role.name === "Agent") && (
              <span className="cursor-pointer p-2 hover:text-indigo-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="0.9em"
                  width="0.9em"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  aria-hidden="true"
                  strokeWidth="2"
                  onClick={() => {
                    dispatch(setSelectedUser(row!));
                    navigate(`/users/details/${row.id}`);
                  }}
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </span>
            )}
            {userHasPermission("UpdateUser") && (
              <span
                className="cursor-pointer p-2 hover:text-indigo-600"
                data-bs-toggle="modal"
                data-bs-target="#editUser"
                onClick={() => {
                  openDialog("edit");
                  setEditUser((prevState: any) => {
                    return {
                      ...prevState,
                      id: row.id,
                      firstName: row.firstName,
                      lastName: row.lastName,
                      email: row.email,
                      password: row.password,
                      address: row.address,
                      phoneNumber1: row.phoneNumber1,
                      phoneNumber2: row.phoneNumber2,
                      roles: row.roles?.map((r: any) => r.id),
                    };
                  });
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="0.9em"
                  width="0.9em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  ></path>
                </svg>
              </span>
            )}
            {userHasPermission("DeleteUser") && (
              <span
                className="cursor-pointer p-2 hover:text-red-500"
                data-bs-toggle="modal"
                data-bs-target="#deleteUser"
                onClick={() => {
                  openDialog("delete");
                  setEditUser((prevState: any) => {
                    return {
                      ...prevState,
                      id: row.id,
                      firstName: row.firstName,
                      lastName: row.lastName,
                      email: row.email,
                      password: row.password,
                      address: row.address,
                      phoneNumber1: row.phoneNumber1,
                      phoneNumber2: row.phoneNumber2,
                      roles: row.roles?.map((r: any) => r.id),
                    };
                  });
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="0.9em"
                  width="0.9em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  ></path>
                </svg>
              </span>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const dynamicSearch = (terme: any) => {
    setFilterText(terme);
    let result = search(users!, String(terme).toLocaleLowerCase());
    setFilteredUsers(result as ITenant[]);
    console.log("result", result);
  };

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="container mx-auto">
            <div className="card adaptable-card">
              <div className="card-body">
                <div className="lg:flex items-center justify-between mb-4">
                  <h3 className="mb-4 lg:mb-0">Utilisateurs</h3>
                  {userHasPermission("AddUser") && (
                    <button
                      className="btn btn-two-tune btn-sm"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#addUser"
                      onClick={() => openDialog("add")}
                      disabled={loading}
                    >
                      <span className="flex items-center justify-center gap-2">
                        <span className="text-lg">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            height="0.9em"
                            width="0.9em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                        </span>
                        <span>Ajouter</span>
                      </span>
                    </button>
                  )}
                </div>
                {loading ? (
                  <>
                    <div className="lg:flex items-center justify-center">
                      <Spinners sizeFromProps={50} />
                    </div>
                  </>
                ) : showError ? (
                  <Alerts
                    type={"alert-danger"}
                    message={
                      "Erreur lors de cette opération. Veuillez reessayer!!!"
                    }
                  />
                ) : (
                  <>
                    {/* Section de filtre */}
                    <div className="lg:flex items-center justify-end mb-4 gap-3">
                      <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                        <input
                          id="date-query-input"
                          className="input input-sm pr-8"
                          placeholder="Rechercher..."
                          value={filterText}
                          onChange={(e: any) => dynamicSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="overflow-x-auto">
                      <DataTable
                        columns={columns}
                        pagination
                        data={filteredUsers!}
                        striped={true}
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[25, 50, 100]}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <UserDialog
          title={title}
          actionType={actionType}
          roleList={roles}
          dialogId="addUser"
          showDialog={showDialog}
          handleCreateUser={handleCreateUser}
        />
        <UserDialog
          title={title}
          actionType={actionType}
          roleList={roles}
          dialogId="editUser"
          showDialog={showDialog}
          userToEdit={editUser}
          handleEditUser={handleEditUser}
        />
        <UserDialog
          title={title}
          actionType={actionType}
          dialogId="deleteUser"
          showDialog={showDialog}
          userToEdit={editUser}
          handleDeleteUser={handleDeleteUser}
        />
      </main>
    </>
  );
}
