import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Alerts from "../../helpers/Alerts";
import Spinners from "../../helpers/Spinners";
import { selectPropertyList, selectPropertyStatus, getPropertiesAsync, setSelectedProperty, deletePropertyAsync, setPropertyChartData } from "./propertySlice";
import PropertyDialog from "./components/PropertyDialog";
import { selectAuthUserPermissions } from "../account/user/userSlice";
import DataTable from "react-data-table-component";
import { search } from "../../helpers/objectMapper";
// import DataTable from "datatables.net-dt";

export function PropertiesPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const properties = useAppSelector(selectPropertyList);
  const status = useAppSelector(selectPropertyStatus);
  const AuthUserPermissions = useAppSelector(selectAuthUserPermissions);
  const [propertyToEdit, setPropertyToEdit] = useState<IProperty>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [filteredProperties, setFilteredProperties] = useState<ILease[]>();
  const [filterText, setFilterText] = useState<string>("");

  useEffect(() => {
    if (properties && properties.length === 0) {
      dispatch(getPropertiesAsync());
    }
  }, []);

  useEffect(() => {
    dispatch(setPropertyChartData(undefined));
  }, [location]);

  useEffect(() => {
    if (status === "success") {
      // let table = new DataTable("#properties-data-table", {
      //   // config options...
      // });
      setLoading(false);
    }
    if (status === "failed" && loading === true) {
      setShowError(true);
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    setFilteredProperties(properties);
  }, [properties]);

  const handleDeleteProperty = () => {
    dispatch(deletePropertyAsync(propertyToEdit?.id!));
  };

  const userHasPermission = (action: string) => {
    if (AuthUserPermissions.some((permission) => permission === action)) {
      return true;
    }
    return false;
  };

  const columns = useMemo(
    () => [
      {
        name: "Nom",
        selector: (row: any) => row.name,
        sortable: true,
      },
      {
        name: "Type",
        selector: (row: any) => row.type,
        sortable: true,
      },
      {
        name: "Nom du propirétaire",
        selector: (row: any) => row.landlord?.firstName + " " + row.landlord?.lastName,
        sortable: true,
      },
      {
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        cell: (row: any) => (
          <div className="flex justify-end text-lg">
            {userHasPermission("UpdateProperty") && (
              <span className="cursor-pointer p-2 hover:text-indigo-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  width="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  aria-hidden="true"
                  strokeWidth="2"
                  onClick={() => {
                    dispatch(setSelectedProperty(row!));
                    navigate(`/properties/details/${row.id}`);
                  }}
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </span>
            )}
            {userHasPermission("DeleteProperty") && (
              <span className="cursor-pointer p-2 hover:text-red-500" data-bs-toggle="modal" data-bs-target="#delete-property" onClick={() => setPropertyToEdit(row)}>
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                </svg>
              </span>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const dynamicSearch = (terme: any) => {
    setFilterText(terme);
    let result = search(properties!, String(terme).toLocaleLowerCase());
    setFilteredProperties(result as ITenant[]);
    console.log("result", result);
  };

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="container mx-auto">
            <div className="card adaptable-card">
              <div className="card-body">
                <div className="lg:flex items-center justify-between mb-4">
                  <h3 className="mb-4 lg:mb-0">Biens immobiliers</h3>
                  {userHasPermission("AddProperty") && (
                    <button className="btn btn-two-tune btn-sm" type="button" onClick={() => navigate("/properties/create")} disabled={loading || showError}>
                      <span className="flex items-center justify-center gap-2">
                        <span className="text-lg">
                          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                          </svg>
                        </span>
                        <span>Ajouter</span>
                      </span>
                    </button>
                  )}
                </div>
                {loading ? (
                  <div className="lg:flex items-center justify-center">
                    <Spinners sizeFromProps={50} />
                  </div>
                ) : showError ? (
                  <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
                ) : (
                  <>
                    {/* Section de filtre */}
                    <div className="lg:flex items-center justify-end mb-4 gap-3">
                      <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                        <input id="date-query-input" className="input input-sm pr-8" placeholder="Rechercher..." value={filterText} onChange={(e: any) => dynamicSearch(e.target.value)} />
                      </div>
                    </div>
                    <div className="overflow-x-auto">
                      <DataTable columns={columns} pagination data={filteredProperties!} striped={true} paginationPerPage={25} paginationRowsPerPageOptions={[25, 50, 100]} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <PropertyDialog property={propertyToEdit!} handleDeleteProperty={handleDeleteProperty} />
      </main>
    </>
  );
}
