import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Alerts from "../../../helpers/Alerts";
import Spinners from "../../../helpers/Spinners";
import AccountSummary from "../../commonComponent/AccountSummary";
import PaymentSummary from "../../commonComponent/PaymentSummary";
import {
  addPaymentAsync,
  deleteTenantAsync,
  getTenantsAsync,
  selectSelectedTenant,
  selectTenantList,
  selectTenantStatus,
  setSelectedTenant,
  selectTenantChartData,
  getTenantChartDataAsync,
  selectTenantRentPayments,
  getTenantRentPaymentsAsync,
  updateTenantRentPaymentAsync,
} from "../tenantSlice";
import TenantDeleteDialog from "./TenantDeleteDialog";
import TenantPaymentDialog from "./TenantPaymentDialog";
import { selectAuthUserPermissions } from "../../account/user/userSlice";
import ReactApexChart from "react-apexcharts";
import { getLeasesAsync, selectLeaseList } from "../../leases/leaseSlice";
import DataTable from "react-data-table-component";
import { search } from "../../../helpers/objectMapper";
import moment from "moment";
import TenantEditPaymentDialog from "./TenantEditPaymentDialog";

export function TenantDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectTenantStatus);
  const leases = useAppSelector(selectLeaseList);
  const tenants = useAppSelector(selectTenantList);
  const tenantRentPayments = useAppSelector(selectTenantRentPayments);
  const selectedTenant = useAppSelector(selectSelectedTenant);
  const tenantChartData = useAppSelector(selectTenantChartData);
  const AuthUserPermissions = useAppSelector(selectAuthUserPermissions);
  const [dialogId, setDialogId] = useState("updateLease");
  const [title, setTitle] = useState<string>();
  const [paymentTitle, setPaymentTitle] = useState("");
  const [paymentActionType, setPaymentActionType] = useState<string>("");
  const [actionType, setActionType] = useState<string>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [editPayment, setEditPayment] = useState<IUpdatePaymentData>();
  const [tenant, setTenant] = useState<ITenant>();
  const [payment, setPayment] = useState<IPayment>({
    amount: 0,
    paymentDate: "",
    paymentMethod: "",
    months: [],
    comments: "",
    leaseId: "",
    year: moment().year(),
  });
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [chartOptions, setChartOptions] = useState<any>({ series: [] });
  const [filteredTenantRentPayments, setFilteredTenantRentPayments] =
    useState<IPayment[]>();
  const [filterText, setFilterText] = useState<string>("");

  useEffect(() => {
    if (selectedTenant) {
      setTenant(selectedTenant);
      setLoading(false);
    } else if (selectedTenant == null) {
      dispatch(getTenantsAsync());
    }
    if (tenantChartData == undefined) {
      dispatch(getTenantChartDataAsync(id!));
    }
  }, []);

  useEffect(() => {
    if (tenants.length > 0) {
      let value = tenants.find((l) => l.id == id);
      setTenant(value);
      dispatch(setSelectedTenant(value!));
    }
  }, [tenants]);

  useEffect(() => {
    setFilteredTenantRentPayments(tenantRentPayments);
  }, [tenantRentPayments]);

  useEffect(() => {
    if (selectedTenant) {
      dispatch(getTenantRentPaymentsAsync(id!));
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (leases && leases.length === 0) {
      dispatch(getLeasesAsync());
    } else if (loading) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {}, [leases]);

  useEffect(() => {
    if (status === "success") {
      setLoading(false);
    }
    if (status === "failed" && loading === true) {
      setShowError(true);
      setLoading(false);
    }
    if (deleting && status === "success") {
      navigate("/tenants/list");
    }
  }, [status]);

  useEffect(() => {
    if (tenantChartData && chartLoading) {
      setChartOptions({
        series: tenantChartData,
        chart: {
          type: "bar",
          height: 425,
        } as ApexChart,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Avr",
            "Mai",
            "Juin",
            "Jul",
            "Aout",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          title: {
            text: "FCFA",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val + " FCFA";
            },
          },
        },
      });
      setChartLoading(false);
    }
  }, [tenantChartData]);

  const openDialog = (action: string) => {
    if (action === "update") {
      setTitle("Liste de contrats");
      setActionType("afficher");
    } else if (action === "edit") {
      setPaymentTitle("Modifier un paiement");
      setPaymentActionType(action);
    }
    setShowDialog(true);
  };

  let handleChange = (objectKey: string, value: any) => {
    setPayment((prevState: any) => {
      return { ...prevState, [objectKey]: value };
    });
  };

  const handleDeleteTenant = () => {
    setDeleting(true);
    dispatch(deleteTenantAsync(tenant?.id!));
  };

  const handleRentPayment = (payment: IPayment) => {
    dispatch(addPaymentAsync(payment!));
  };

  const handleEditPayment = (paymentData: IUpdatePaymentData) => {
    dispatch(updateTenantRentPaymentAsync(paymentData!));
  };

  const initializePayment = () => {
    setPayment({
      amount: 0,
      paymentDate: "",
      paymentMethod: "",
      months: [],
      comments: "",
      leaseId: "",
      year: moment().year(),
    });
  };

  const expensesSum = (): number => {
    // let result = landlord?.accountOperations
    //   ?.filter((op) => op.type === 1)
    //   ?.map((acc) => acc.amount);
    // if (result && result.length > 0) {
    //   return result?.reduce((a, b) => {
    //     return a! + b!;
    //   })!;
    // }
    return 0;
  };

  const paidSum = (): number => {
    let result = tenantRentPayments?.map((acc) => acc.amount);
    if (result && result.length > 0) {
      return result?.reduce((a, b) => {
        return a! + b!;
      })!;
    }
    return 0;
  };

  const getPaymentMethodDescription = (method: string) => {
    switch (method) {
      case "Cash":
        return "Comptant";
      case "OrangeMoney":
        return "Orange Money";
      case "MoovMoney":
        return "Moov Money";
      case "Wave":
        return "Wave";
      case "SamaMoney":
        return "Sama Money";
      case "Cheque":
        return "Chèque";
      default:
        return "";
    }
  };

  const userHasPermission = (action: string) => {
    if (AuthUserPermissions.some((permission) => permission === action)) {
      return true;
    }
    return false;
  };

  const dynamicSearch = (terme: any) => {
    setFilterText(terme);
    let result = search(tenantRentPayments!, String(terme).toLocaleLowerCase());
    setFilteredTenantRentPayments(result as IPayment[]);
    console.log("result", result);
  };

  const columns = useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) =>
          new Date(row.paymentDate).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        sortable: true,
      },
      {
        name: "Bien",
        selector: (row: any) => row.propertyName,
        sortable: true,
      },
      {
        name: "Unité",
        selector: (row: any) => row.unitName,
        sortable: true,
      },
      {
        name: "Mode de paiment",
        selector: (row: any) => row.paymentMethod,
        sortable: true,
      },
      {
        name: "Montant",
        selector: (row: any) =>
          new Intl.NumberFormat("fr-FR").format(row.amount),
        sortable: true,
      },
      {
        name: "Mois",
        sortable: true,
        selector: (row: any) => (
          <div className="flex gap-2">
            {row.months?.map((m: any, r_index: any) => (
              <div className="tag" key={"r" + r_index}>
                {m}
              </div>
            ))}
          </div>
        ),
      },
      {
        name: "Commentaires",
        selector: (row: any) => row.comments,
        sortable: true,
      },
      {
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        right: true,
        cell: (row: any) => (
          <div className="flex justify-end text-lg">
            {
              <span
                className="cursor-pointer p-2 hover:text-indigo-600"
                data-bs-toggle="modal"
                data-bs-target="#editPayment"
                onClick={() => {
                  openDialog("edit");
                  setEditPayment((prevState: any) => {
                    console.log(row);
                    return {
                      ...prevState,
                      id: row.id,
                      referenceNumber: row.referenceNumber,
                      leaseId: row.leaseId,
                      amount: row.amount,
                      comments: row.comments,
                    };
                  });
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="0.9em"
                  width="0.9em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  ></path>
                </svg>
              </span>
            }
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="flex flex-col gap-4 h-full">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-2">
                <h3>
                  Détail d'un locataire - {tenant?.firstName} {tenant?.lastName}
                </h3>
              </div>
              <div className="flex items-center gap-2">
                <div className="dropdown">
                  <div
                    className="dropdown-toggle"
                    id="dropdown-toggle-42-BXqtaPjU7e"
                  >
                    <button
                      className="btn btn-default btn-sm"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target={"#tenantleasesdialog"}
                      disabled={loading}
                    >
                      <span className="flex items-center justify-center">
                        <span className="text-lg" style={{ marginTop: "-5px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                            ></path>
                          </svg>
                        </span>
                        <span className="ltr:ml-1 rtl:mr-1">
                          <span className="hidden sm:block">
                            &nbsp; Contrats
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <PaymentSummary
                payment={{ amount: paidSum(), title: "Total payé" }}
              />
              <PaymentSummary
                payment={{ amount: expensesSum(), title: "Total non payé" }}
              />
              <PaymentSummary
                payment={{
                  amount: 0, //FIXME
                  title: "Total à percevoir",
                }}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <AccountSummary
                person={tenant}
                propertyName={"tenants"}
                deleteTarget={"delete-tenant"}
              />
              <div className="card card-layout-frame col-span-2">
                <div className="card-body">
                  <div className="flex items-center justify-between">
                    <h4>Rapport des opérations</h4>
                    {/* <button className="btn btn-sm btn-default">Export Report</button> */}
                    <div>
                      <select className="input input-md">
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <ReactApexChart
                      options={chartOptions}
                      series={chartOptions?.series!}
                      type="bar"
                      height={425}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div className="card card-layout-frame col-span-2">
                <div className="card-body">
                  <div className="lg:flex items-center justify-between mb-4 gap-3">
                    <div className="mb-4 lg:mb-0">
                      <h4>Liste des paiements</h4>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                      <span className="input-wrapper">
                        <input
                          id="date-query-input"
                          className="input input-sm pr-8"
                          placeholder="Rechercher..."
                          value={filterText}
                          onChange={(e: any) => dynamicSearch(e.target.value)}
                        />
                      </span>
                      {userHasPermission("AddTenantPayment") && (
                        <button
                          className="btn btn-two-tune btn-sm"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#addPayment"
                        >
                          <span className="flex items-center justify-center gap-2">
                            <span className="text-lg">
                              <svg
                                stroke="currentColor"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                              </svg>
                            </span>
                            <span>Ajouter un paiement</span>
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                  {loading ? (
                    <>
                      <div className="lg:flex items-center justify-center">
                        <Spinners sizeFromProps={50} />
                      </div>
                    </>
                  ) : showError ? (
                    <Alerts
                      type={"alert-danger"}
                      message={
                        "Erreur lors de cette opération. Veuillez reessayer!!!"
                      }
                    />
                  ) : (
                    <>
                      <div className="overflow-x-auto">
                        <DataTable
                          columns={columns}
                          data={filteredTenantRentPayments!}
                          striped={true}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TenantDeleteDialog
          tenant={tenant!}
          handleDeleteTenant={handleDeleteTenant}
        />
        <TenantPaymentDialog
          tenant={tenant!}
          payment={payment}
          dialogId={"addPayment"}
          title={"Ajouter un nouveau paiement de location"}
          handleChange={handleChange}
          initializePayment={initializePayment}
          handleRentPayment={handleRentPayment}
        />
        <>
          <div
            className="modal fade"
            id="tenantleasesdialog"
            aria-hidden="true"
          >
            <div className="modal-dialog dialog">
              <div className="dialog-content">
                <span
                  className="close-btn absolute z-10 ltr:right-6 rtl:left-6"
                  role="button"
                  data-bs-dismiss="modal"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <div>
                  <div className="mb-8">
                    <h3 className="mb-2">Contrats du locataire</h3>
                  </div>
                  <div className="rounded-lg border border-gray-200 dark:border-gray-600">
                    {selectedTenant?.leases?.map((lease, index) => (
                      <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-3 p-4 border-b border-gray-200 dark:border-gray-600">
                        <div className="flex items-center gap-3">
                          <div>
                            <div className="items-center">
                              <div className="text-gray-900 dark:text-gray-100 font-semibold">
                                Contrat {lease.number} - Montant du loyer:{" "}
                                {lease.rentAmount} FCFA
                                {lease.unit?.isOccupied ? (
                                  <span
                                    className="capitalize"
                                    style={{ textAlign: "right" }}
                                  >
                                    <span className="tag bg-sky-100 text-sky-600 dark:bg-sky-500/20 dark:text-sky-100 rounded-md border-0 mx-2">
                                      Occupé
                                    </span>
                                  </span>
                                ) : (
                                  <span
                                    className="capitalize"
                                    style={{ textAlign: "right" }}
                                  >
                                    {" "}
                                    <span className="tag text-red-600 bg-red-100 dark:text-red-100 dark:bg-red-500/20 border-0">
                                      Résilié
                                    </span>
                                  </span>
                                )}
                                <br />
                                Date de début:{" "}
                                {new Date(lease.startDate!).toLocaleDateString(
                                  "fr",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                              </div>
                              <span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    navigate(
                                      `/properties/details/${lease.property?.id}`
                                    );
                                  }}
                                >
                                  Propriété: {lease.property?.name}
                                </span>
                              </span>
                              <br />
                              <span>Unité: {lease.unit?.name}</span>
                              <br />
                              <span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    navigate(
                                      `/landlords/details/${lease.landlord?.id}`
                                    );
                                  }}
                                >
                                  Bailleur: {lease.landlord?.firstName}{" "}
                                  {lease.landlord?.lastName}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="text-right mt-6">
                  <button className="btn btn-solid" data-bs-dismiss="modal">
                    Fermer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
        <TenantEditPaymentDialog
          title={paymentTitle}
          actionType={paymentActionType}
          dialogId="editPayment"
          showDialog={showDialog}
          PaymentToEdit={editPayment}
          handleEditPayment={handleEditPayment}
        />
      </main>
    </>
  );
}
