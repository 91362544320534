import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { selectAuthUserPermissions } from "../../features/account/user/userSlice";
import { useAppSelector } from "../../app/hooks";

const Sidebar = () => {
  const location = useLocation();
  const AuthUserPermissions = useAppSelector(selectAuthUserPermissions);

  useEffect(() => {
    activeSingleNav(getActiveNavIdFromUrl(location.pathname));
  }, [location]);

  const getActiveNavIdFromUrl = (pathname: string): string => {
    if (pathname.includes("properties")) return "properties";
    else if (pathname.includes("tenants")) return "tenants";
    else if (pathname.includes("landlords")) return "landlords";
    else if (pathname.includes("agency")) return "agency";
    else if (pathname.includes("leases")) return "leases";
    else if (pathname.includes("users")) return "users";
    else if (pathname.includes("agencyAccountOperations")) return "agencyAccountOperations";
    else if (pathname.includes("landlordAccountOperations")) return "landlordAccountOperations";
    else if (pathname.includes("agentAccountOperations")) return "agentAccountOperations";
    else if (pathname.includes("accountOperations")) return "accountOperations";
    else return "dashboard";
  };

  const getSelectedCollapseNav = (nav: string): string => {
    if (nav === "agencyAccountOperations" || nav === "landlordAccountOperations" || nav === "agentAccountOperations" || nav === "accountOperations") {
      return "comptability";
    }
    return "";
  };

  const activeSingleNav = (itemId: string) => {
    let collapseNav = getSelectedCollapseNav(itemId);
    if (collapseNav && collapseNav !== "") {
      activeCollapseNavs(collapseNav);
    }
    disableSignleNavs();
    let selectedNav = document.getElementById(itemId);
    selectedNav?.classList.add("menu-item-active");
  };

  const disableSignleNavs = () => {
    let menuItemsSingle = document.getElementsByClassName("menu-item-single");
    for (let index = 0; index < menuItemsSingle.length; index++) {
      const element = menuItemsSingle[index];
      element.classList.remove("menu-item-active");
    }
  };

  const activeCollapseNavs = (coll: string) => {
    disableCollapseNavs();
    let selectedCollapseNav = document.getElementById(coll);
    selectedCollapseNav?.classList.add("menu-collapse-item-active");
    let nodes = selectedCollapseNav?.children;
    nodes![1].setAttribute("style", "display: block");
  };

  const disableCollapseNavs = () => {
    let menuItemsCollapse = document.getElementsByClassName("menu-collapse");
    for (let index = 0; index < menuItemsCollapse.length; index++) {
      const element = menuItemsCollapse[index];
      element.classList.remove("menu-collapse-item-active");
      let uls = element.children;
      uls![1].setAttribute("style", "display: none");
    }
  };

  const userHasPermission = (action: string) => {
    if (AuthUserPermissions.some((permission) => permission === action)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="side-nav side-nav-transparent side-nav-expand">
        <div className="side-nav-header">
          <div className="logo px-6">
            <img src="/assets/images/logo-jikke.png" alt="JIKKE Admin logo" />
          </div>
        </div>
        <div className="side-nav-content relative side-nav-scroll">
          <nav className="menu menu-transparent px-4 pb-4">
            <div className="menu-group">
              <ul>
                <>
                  <li id="dashboard" data-menu-item="classic-single-menu" className="menu-item menu-item-single mb-2">
                    <a className="menu-item-link" href="/">
                      <span className="menu-item-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"></path>
                        </svg>
                      </span>
                      <span className="menu-item-text">Dashboard</span>
                    </a>
                  </li>
                  {/* Fixe Me: Mettre la permission */}
                  <li id="agency" data-menu-item="classic-single-menu" className="menu-item menu-item-single mb-2">
                    <a className="menu-item-link" href="/agency/details">
                      <span className="menu-item-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"></path>
                        </svg>
                      </span>
                      <span className="menu-item-text">Agence</span>
                    </a>
                  </li>
                  {userHasPermission("SeeLandlords") && (
                    <li id="landlords" data-menu-item="classic-single-menu" className="menu-item menu-item-single mb-2">
                      <a className="menu-item-link" href="/landlords/list">
                        <span className="menu-item-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"></path>
                          </svg>
                        </span>
                        <span className="menu-item-text">Bailleurs</span>
                      </a>
                    </li>
                  )}
                  {userHasPermission("SeeProperties") && (
                    <li id="properties" data-menu-item="classic-single-menu" className="menu-item menu-item-single mb-2">
                      <a className="menu-item-link" href="/properties/list">
                        <span className="menu-item-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"></path>
                          </svg>
                        </span>
                        <span className="menu-item-text">Biens immobiliers</span>
                      </a>
                    </li>
                  )}
                  {userHasPermission("SeeTenants") && (
                    <li id="tenants" data-menu-item="classic-single-menu" className="menu-item menu-item-single mb-2">
                      <a className="menu-item-link" href="/tenants/list">
                        <span className="menu-item-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"></path>
                          </svg>
                        </span>
                        <span className="menu-item-text">Locataires</span>
                      </a>
                    </li>
                  )}
                  {userHasPermission("SeeLeases") && (
                    <li id="leases" data-menu-item="classic-single-menu" className="menu-item menu-item-single mb-2">
                      <a className="menu-item-link" href="/leases/list">
                        <span className="menu-item-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"></path>
                          </svg>
                        </span>
                        <span className="menu-item-text">Contrats</span>
                      </a>
                    </li>
                  )}
                  {/* {userHasPermission(currentUser!, "AddLandlord") && ( */}
                  <li id="comptability" className="menu-collapse">
                    <div className="menu-collapse-item">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"></path>
                      </svg>
                      <span className="menu-item-text">Comptabilités</span>
                    </div>
                    <ul>
                      <li id="agencyAccountOperations" data-menu-item="classic-submenu-2" className="menu-item menu-item-single">
                        <a className="h-full w-full flex items-center" href="/agencyAccountOperations">
                          <span>Bilan de l'agence</span>
                        </a>
                      </li>
                      <li id="landlordAccountOperations" data-menu-item="classic-submenu-2" className="menu-item menu-item-single">
                        <a className="h-full w-full flex items-center" href="/landlordAccountOperations">
                          <span>Bilan par bailleurs</span>
                        </a>
                      </li>
                      <li id="agentAccountOperations" data-menu-item="classic-submenu-2" className="menu-item menu-item-single">
                        <a className="h-full w-full flex items-center" href="/agentAccountOperations">
                          <span>Bilan par agent</span>
                        </a>
                      </li>
                      <li id="accountOperations" data-menu-item="classic-submenu-2" className="menu-item">
                        <a className="h-full w-full flex items-center" href="/accountOperations">
                          <span>Journal par compte</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  {/* )} */}
                  {userHasPermission("SeeUsers") && (
                    <li id="users" data-menu-item="classic-single-menu" className="menu-item menu-item-single mb-2">
                      <a className="menu-item-link" href="/users">
                        <span className="menu-item-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"></path>
                          </svg>
                        </span>
                        <span className="menu-item-text">Utilisateurs</span>
                      </a>
                    </li>
                  )}
                </>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
