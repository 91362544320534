export function getStringArrayFromObjectArray(data: any[]): any[] {
  const unique: any[] = data.map((item: any) => item.value);
  return unique;
}

export function getObjectArrayFromIdStringArray(
  data: any[],
  ids: any[]
): any[] {
  let result: any[] = [];
  ids.forEach((id) => {
    result.push(
      data.filter((obj) => {
        return obj.id == id;
      })
    );
  });
  return result;
}

export function search(list: any[], searchTerme: string): any[] {
  let result: any[] = [];
  list.filter(o => {
    return Object.entries(o).forEach(([key, value]) => {
      var valueToCheck = value;
      
      if ((typeof value) === "object") {
        //FIXME: Trouver une autre facon de parser les objects complexes
        valueToCheck = JSON.stringify(value); 
      }
      
      if (valueToCheck !== null && valueToCheck !== undefined) {
        if (String(valueToCheck).toLocaleLowerCase().includes(searchTerme)) {
          result.push(o);
        }
      }
    });
  });
  result = Object.values(result.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {}))
  return result
}

export function mapObjectToFormData(object: any): FormData {
  let formData = new FormData();
  Object.entries(object!).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      if (key !== "idBackFile" && key !== "idFrontFile") {
        formData.append(`${key}`, `${value}`);
      }
    }
  });
  return formData;
}

export function mapFormDataToObject(data: FormData): FormData {
  let value: any = {};
  const pairs = Array.from(data.entries());
  for (let pair of pairs) {
    value = { ...value, [pair[0]]: pair[1] };
  }
  return value;
}

export function sortedArray(array: any[]): any[] {
  array.sort(
    (a: any, b: any) =>
      new Date(b.dateModified).getTime() - new Date(a.dateModified).getTime()
  );
  return array;
}

export function updateArrayByItemId(array: any[], item: any): any[] {
  let indexOf = array.findIndex((a) => a.id === item.id);
  array.splice(indexOf, 1);
  array.push(item);
  array.sort(
    (a: any, b: any) =>
      new Date(b.dateModified).getTime() - new Date(a.dateModified).getTime()
  );
  return array;
}

export function addNewItemToObjectArray(array: any[], item: any): any[] {
  array.push(item);
  array.sort(
    (a: any, b: any) =>
      new Date(b.dateModified).getTime() - new Date(a.dateModified).getTime()
  );
  return array;
}

export function removeItemFromObjectArray(array: any[], id: string): any[] {
  let indexOf = array.findIndex((a) => a.id === id);
  array.splice(indexOf, 1);
  array.sort(
    (a: any, b: any) =>
      new Date(b.dateModified).getTime() - new Date(a.dateModified).getTime()
  );
  return array;
}
