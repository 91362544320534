import React, { createRef, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import Alerts from "../../../helpers/Alerts";
import Spinners from "../../../helpers/Spinners";
import { selectLandlordStatus } from "../landlordSlice";

interface LandlordPaymentDialogProps {
  landlord: ILandlord;
  deposit: IMakeDeposit | undefined;
  title: string;
  dialogId: string;
  handleChange: (objectKey: string, value: any) => void;
  initializePayment: () => void;
  handleMakeDeposit?: (deposit: IMakeDeposit) => void;
  landlordId?: string;
  properties: IProperty[];
}

const LandlordPaymentDialog: React.FC<LandlordPaymentDialogProps> = (props) => {
  const {
    deposit,
    title,
    dialogId,
    landlordId,
    handleChange,
    initializePayment,
    handleMakeDeposit,
    properties,
  } = props;
  const status = useAppSelector(selectLandlordStatus);
  const closeDialogRef = createRef<HTMLSpanElement>();

  useEffect(() => {
    if (status === "success") {
      closeDialogRef.current?.click();
    }
    console.log("proprietés", properties);
  }, [status]);

  const formValidate = (): boolean => {
    return (
      !deposit ||
      deposit.amount === undefined ||
      deposit.amount === 0 ||
      deposit.paymentMethod === undefined ||
      deposit.paymentMethod === ""
    );
  };

  const handleRent = () => {
    const depositData = { ...deposit, landlordId: props.landlordId };
    handleMakeDeposit!(depositData!);
  };

  const paymentMethodOptions: any[] = [
    { value: "Cash", label: "Comptant" },
    { value: "OrangeMoney", label: "Orange Money" },
    { value: "MoovMoney", label: "Moov Money" },
    { value: "Wave", label: "Wave" },
    { value: "SamaMoney", label: "Sama Money" },
    { value: "Cheque", label: "Chèque" },
  ];

  return (
    <>
      <div
        className="modal fade"
        id={dialogId}
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog dialog">
          <div className="modal-content dialog-content">
            <span
              ref={closeDialogRef}
              className="close-btn absolute z-10 ltr:right-6 rtl:left-6"
              role="button"
              data-bs-dismiss="modal"
              onClick={() => initializePayment()}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h5 className="mb-4">{title}</h5>
            <div>
              <form action="#">
                <div className="form-container vertical">
                  <div className="form-item vertical">
                    <label className="form-label mb-2"> Propriété </label>
                    <div>
                      <div className="input-group">
                        <select
                          className="input"
                          name="propertyId"
                          value={deposit?.propertyId}
                          onChange={(e) =>
                            handleChange("propertyId", e.target.value)
                          }
                        >
                          <option
                            value=""
                            label="Choisissez une propriété"
                          ></option>
                          {properties.map((property, index) => {
                            if (property?.landlord?.id === landlordId) {
                              return (
                                <option
                                  key={property.id}
                                  value={property.id}
                                  label={property.name}
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Montant *</label>
                    <div>
                      <div className="input-group">
                        <input
                          className="input"
                          type="number"
                          name="amount"
                          placeholder="Montant"
                          value={deposit?.amount}
                          onChange={(e) =>
                            handleChange("amount", Number(e.target.value))
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">
                      Mode de paiement *
                    </label>
                    <div>
                      <div className="input-group">
                        <select
                          className="input"
                          name="paymentMethod"
                          value={deposit?.paymentMethod}
                          onChange={(e) =>
                            handleChange("paymentMethod", e.target.value)
                          }
                        >
                          <option
                            value=""
                            label="Choisissez une option"
                          ></option>
                          {paymentMethodOptions?.map((op, index) => (
                            <option
                              key={index}
                              value={op.value}
                              label={op.label}
                            />
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2"> Commentaires </label>
                    <div>
                      <div className="input-group">
                        <textarea
                          className="input input-textarea"
                          value={deposit?.comments}
                          name="Comments"
                          placeholder="Mettez un commentaire ici"
                          onChange={(e) =>
                            handleChange("comments", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="text-right mt-6">
              <button
                className="btn btn-plain ltr:mr-2 rtl:ml-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  // dispatch(initializeActionStatus());
                  initializePayment();
                }}
              >
                Annuler
              </button>
              <button
                className="btn btn-solid"
                onClick={handleRent}
                disabled={status === "pending" || formValidate()}
              >
                <span className="flex items-center justify-center">
                  Sauvegarder
                  {status === "pending" && <Spinners sizeFromProps={30} />}
                </span>
              </button>
            </div>
            {status === "failed" && (
              <Alerts
                type={"alert-danger"}
                message={
                  "Erreur lors de cette opération. Veuillez reessayer!!!"
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LandlordPaymentDialog;
