import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ReactApexChart from "react-apexcharts";
import AccountSummary from "../commonComponent/AccountSummary";
import PaymentSummary from "../commonComponent/PaymentSummary";
import { selectLandlordChartData, selectLandlordAccountOperations } from "../landlords/landlordSlice";
import { setSelectedProperty } from "../properties/propertySlice";
import { selectAuthUserPermissions } from "../account/user/userSlice";
import $ from "jquery";
import Spinners from "../../helpers/Spinners";
import { search } from "../../helpers/objectMapper";
import Alerts from "../../helpers/Alerts";
import DataTable from "react-data-table-component";
import { addAgencyExpenseAsync, getAgencyAccountOperationsAsync, getAgencyChartDataAsync, selectAccountList, selectAccountStatus, selectAgencyChartData } from "../accountOperation/accountOperationSlice";
import AgencyExpenseDialog from "../accountOperation/component/AgencyExpenseDialog";
import DepositViwerDialog from "../landlords/components/DepositViwerDialog";

export function AgencyDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccountList);
  const status = useAppSelector(selectAccountStatus);
  const agencyChartData = useAppSelector(selectAgencyChartData);
  const landlordAccountOperations = useAppSelector(selectLandlordAccountOperations);
  const AuthUserPermissions = useAppSelector(selectAuthUserPermissions);
  const [OperationfilterText, setOperationFilterText] = useState<string>("");
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [chartOptions, setChartOptions] = useState<any>({ series: [] });
  const [expense, setExpense] = useState<IAddAgencyExpense>();
  const [depositAccountOperations, setDepositAccountOperations] = useState<IAccountOperation[]>();

  useEffect(() => {
    if (agencyChartData == undefined) {
      dispatch(getAgencyChartDataAsync());
    }
    dispatch(getAgencyAccountOperationsAsync());
  }, []);

  useEffect(() => {
    setLoading(status === "pending");
    setShowError(status === "failed");
  }, [status]);

  useEffect(() => {
    if (agencyChartData && chartLoading) {
      setChartOptions({
        series: agencyChartData,
        chart: {
          type: "bar",
          height: 425,
        } as ApexChart,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Jul", "Aout", "Sep", "Oct", "Nov", "Dec"],
        },
        yaxis: {
          title: {
            text: "FCFA",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val + " FCFA";
            },
          },
        },
      });
      setChartLoading(false);
    }
  }, [agencyChartData]);

  useEffect(() => {
    let result = accounts?.filter((op) => op.isDeposit === true);
    setDepositAccountOperations(result);
  }, [accounts]);

  const getOperationTypeDescription = (type: any) => {
    return type === 0 ? "Crédit" : "Débit";
  };

  const expensesSum = (): number => {
    let result = accounts?.filter((op: any) => op.type === 1)?.map((acc: any) => acc.amount);
    if (result && result.length > 0) {
      return result?.reduce((a: any, b: any) => {
        return a! + b!;
      })!;
    }
    return 0;
  };

  const incomesSum = (): number => {
    let result = accounts?.filter((op: any) => op.type === 0)?.map((acc: any) => acc.amount);
    if (result && result.length > 0) {
      return result?.reduce((a: any, b: any) => {
        return a! + b!;
      })!;
    }
    return 0;
  };

  const depositSum = (): number => {
    let result = accounts?.filter((op: any) => op.isDeposit === true)?.map((acc: any) => acc.amount);
    if (result && result.length > 0) {
      return result?.reduce((a: any, b: any) => {
        return a! + b!;
      })!;
    }
    return 0;
  };

  // const getPropertyById = (id: string): IProperty => {
  //   return properties.find((l: any) => l.id === id)!;
  // };

  const userHasPermission = (action: string) => {
    if (AuthUserPermissions.some((permission: any) => permission === action)) {
      return true;
    }
    return false;
  };

  const columns = useMemo(
    () => [
      {
        name: "Nom",
        selector: (row: any) => row.name,
        sortable: true,
      },
      {
        name: "Type",
        selector: (row: any) => row.type,
        sortable: true,
      },
      {
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        cell: (row: any) => (
          <div className="flex justify-end text-lg">
            {userHasPermission("UpdateTenant") && (
              <span className="cursor-pointer p-2 hover:text-indigo-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  width="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  aria-hidden="true"
                  strokeWidth="2"
                  onClick={() => {
                    dispatch(setSelectedProperty(row!));
                    navigate(`/properties/details/${row.id}`);
                  }}
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </span>
            )}
          </div>
        ),
      },
    ],
    []
  );

  // const dynamicSearch = (terme: any) => {
  //   setFilterText(terme);
  //   let result = search(landlordProperties!, String(terme).toLocaleLowerCase());
  //   setFilteredProperties(result as ITenant[]);
  //   console.log("result", result);
  // };

  const depositOperationColumns = useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) =>
          new Date(row.date).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        sortable: true,
      },
      {
        name: "Montant",
        selector: (row: any) => new Intl.NumberFormat("fr-FR").format(row.amount),
        sortable: true,
      },
      {
        name: "Commentaires",
        selector: (row: any) => row.comments,
        sortable: true,
      },
    ],
    []
  );

  const operationColumns = useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) =>
          new Date(row.date).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        sortable: true,
        width: "150px",
      },
      {
        name: "Mode de paiement",
        selector: (row: any) => row.paymentMethod,
        sortable: true,
        width: "180px",
      },
      {
        name: "Type",
        selector: (row: any) => getOperationTypeDescription(row.type),
        sortable: true,
        width: "140px",
      },
      {
        name: "Montant",
        selector: (row: any) => new Intl.NumberFormat("fr-FR").format(row.amount),
        sortable: true,
        width: "120px",
      },
      {
        name: "Commentaires",
        selector: (row: any) => row.comments,
        sortable: true,
        width: "480px",
      },
    ],
    []
  );

  const OperationDynamicSearch = (terme: any) => {
    setOperationFilterText(terme);
    let result = search(landlordAccountOperations!, String(terme).toLocaleLowerCase());
    // setFilteredAccountOperations(result as IAccountOperation[]);
    // console.log("result", result);
  };

  const handleExpenseChange = (objectKey: string, value: any) => {
    setExpense((prevState: any) => {
      return { ...prevState, [objectKey]: value };
    });
  };

  const handleAddExpense = (expense: IAddAgencyExpense) => {
    dispatch(addAgencyExpenseAsync(expense!));
  };

  const initializePayment = () => {
    setExpense({
      amount: 0,
      comments: "",
      paymentMethod: "",

      paymentDate: "",
      categories: [],
    });
  };

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="flex flex-col gap-4 h-full">
            <div className="lg:flex items-center justify-between mb-4 gap-3">
              <div className="mb-4 lg:mb-0">
                <h3>Compte d'agence</h3>
              </div>
              <div className="flex items-center gap-2">
                <div className="dropdown">
                  <div className="dropdown-toggle" id="dropdown-toggle-42-BXqtaPjU7e">
                    <button className="btn btn-default btn-sm" type="button" data-bs-toggle="modal" data-bs-target={"#tenantleasesdialog"} disabled={loading}>
                      <span className="flex items-center justify-center">
                        <span className="text-lg" style={{ marginTop: "-5px" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"></path>
                          </svg>
                        </span>
                        <span className="ltr:ml-1 rtl:mr-1">
                          <span className="hidden sm:block">&nbsp; Cautions</span>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
              <PaymentSummary payment={{ amount: depositSum(), title: "Total cautions" }} />
              <PaymentSummary payment={{ amount: expensesSum(), title: "Total debits" }} />
              <PaymentSummary payment={{ amount: incomesSum(), title: "Total crédits" }} />
              <PaymentSummary
                payment={{
                  amount: incomesSum() - expensesSum(),
                  title: "Solde",
                }}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4">
              {/* <AccountSummary person={landlord} propertyName={"agency"} deleteTarget={"delete-landlord"} /> */}
              <div className="card card-layout-frame col-span-2">
                <div className="card-body">
                  <div className="flex items-center justify-between">
                    <h4>Rapport des opérations</h4>
                    {/* <button className="btn btn-sm btn-default">Export Report</button> */}
                    <div>
                      <select className="input input-md">
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <ReactApexChart options={chartOptions} series={chartOptions?.series!} type="bar" height={425} />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div className="card card-layout-frame col-span-2">
                <div className="card-body">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                      <h4>Liste des opérations</h4>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:items-center justify-end gap-3">
                      <input id="samll-date-query-input" className="input input-sm pr-8" placeholder="Rechercher..." value={OperationfilterText} onChange={(e: any) => OperationDynamicSearch(e.target.value)} />
                      <button className="btn btn-two-tune btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#addPayment">
                        <span className="flex items-center justify-center gap-2">
                          <span className="text-lg">
                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                          </span>
                          <span>Effectuer une dépense</span>
                        </span>
                      </button>
                    </div>
                  </div>
                  <br />

                  <div className="overflow-x-auto">
                    {loading ? (
                      <>
                        <div className="lg:flex items-center justify-center">
                          <Spinners sizeFromProps={50} />
                        </div>
                      </>
                    ) : showError ? (
                      <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
                    ) : (
                      <>
                        {/* Section de filtre */}
                        <div className="lg:flex items-center justify-end mb-4 gap-3"></div>
                        <div className="overflow-x-auto">
                          <DataTable columns={operationColumns} pagination data={accounts!} striped={true} paginationPerPage={25} paginationRowsPerPageOptions={[25, 50, 100]} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AgencyExpenseDialog expense={expense} dialogId={"addPayment"} title={"Faire un versement au bailleur"} handleChange={handleExpenseChange} initializePayment={initializePayment} handleAddExpense={handleAddExpense} />
        <DepositViwerDialog depositAccountOperations={depositAccountOperations!} />
      </main>
    </>
  );
}
