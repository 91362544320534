import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Alerts from "../../helpers/Alerts";
import Spinners from "../../helpers/Spinners";
import LeaseDialog from "./components/leaseDialog";
import {
  createLeaseAsync,
  getLeasesAsync,
  terminateLeaseAsync,
  selectLeaseList,
  selectLeaseStatus,
} from "./leaseSlice";
import {
  getPropertiesAsync,
  selectPropertyList,
  setSelectedProperty,
} from "../properties/propertySlice";
import {
  getLandlordsAsync,
  selectLandlordList,
  setSelectedLandlord,
} from "../landlords/landlordSlice";
import { getTenantsAsync, selectTenantList } from "../tenant/tenantSlice";
import { selectAuthUserPermissions } from "../account/user/userSlice";
import DataTable from "react-data-table-component";
import { search } from "../../helpers/objectMapper";
import { useNavigate } from "react-router-dom";

export function LeasesPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const leases = useAppSelector(selectLeaseList);
  const properties = useAppSelector(selectPropertyList);
  const landlords = useAppSelector(selectLandlordList);
  const tenants = useAppSelector(selectTenantList);
  const status = useAppSelector(selectLeaseStatus);
  const AuthUserPermissions = useAppSelector(selectAuthUserPermissions);
  const [lease, setLease] = useState<ILease>();
  const [dialogId, setDialogId] = useState("addLease");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>("");
  const [title, setTitle] = useState("");
  const [filteredLeases, setFilteredLeases] = useState<ILease[]>();
  const [filterText, setFilterText] = useState<string>("");

  useEffect(() => {
    if (leases && leases.length === 0) {
      dispatch(getLeasesAsync());
    }
    if (landlords && landlords.length === 0) {
      dispatch(getLandlordsAsync());
    }
    if (properties && properties.length === 0) {
      dispatch(getPropertiesAsync());
    }
    if (tenants && tenants.length === 0) {
      dispatch(getTenantsAsync());
    }
  }, []);

  useEffect(() => {
    setLoading(status === "pending");
    setShowError(status === "failed");
  }, [status]);

  useEffect(() => {
    setFilteredLeases(leases);
  }, [leases]);

  const openDialog = (actionType: string) => {
    if (actionType == "add") {
      setActionType(actionType);
      setTitle("Ajouter un nouveau contrat");
    } else if (actionType == "edit") {
      setActionType(actionType);
      setTitle("Modifier un contrat");
    } else if (actionType == "delete") {
      setActionType(actionType);
      setTitle("Résilier un contrat");
    }
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const handleChange = (objectKey: string, value: any) => {
    setLease((prevLease) => ({
      ...prevLease,
      [objectKey]: value,
    }));
  };

  const initializeLease = () => {
    // setLease({
    //   landlordId: "",
    // number: "",
    // propertyId: "",
    // unitId: "",
    // tenantId: "",
    // id: "",
    // landlord: undefined,
    // property: undefined,
    // unit: undefined,
    // tenant: undefined,
    // startDate: "",
    // endDate: "",
    // rentAmount: 0,
    // depositAmount: 0,
    // advanceAmount: 0,
    // paymentDate: "",
    // months: [],
    // paymentMethod: "",
    // commission: 0,
    // belongsToInternalAgent: false,
    // depositResponsible: "",
    // rentPayments: undefined,
    // dateCreated: "",
    // dateModified: "",
    // } as ILease);
  };

  const handleCreateLease = (leaseData: ILease) => {
    dispatch(createLeaseAsync(leaseData!));
  };

  // const handleEditLease = (leaseData: ICreateOrUpdateLeaseData) => {
  //     dispatch(updateLeaseAsync(leaseData!))
  // }

  const handleterminateLease = (leaseData: ILease) => {
    dispatch(terminateLeaseAsync(leaseData));
  };

  const userHasPermission = (action: string) => {
    if (AuthUserPermissions.some((permission) => permission === action)) {
      return true;
    }
    return false;
  };

  const columns = useMemo(
    () => [
      {
        name: "N° du contrat",
        selector: (row: any) => row.number,
        sortable: true,
      },
      {
        name: "Nom du bailleur",
        selector: (row: any) =>
          userHasPermission("UpdateLandlord") && (
            <span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/landlords/details/${row.landlord?.id}`);
                }}
              >
                {row.landlord?.firstName + " " + row.landlord?.lastName}
              </span>
            </span>
          ),
        sortable: true,
      },
      {
        name: "Nom du bien",
        selector: (row: any) =>
          userHasPermission("UpdateProperty") && (
            <span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/properties/details/${row.property?.id}`);
                }}
              >
                {row.property?.name}
              </span>
            </span>
          ),
        sortable: true,
      },
      {
        name: `Nom de l'unité`,
        selector: (row: any) => row.unit?.name,
        sortable: true,
      },
      {
        name: `Nom du locataire`,
        selector: (row: any) =>
          userHasPermission("UpdateTenant") && (
            <span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/Tenants/details/${row.tenant?.id}`);
                }}
              >
                {row.tenant?.firstName + " " + row.tenant?.lastName}
              </span>
            </span>
          ),
        sortable: true,
      },
      {
        name: `Statut`,
        selector: (row: any) => row.unit?.isOccupied,
        sortable: true,
        cell: (row: any) =>
          row.unit?.isOccupied ? (
            <div className="tag bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 border-0">
              En cours
            </div>
          ) : (
            <div className="tag text-white bg-orange-100 dark:text-red-100 dark:bg-red-500/20 border-0">
              Résilié
            </div>
          ),
      },
      {
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        cell: (row: any) => (
          <div className="flex justify-end text-lg">
            {userHasPermission("DeleteLease") && row.unit?.isOccupied && (
              <span
                className="cursor-pointer p-2 hover:text-red-500"
                data-bs-toggle="modal"
                data-bs-target={`#${dialogId}`}
                onClick={() => {
                  openDialog("delete");
                  setLease(row as ILease);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  strokeWidth="4"
                >
                  <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                </svg>
              </span>
            )}
            {userHasPermission("UpdateLease") && (
              <span
                className="cursor-pointer p-2 hover:text-indigo-600"
                data-bs-toggle="modal"
                data-bs-target={`#${dialogId}`}
                onClick={() => {
                  setLease((prevState: any) => {
                    return {
                      ...prevState,
                      landlordId: row.landlord?.id!,
                      propertyId: row.property?.id!,
                      unitId: row.unit?.id!,
                      tenantId: row.tenant?.id!,
                      startDate: row.startDate!,
                      endDate: row.endDate!,
                      rentAmount: row.rentAmount!,
                      depositAmount: row.depositAmount!,
                      depositResponsible: row.depositResponsible!,
                      depositPaymentMethod: lease?.depositPaymentMethod!,
                      refundPaymentMethod: lease?.refundPaymentMethod,
                      advanceAmount: row.advanceAmount!,
                      commission: row.commission!,
                    };
                  });
                  openDialog("edit");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  width="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  aria-hidden="true"
                  strokeWidth="2"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </span>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const dynamicSearch = (terme: any) => {
    setFilterText(terme);
    let result = search(leases!, String(terme).toLocaleLowerCase());
    setFilteredLeases(result as ITenant[]);
    console.log("result", result);
  };

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="container mx-auto">
            <div className="card adaptable-card">
              <div className="card-body">
                <div className="lg:flex items-center justify-between mb-4">
                  <h3 className="mb-4 lg:mb-0">Contrats</h3>
                  {userHasPermission("AddLease") && (
                    <button
                      className="btn btn-two-tune btn-sm"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target={`#${dialogId}`}
                      onClick={() => {
                        setDialogId("addLease");
                        openDialog("add");
                      }}
                      disabled={loading}
                    >
                      <span className="flex items-center justify-center gap-2">
                        <span className="text-lg">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                        </span>
                        <span>Ajouter</span>
                      </span>
                    </button>
                  )}
                </div>
                {loading ? (
                  <>
                    <div className="lg:flex items-center justify-center">
                      <Spinners sizeFromProps={50} />
                    </div>
                  </>
                ) : showError ? (
                  <Alerts
                    type={"alert-danger"}
                    message={
                      "Erreur lors de cette opération. Veuillez reessayer!!!"
                    }
                  />
                ) : (
                  <>
                    {/* Section de filtre */}
                    <div className="lg:flex items-center justify-end mb-4 gap-3">
                      <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                        <input
                          id="date-query-input"
                          className="input input-sm pr-8"
                          placeholder="Rechercher..."
                          value={filterText}
                          onChange={(e: any) => dynamicSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="overflow-x-auto">
                      <DataTable
                        columns={columns}
                        pagination
                        data={filteredLeases!}
                        striped={true}
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[25, 50, 100]}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <LeaseDialog
          lease={lease!}
          title={title}
          actionType={actionType}
          dialogId={dialogId}
          showDialog={showDialog}
          closeDialog={closeDialog}
          handleChange={handleChange}
          handleCreateLease={handleCreateLease}
          initializeLease={initializeLease}
          handleTerminateLease={handleterminateLease}
        />
      </main>
    </>
  );
}
