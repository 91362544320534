import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface propertyEditNavProps {
  activeNav?: (itemId: string, sectionId: string) => void;
}

const propertyEditNav: React.FC<propertyEditNavProps> = (props) => {
  const { activeNav } = props;

  return (
    <>
      <nav className="menu menu-transparent px-2">
        <div
          id="menu-item-0"
          className="menu-item section-menu-item menu-item-active mb-2"
          onClick={() =>
            activeNav!("menu-item-0", "basic-information-section")
          }
        >
          <span className="text-2xl ltr:mr-2 rtl:ml-2">
            <svg
              className="checked-icon text-gray-400"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              aria-hidden="true"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
          <span>Informations de base</span>
        </div>
        <div
          id="menu-item-2"
          className="menu-item section-menu-item mb-2 h-[40px]"
          onClick={() => activeNav!("menu-item-2", "unity-section")}
        >
          <span className="text-2xl ltr:mr-2 rtl:ml-2">
            <svg
              className="locked-icon text-gray-400"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              aria-hidden="true"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
          <span>Unités</span>
        </div>
      </nav>
    </>
  );
};

export default propertyEditNav;
