export const api = getApiUrl();

function getApiUrl() {
    var host = window.location.hostname;
    switch (host.toLowerCase()) {
        case "localhost":
            return "http://gninigali-001-site8.ftempurl.com";           
            //return "https://localhost:7234";

        case "gninigali-001-site9.ftempurl.com":
            return "http://gninigali-001-site8.ftempurl.com";

        case "gninigali-001-site11.ftempurl.com":
            return "http://gninigali-001-site10.ftempurl.com";

        case "jikkeimmobiliere.com":
            return "https://jikke-api.jikkeimmobiliere.com"

        default:
        //do nothing
    }
}