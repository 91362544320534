import React, { createRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Alerts from '../../../helpers/Alerts';
import { selectLandlordStatus, initializeActionStatus } from '../landlordSlice';


interface LandlordDialogProps {
    landlord: ILandlord;
    handleDeleteLandlord?: (landlordId: string) => void;
}

const LandlordDialog: React.FC<LandlordDialogProps> = (props) => {
    const {
        landlord,
        handleDeleteLandlord
    } = props;

    const dispatch = useAppDispatch();
    const status = useAppSelector(selectLandlordStatus);
    const closeDialogRef = createRef<HTMLSpanElement>();

    useEffect(() => {
        if (status === 'success') {
            closeDialogRef.current?.click();
        }
    }, [status])

    const handleDelete = () => {
        handleDeleteLandlord!(landlord.id!)
    }


    return (
        <>
            <div className="modal fade" id="delete-landlord" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog dialog">
                    <div className="modal-content dialog-content">
                        <span
                            ref={closeDialogRef}
                            className="close-btn absolute z-10 ltr:right-6 rtl:left-6"
                            role="button"
                            data-bs-dismiss="modal"
                            onClick={() => dispatch(initializeActionStatus())}
                        >
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </span>
                        <h5 className="mb-4">Supprimer un bailleur</h5>
                        <div>
                            <p>Êtes-vous sûr de vouloir supprimer le bailleur <strong>{landlord?.firstName} {landlord?.lastName}</strong>?</p>

                        </div>
                        <div className="text-right mt-6">
                            <button
                                className="btn btn-plain ltr:mr-2 rtl:ml-2"
                                data-bs-dismiss="modal"
                                onClick={() => dispatch(initializeActionStatus())}
                            >
                                Non
                            </button>
                            <button
                                className="btn btn-solid"
                                onClick={() => handleDelete()}
                                disabled={status === 'pending'}
                            >
                                <span className="flex items-center justify-center">
                                    Oui
                                    {status === 'pending' && (
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            className="animate-spin ml-1 w-5 h-5 h-[30px] w-[30px]"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.2"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                fill="currentColor"
                                            ></path>
                                            <path
                                                d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    )}
                                </span>
                            </button>
                        </div>
                        {status === 'failed' && (
                            <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LandlordDialog;
