import { rejects } from "assert";
import axios from "axios";
import { mapFormDataToObject } from "../../helpers/objectMapper";
import { api } from "../../services/apiHelpers";

const axiosHeadersConfig = () => {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  if (user.accessToken) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + user.accessToken;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export function getTenants() {
  axiosHeadersConfig();
  return new Promise<{ data: ITenant[]; status: number }>((resolve, reject) => {
    axios
      .get(api + "/Tenants/list")
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function createTenant(data: FormData) {
  return new Promise<{ data: ITenant; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Tenants/create", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function updateTenant(data: FormData) {
  return new Promise<{ data: ITenant; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Tenants/update", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function deleteTenant(tenantId: string) {
  return new Promise<{ data: string; status: number }>((resolve, reject) => {
    axios
      .delete(api + `/Tenants/${tenantId}`)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: tenantId, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function getTenantRentPayments(id: string) {
  axiosHeadersConfig();
  return new Promise<{ data: IPayment[]; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Tenants/rentPayments/${id}`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function addPayment(data: IPayment) {
  return new Promise<{ data: IPayment; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Tenants/rentPayments/add", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function updateRentPayment(data: IUpdatePaymentData) {
  return new Promise<{ data: IPayment[]; status: number }>(
    (resolve, reject) => {
      axios
        .post(api + "/Tenants/rentPayments/update", data)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function getTenantChartData(id: string) {
  axiosHeadersConfig();
  return new Promise<{ data: IChartData; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Tenants/charts/rentPayments/${id}`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}
