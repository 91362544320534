import axios from "axios";
import { api } from "../../services/apiHelpers";

const axiosHeadersConfig = () => {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  if (user.accessToken) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + user.accessToken;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};


export function getHomeChartData() {
  axiosHeadersConfig();
  return new Promise<{ data: IHomeData; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/pages/dashboard`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

