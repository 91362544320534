import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import {
  addNewItemToObjectArray,
  mapObjectToFormData,
  removeItemFromObjectArray,
  sortedArray,
  updateArrayByItemId,
} from "../../helpers/objectMapper";
import {
  createLandlord,
  deleteLandlord,
  getLandlordAccountOperations,
  getLandlordsPropertiesList,
  getLandlordChartData,
  getLandlords,
  makeDeposit,
  updateLandlord,
} from "./landlordAPI";

export interface LandlordState {
  landlordList: ILandlord[];
  selectedLandlord: ILandlord | null;
  landlordChartData?: IChartData;
  properties?: IProperty[];
  status: "pending" | "success" | "failed" | "";
  accountOperationsStatus: "pending" | "success" | "failed" | "";
  propertyStatus: "pending" | "success" | "failed" | "";
  accountOperations?: IAccountOperation[];
}

const initialState: LandlordState = {
  landlordList: [],
  selectedLandlord: null, //
  landlordChartData: undefined,
  properties: [],
  status: "",
  accountOperationsStatus: "",
  accountOperations: [],
  propertyStatus: ""
};

export const getLandlordsAsync = createAsyncThunk(
  "landlord/getLandlords",
  async () => {
    const response = await getLandlords();
    return response.data;
  }
);

export const createLandlordAsync = createAsyncThunk(
  "landlord/create",
  async (data: ILandlord) => {
    let formData = mapObjectToFormData(data);
    if (data.idFrontFile !== null && data.idFrontFile !== undefined) {
      formData.append("idFrontFile", data.idFrontFile!);
    }
    if (data.idBackFile !== null && data.idBackFile !== undefined) {
      formData.append("idBackFile", data.idBackFile!);
    }
    const response = await createLandlord(formData);
    return response.data;
  }
);

export const updateLandlordAsync = createAsyncThunk(
  "landlord/update",
  async (data: ILandlord) => {
    let formData = mapObjectToFormData(data);
    if (data.idFrontFile !== null && data.idFrontFile !== undefined) {
      formData.append("idFrontFile", data.idFrontFile!);
      formData.delete("idFrontFileUrl");
    }
    if (data.idBackFile !== null && data.idBackFile !== undefined) {
      formData.append("idBackFile", data.idBackFile!);
      formData.delete("idBackFileUrl");
    }
    const response = await updateLandlord(formData);
    return response.data;
  }
);

export const deleteLandlordAsync = createAsyncThunk("landlord/delete", async (landlordId: string) => {
  const response = await deleteLandlord(landlordId);
  return response.data;
}
);

export const makeDepositAsync = createAsyncThunk(
  "landlord/makeDeposit",
  async (data: IMakeDeposit) => {
    const response = await makeDeposit(data);
    return response.data;
  }
);

export const getLandlordChartDataAsync = createAsyncThunk("landlord/getLandlordChartData", async (landlordId: string) => {
  const response = await getLandlordChartData(landlordId);
  return response.data;
});

export const getLandlordAccountOperationDataAsync = createAsyncThunk("landlord/getLandlordAccountOperations", async (landlordId: string) => {
  const response = await getLandlordAccountOperations(landlordId);
  return response.data;
});

export const getPropertiesByLandlordIdAsync = createAsyncThunk("landlord/getLandlordsPropertiesList", async (landlordId: string) => {
  const response = await getLandlordsPropertiesList(landlordId);
  return response.data;
});

export const landlordSlice = createSlice({
  name: "landlord",
  initialState,
  reducers: {
    initializeActionStatus: (state) => {
      state.status = "";
    },
    setSelectedLandlord: (state, action: PayloadAction<ILandlord>) => {
      state.selectedLandlord = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLandlordsAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getLandlordsAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.landlordList = sortedArray(action.payload) as ILandlord[];
      })
      .addCase(getLandlordsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createLandlordAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createLandlordAsync.fulfilled, (state, action) => {
        state.landlordList = addNewItemToObjectArray(
          [...state.landlordList],
          action.payload
        ) as ILandlord[];
        state.status = "success";
      })
      .addCase(createLandlordAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateLandlordAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateLandlordAsync.fulfilled, (state, action) => {
        state.landlordList = updateArrayByItemId(
          [...state.landlordList],
          action.payload
        ) as ILandlord[];
        state.status = "success";
      })
      .addCase(updateLandlordAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteLandlordAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(deleteLandlordAsync.fulfilled, (state, action) => {
        state.landlordList = removeItemFromObjectArray(
          [...state.landlordList],
          action.payload
        ) as ILandlord[];
        state.status = "success";
      })
      .addCase(deleteLandlordAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(makeDepositAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(makeDepositAsync.fulfilled, (state, action) => {
        state.landlordList = updateArrayByItemId(
          [...state.landlordList],
          action.payload
        ) as ILandlord[];
        state.status = "success";
      })
      .addCase(makeDepositAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getLandlordChartDataAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getLandlordChartDataAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.landlordChartData = action.payload;
      })
      .addCase(getLandlordChartDataAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getLandlordAccountOperationDataAsync.pending, (state) => {
        state.accountOperationsStatus = "pending";
      })
      .addCase(getLandlordAccountOperationDataAsync.fulfilled, (state, action) => {
        state.accountOperationsStatus = "success";
        state.accountOperations = action.payload;
      })
      .addCase(getLandlordAccountOperationDataAsync.rejected, (state) => {
        state.accountOperationsStatus = "failed";
      })
      .addCase(getPropertiesByLandlordIdAsync.pending, (state) => {
        state.propertyStatus = "pending";
      })
      .addCase(getPropertiesByLandlordIdAsync.fulfilled, (state, action) => {
        state.propertyStatus = "success";
        state.properties = action.payload;
      })
      .addCase(getPropertiesByLandlordIdAsync.rejected, (state) => {
        state.propertyStatus = "failed";
      });
  },
});

export const { initializeActionStatus, setSelectedLandlord } = landlordSlice.actions;

export const selectLandlordList = (state: RootState) => state.landlord.landlordList;
export const selectSelectedLandlord = (state: RootState) => state.landlord.selectedLandlord;
export const selectLandlordChartData = (state: RootState) => state.landlord.landlordChartData;
export const selectLandlordProperties = (state: RootState) => state.landlord.properties;
export const selectLandlordAccountOperations = (state: RootState) => state.landlord.accountOperations;
export const selectLandlordStatus = (state: RootState) => state.landlord.status;
export const selectAccountOperationsStatus = (state: RootState) => state.landlord.accountOperationsStatus;
export const selectPropertyStatus = (state: RootState) => state.landlord.propertyStatus;

export default landlordSlice.reducer;
