import React, { useEffect, useState } from 'react';

interface PersonalInformationSectionProps {
    tenant: ITenant;
    activeNav?: (itemId: string, sectionId: string) => void;
    handleChange: (objectKey: string, value: any) => void;
}

const PersonalInformationSection: React.FC<PersonalInformationSectionProps> = (props) => {

    const {
        tenant,
        handleChange,
        activeNav
    } = props;

    return (
        <>
            <div id="personal-information-section" className="content-section">
                <div className="mb-8">
                    <h3 className="mb-2">Information personnelle</h3>
                    <p>Information de base pour une ouverture de compte</p>
                </div>
                <form action="#">
                    <div className="form-container vertical">
                        <div className="md:grid grid-cols-2 gap-4">
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Prénom *</label>
                                <div>
                                    <input
                                        className="input"
                                        type="text"
                                        name="firstName"
                                        placeholder="Prénom"
                                        value={tenant?.firstName}
                                        onChange={(e) => handleChange('firstName', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Nom *</label>
                                <div>
                                    <input
                                        className="input"
                                        type="text"
                                        name="lastName"
                                        placeholder="Nom"
                                        value={tenant?.lastName}
                                        onChange={(e) => handleChange('lastName', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-item vertical">
                            <label className="form-label mb-2">Email</label>
                            <div>
                                <input
                                    className="input"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={tenant?.email}
                                    onChange={(e) => handleChange('email', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="md:grid grid-cols-2 gap-4">
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Civilité</label>
                                <select
                                    className="input"
                                    name='civility'
                                    value={tenant?.civility}
                                    onChange={(e) => handleChange('civility', e.target.value)}
                                >
                                    <option value="">Choisissez une option</option>
                                    <option value="Mr">Mr.</option>
                                    <option value="Mme">Mme.</option>
                                    <option value="Mlle">Mlle.</option>
                                </select>
                            </div>
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Nationalité</label>
                                <div>
                                    <input
                                        className="input"
                                        type="text"
                                        name="nationality"
                                        placeholder="Nationalité"
                                        value={tenant?.nationality}
                                        onChange={(e) => handleChange('nationality', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="md:grid grid-cols-2 gap-4">
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Date de naissance</label>
                                <div>
                                    <span className="input-wrapper undefined">
                                        <input
                                            className="input"
                                            type="date"
                                            name="birthDate"
                                            value={tenant?.birthDate}
                                            onChange={(e) => handleChange('birthDate', e.target.value)}
                                        />
                                        <div className="input-suffix-end">
                                            <svg
                                                stroke="currentColor"
                                                fill="none"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                aria-hidden="true"
                                                className="text-lg"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                                            </svg>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Lieu de naissance</label>
                                <div>
                                    <input
                                        className="input"
                                        type="text"
                                        name="birthPlace"
                                        placeholder="Lieu de naissance"
                                        value={tenant?.birthPlace}
                                        onChange={(e) => handleChange('birthPlace', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="md:grid grid-cols-2 gap-4">
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Genre</label>
                                <select
                                    className="input"
                                    name='gender'
                                    value={tenant?.gender}
                                    onChange={(e) => handleChange('gender', e.target.value)}
                                >
                                    <option value="">Choisissez une option</option>
                                    <option value="Homme">Homme</option>
                                    <option value="Femme">Femme</option>
                                </select>
                            </div>
                            <div className="form-item vertical">
                                <label className="form-label mb-2">État matrimonial</label>
                                <select
                                    className="input"
                                    name='maritalStatus'
                                    value={tenant?.maritalStatus}
                                    onChange={(e) => handleChange('maritalStatus', e.target.value)}
                                >
                                    <option value="">Choisissez une option</option>
                                    <option value="Célibaraire">Célibataire</option>
                                    <option value="Marié(e)">Marié(e)</option>
                                    <option value="Veuf(ve)">Veuf(ve)</option>
                                </select>
                            </div>
                        </div>
                        <div className="md:grid grid-cols-2 gap-4">
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Numéro de téléphone 1 *</label>
                                <div>
                                    <div className="input-group">
                                        <input
                                            className="input"
                                            type="text"
                                            name="phoneNumber1"
                                            placeholder="Numéro de téléphone 1"
                                            value={tenant?.phoneNumber1}
                                            onChange={(e) => handleChange('phoneNumber1', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Numéro de téléphone 2</label>
                                <div>
                                    <div className="input-group">
                                        <input
                                            className="input"
                                            type="text"
                                            name="phoneNumber2"
                                            placeholder="Numéro de téléphone 2"
                                            value={tenant?.phoneNumber2}
                                            onChange={(e) => handleChange('phoneNumber2', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end gap-2">
                            <button
                                className="btn btn-solid"
                                type="button"
                                onClick={() => activeNav!('menu-item-1', 'identification-section')}
                            >
                                Suivant
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default PersonalInformationSection;
