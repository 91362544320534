import React, { useEffect, useState } from "react";
import UnitySection from "./UnitySection";
import BasicInformationSection from "./BasicInformationSection";
import PropertyEditNav from "./PropertyEditNav";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  createPropertyAsync,
  getPropertiesAsync,
  selectPropertyList,
  selectPropertyStatus,
  selectSelectedProperty,
  setSelectedProperty,
  updatePropertyAsync,
  addUnitAsync,
  updateUnitAsync,
  deleteUnitAsync,
} from "../propertySlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getLandlordsAsync,
  selectLandlordList,
} from "../../landlords/landlordSlice";
import Alerts from "../../../helpers/Alerts";
import Spinners from "../../../helpers/Spinners";
import { getUsersAsync, selectUserList } from "../../account/user/userSlice";

interface PropertyEditProps {
  propertyToEdit?: IProperty;
}

const PropertyEdit: React.FC<PropertyEditProps> = (props) => {
  const { propertyToEdit } = props;

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const status = useAppSelector(selectPropertyStatus);
  const properties = useAppSelector(selectPropertyList);
  const landlords = useAppSelector(selectLandlordList);
  const users = useAppSelector(selectUserList);
  const location = useLocation();

  const [actionType, setActionType] = useState<string>("");
  const [property, setProperty] = useState<IProperty>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (landlords && landlords.length === 0) {
      dispatch(getLandlordsAsync());
    }
    if (users && users.length === 0) {
      dispatch(getUsersAsync());
    }
    if (selectedProperty) {
      let value = mapIPropertyToIPropertyUpdateData(selectedProperty);
      setProperty(value);
      setLoading(false);
    } else if (selectedProperty == null) {
      dispatch(getPropertiesAsync());
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setProperty(mapIPropertyToIPropertyUpdateData(selectedProperty!));
      setActionType("edit");
    } else if (location.pathname.includes("create")) {
      setActionType("create");
    }
  }, [location]);

  useEffect(() => {
    if (properties.length > 0) {
      let value = properties.find((l) => l.id == id);
      setProperty(mapIPropertyToIPropertyUpdateData(value!));
      dispatch(setSelectedProperty(value!));
    }
  }, [properties]);

  useEffect(() => {
    if (status === "success") {
      setLoading(false);
    }
    if (status === "failed" && loading === true) {
      setShowError(true);
      setLoading(false);
    }
    if (saving && status === 'success') {
      setSaving(false);
      activeNav("menu-item-2", "unity-section");
    }
  }, [status]);

  const activeNav = (itemId: string, sectionId: string) => {
    /***** Hide other sections and show selected *****/
    hidSections();
    let selectedSection = document.getElementById(sectionId);
    selectedSection?.classList.remove("hidden");

    /***** Disable other nav and active selected *****/
    disableNavs();
    let selectedNav = document.getElementById(itemId);
    selectedNav?.classList.add("menu-item-active");
  };

  const disableNavs = () => {
    let navs = document.getElementsByClassName("section-menu-item");
    for (let index = 0; index < navs.length; index++) {
      const element = navs[index];
      element.classList.remove("menu-item-active");
    }
  };

  const hidSections = () => {
    let sections = document.getElementsByClassName("content-section");
    for (let index = 0; index < sections.length; index++) {
      const element = sections[index];
      element.classList.add("hidden");
    }
  };

  const mapIPropertyToIPropertyUpdateData = (data: IProperty): IProperty => {
    let value: IProperty = {
      id: data?.id,
      type: data?.type,
      landlordId: data?.landlord?.id,
      name: data?.name,
      addressLine: data?.addressLine,
      town: data?.town,
      city: data?.city,
      country: data?.country,
      agencyPercetange: data?.agencyPercetange
    };
    return value;
  };

  const handleChange = (objectKey: string, value: any) => {
    setProperty((prevState) => {
      return { ...prevState, [objectKey]: value };
    });
  };

  const submit = () => {
    setSaving(true);
    if (actionType === "create") {
      dispatch(createPropertyAsync(property!));
    }
    else if (actionType === "edit") {
      dispatch(updatePropertyAsync(property!));
    }
  };

  const addOrUpdateUnity = (propertyId: string, unity: IUnity, action: string) => {
    if (action === "add") {
      let val = {
        name: unity.name,
        rentAmount: unity.rentAmount,
        agentId: unity.agentId,
        agentPercetange: unity.agentPercetange
      }
      dispatch(addUnitAsync({ data: val, propertyId }));
    }
    else if (action === "update") {
      let val = {
        id: unity.id,
        name: unity.name,
        rentAmount: unity.rentAmount,
        agentId: unity.assignedAgent?.id,
        agentPercetange: unity.agentPercetange
      }
      dispatch(updateUnitAsync({ data: val, propertyId }!));
    }
    else if (action === "delete") {
      let unitId = unity.id as string;
      dispatch(deleteUnitAsync({ propertyId, unitId }));
    }
  };

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="container mx-auto h-full">
            <div className="card adaptable-card h-full">
              <div className="card-body h-full">
                <div
                  id="form-section"
                  className="grid lg:grid-cols-5 xl:grid-cols-3 2xl:grid-cols-5 gap-4 h-full"
                >
                  {loading ? (
                    <div className="align-vertically-middle lg:flex items-center justify-center">
                      <Spinners sizeFromProps={50} />
                    </div>
                  ) : showError ? (
                    <Alerts
                      type={"alert-danger"}
                      message={
                        "Erreur lors de cette opération. Veuillez reessayer!!!"
                      }
                    />
                  ) : (
                    <>
                      <div className="2xl:col-span-1 xl:col-span-1 lg:col-span-2">
                        <PropertyEditNav activeNav={activeNav} />
                      </div>
                      <div className="2xl:col-span-4 lg:col-span-3 xl:col-span-2">
                        <BasicInformationSection
                          property={property!}
                          saving={saving}
                          landLords={landlords}
                          activeNav={activeNav}
                          handleChange={handleChange}
                          submit={submit}
                        />
                        <UnitySection
                          activeNav={activeNav}
                          addOrUpdateUnity={addOrUpdateUnity}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div
                  id="complete-section"
                  className="text-center h-full flex-col justify-center hidden"
                >
                  <img
                    src="img/others/pending-approval.png"
                    alt=""
                    className="mb-6 mx-auto"
                  />
                  <h4 className="mb-4">Account application proccessing</h4>
                  <p>
                    Your account application is currently under review &amp;
                    will be finalize shortly.
                    <br />
                    To fast track your account proccessing, you can try to
                    contact our customer services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PropertyEdit;
