import React, { useEffect, useMemo, useState } from "react";
import Alerts from "../../../../helpers/Alerts";
import Spinners from "../../../../helpers/Spinners";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  selectUserStatus,
  selectUserList,
  selectSelectedUser,
  getUsersAsync,
  setSelectedUser,
  updateUserAsync,
  deleteUserAsync,
  makeDepositAsync,
  selectUserChartData,
  getUserChartDataAsync,
  selectAccountOperationsStatus,
  getUserAccountOperationDataAsync,
  selectUserAccountOperations,
} from "../userSlice";
import UserDialog from "./userDialog";
import { getRolesAsync, selectRoleList } from "../../role/roleSlice";
import UserDepositDialog from "./UserDepositDialog";
import PaymentSummary from "../../../commonComponent/PaymentSummary";
import AccountSummary from "../../../commonComponent/AccountSummary";
import {
  getLandlordsAsync,
  selectLandlordList,
} from "../../../landlords/landlordSlice";
import {
  getPropertiesAsync,
  selectPropertyList,
} from "../../../properties/propertySlice";
import ReactApexChart from "react-apexcharts";
import { search } from "../../../../helpers/objectMapper";
import DataTable from "react-data-table-component";

export function UserDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectUserStatus);
  const accountOperationsStatus = useAppSelector(selectAccountOperationsStatus);
  const users = useAppSelector(selectUserList);
  const selectedUser = useAppSelector(selectSelectedUser);
  const userChartData = useAppSelector(selectUserChartData);
  const userAccountOperations = useAppSelector(selectUserAccountOperations);
  const [OperationfilterText, setOperationFilterText] = useState<string>("");
  const roles = useAppSelector(selectRoleList);
  const landlords = useAppSelector(selectLandlordList);
  const properties = useAppSelector(selectPropertyList);
  const [user, setUser] = useState<IUser>();
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<ICreateOrUpdateUserData>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [deposit, setDeposit] = useState<IMakeDeposit>();
  const [actionType, setActionType] = useState<string>("");
  const [title, setTitle] = useState("");
  const [chartOptions, setChartOptions] = useState<any>({ series: [] });
  const [accountOperationLoading, setAccountOperationLoading] =
    useState<boolean>(true);
  const [filteredAccountOperations, setFilteredAccountOperations] =
    useState<IAccountOperation[]>();

  useEffect(() => {
    if (roles && roles.length === 0) {
      dispatch(getRolesAsync());
    }
    if (selectedUser) {
      setUser(selectedUser);
      setLoading(false);
    } else if (selectedUser == null) {
      dispatch(getUsersAsync());
    }
    if (userChartData == undefined) {
      dispatch(getUserChartDataAsync(id!));
    }
  }, []);

  useEffect(() => {
    setFilteredAccountOperations(userAccountOperations);
  }, [userAccountOperations]);

  useEffect(() => {
    if (users.length > 0) {
      let value = users.find((l) => l.id == id);
      setUser(value);
      dispatch(setSelectedUser(value!));

      //FIXME: Pour une raison etrange setSelectedUser ne s'appelle pas
      //Donc le code ci-dessous a ete duplique. A fixer
      dispatch(getUserAccountOperationDataAsync(id!));
    }
    if (landlords && landlords.length === 0) {
      dispatch(getLandlordsAsync());
    }
    if (properties && properties.length === 0) {
      dispatch(getPropertiesAsync());
    }
  }, [users, landlords, properties]);

  useEffect(() => {
    if (selectedUser) {
      // dispatch(getLandlordChartDataAsync(id!));
      dispatch(getUserAccountOperationDataAsync(id!));
    }
  }, [selectedUser]);

  useEffect(() => {
    if (status === "success") {
      setLoading(false);
    }
    if (status === "failed" && loading === true) {
      setShowError(true);
      setLoading(false);
    }
    if (deleting && status === "success") {
      navigate("/users/list");
    }
    if (accountOperationsStatus === "success") {
      setAccountOperationLoading(false);
    }
  }, [status, accountOperationsStatus]);

  useEffect(() => {
    if (userChartData && chartLoading) {
      setChartOptions({
        series: userChartData,
        chart: {
          type: "bar",
          height: 425,
        } as ApexChart,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Avr",
            "Mai",
            "Juin",
            "Jul",
            "Aout",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          title: {
            text: "FCFA",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val + " FCFA";
            },
          },
        },
      });
      setChartLoading(false);
    }
  }, [userChartData]);

  const handleChange = (objectKey: string, value: any) => {
    setDeposit((prevState: any) => {
      return { ...prevState, [objectKey]: value };
    });
  };

  const openDialog = (actionType: string) => {
    if (actionType === "add") {
      setActionType(actionType);
      setTitle("Ajouter un nouvel utilisateur");
    } else if (actionType === "edit") {
      setEditUser((prevState: any) => {
        return {
          ...prevState,
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          password: user?.password,
          address: user?.address,
          phoneNumber1: user?.phoneNumber1,
          phoneNumber2: user?.phoneNumber2,
          roles: user?.roles?.map((r) => r.id),
        };
      });
      setActionType(actionType);
      setTitle("Modifier un utilisateur");
    } else if (actionType === "delete") {
      setEditUser((prevState: any) => {
        return {
          ...prevState,
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          password: user?.password,
          address: user?.address,
          phoneNumber1: user?.phoneNumber1,
          phoneNumber2: user?.phoneNumber2,
          roles: user?.roles?.map((r) => r.id),
        };
      });
      setActionType(actionType);
      setTitle("Supprimer un utilisateur");
    }
    setShowDialog(true);
  };

  const handleMakeDeposit = (deposit: IMakeDeposit) => {
    dispatch(makeDepositAsync(deposit!));
  };

  const initializePayment = () => {
    setDeposit({
      amount: 0,
      comments: "",
      paymentMethod: "",
      propertyId: "",
      landlordId: "",
    });
  };

  const handleEditUser = (userData: ICreateOrUpdateUserData) => {
    dispatch(updateUserAsync(userData!));
  };

  const handleDeleteUser = (userId: string) => {
    dispatch(deleteUserAsync(userId));
  };

  const getOperationTypeDescription = (type: any) => {
    return type === 0 ? "Crédit" : "Débit";
  };

  const expensesSum = (): number => {
    let result = userAccountOperations
      ?.filter((op) => op.type === 1)
      ?.map((acc) => acc.amount);
    if (result && result.length > 0) {
      return result?.reduce((a, b) => {
        return a! + b!;
      })!;
    }
    return 0;
  };

  const incomesSum = (): number => {
    let result = userAccountOperations
      ?.filter((op) => op.type === 0)
      ?.map((acc) => acc.amount);
    if (result && result.length > 0) {
      return result?.reduce((a, b) => {
        return a! + b!;
      })!;
    }
    return 0;
  };

  const getPaymentMethodDescription = (method: string) => {
    switch (method) {
      case "Cash":
        return "Comptant";
      case "OrangeMoney":
        return "Orange Money";
      case "MoovMoney":
        return "Moov Money";
      case "Wave":
        return "Wave";
      case "SamaMoney":
        return "Sama Money";
      case "Cheque":
        return "Chèque";
      default:
        return "";
    }
  };

  const operationColumns = useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) =>
          new Date(row.date).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        sortable: true,
        width: "150px",
      },
      {
        name: "Mode de paiement",
        selector: (row: any) => row.paymentMethod,
        sortable: true,
        width: "180px",
      },
      {
        name: "Type",
        selector: (row: any) => getOperationTypeDescription(row.type),
        sortable: true,
        width: "140px",
      },
      {
        name: "Montant",
        selector: (row: any) =>
          new Intl.NumberFormat("fr-FR").format(row.amount),
        sortable: true,
        width: "120px",
      },
      {
        name: "Commentaires",
        selector: (row: any) => row.comments,
        sortable: true,
        width: "480px",
      },
    ],
    []
  );

  const OperationDynamicSearch = (terme: any) => {
    setOperationFilterText(terme);
    let result = search(
      userAccountOperations!,
      String(terme).toLocaleLowerCase()
    );
    setFilteredAccountOperations(result as IAccountOperation[]);
    console.log("result", result);
  };

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="flex flex-col gap-4 h-full">
            <div className="lg:flex items-center justify-between mb-4 gap-3">
              <div className="mb-4 lg:mb-0">
                <h3>
                  Détail de l'agent - {user?.firstName} {user?.lastName}
                </h3>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <PaymentSummary
                payment={{ amount: expensesSum(), title: "Total debits" }}
              />
              <PaymentSummary
                payment={{ amount: incomesSum(), title: "Total crédits" }}
              />
              <PaymentSummary
                payment={{
                  amount: incomesSum() - expensesSum(),
                  title: "Solde",
                }}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <AccountSummary
                person={user}
                propertyName={"users"}
                deleteTarget={"deleteUser"}
                openDialog={openDialog}
              />
              <div className="card card-layout-frame col-span-2">
                <div className="card-body">
                  <div className="flex items-center justify-between">
                    <h4>Rapport des opérations</h4>
                    {/* <button className="btn btn-sm btn-default">Export Report</button> */}
                    <div>
                      <select className="input input-md">
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <ReactApexChart
                      options={chartOptions}
                      series={chartOptions?.series!}
                      type="bar"
                      height={425}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div className="card card-layout-frame col-span-2">
                <div className="card-body">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                      <h4>Liste des opérations</h4>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:items-center justify-end gap-3">
                      <input
                        id="samll-date-query-input"
                        className="input input-sm pr-8"
                        placeholder="Rechercher..."
                        value={OperationfilterText}
                        onChange={(e: any) =>
                          OperationDynamicSearch(e.target.value)
                        }
                      />

                      <button
                        className="btn btn-two-tune btn-sm"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#addPayment"
                      >
                        <span className="flex flex-col lg:flex-row lg:items-center justify-end gap-3">
                          <span className="text-lg">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                          </span>
                          <span>Faire un versement</span>
                        </span>
                      </button>
                    </div>
                  </div>
                  <br />

                  <div className="overflow-x-auto">
                    {loading ? (
                      <>
                        <div className="lg:flex items-center justify-center">
                          <Spinners sizeFromProps={50} />
                        </div>
                      </>
                    ) : showError ? (
                      <Alerts
                        type={"alert-danger"}
                        message={
                          "Erreur lors de cette opération. Veuillez reessayer!!!"
                        }
                      />
                    ) : (
                      <>
                        {/* Section de filtre */}
                        <div className="lg:flex items-center justify-end mb-4 gap-3"></div>
                        <div className="overflow-x-auto">
                          <DataTable
                            columns={operationColumns}
                            data={filteredAccountOperations!}
                            striped={true}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserDialog
          title={title}
          actionType={actionType}
          roleList={roles}
          dialogId="editUser"
          showDialog={showDialog}
          userToEdit={editUser}
          handleEditUser={handleEditUser}
        />
        <UserDialog
          title={title}
          actionType={actionType}
          dialogId="deleteUser"
          showDialog={showDialog}
          userToEdit={editUser}
          handleDeleteUser={handleDeleteUser}
        />
        <UserDepositDialog
          user={user!}
          agencyId={user?.id}
          deposit={deposit}
          landlords={landlords}
          properties={properties}
          dialogId={"addPayment"}
          title={"Faire un versement à l'agent"}
          handleChange={handleChange}
          initializePayment={initializePayment}
          handleMakeDeposit={handleMakeDeposit}
        />
      </main>
    </>
  );
}
