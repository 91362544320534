import React, { createRef, useEffect, useState } from "react";
import UnityDialog from "./UnityDialog";
import { useAppSelector } from "../../../app/hooks";
import { selectSelectedProperty } from "../propertySlice";

interface UnitySectionProps {
  activeNav?: (itemId: string, sectionId: string) => void;
  addOrUpdateUnity: (propertyId: string, unity: IUnity, action: string) => void;
}

const UnitySection: React.FC<UnitySectionProps> = (props) => {
  const { addOrUpdateUnity } = props;

  const selectedProperty = useAppSelector(selectSelectedProperty);

  const [unity, setUnity] = useState<IUnity>();
  const [title, setTitle] = useState<string>();
  const [actionType, setActionType] = useState<string>();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const openDialog = (action: string) => {
    if (action === 'add') {
      setActionType('add')
      setTitle('Ajouter une nouvelle unité');
    }
    else if (action === 'update') {
      setTitle('Modifier une unité');
      setActionType('update')
    }
    else if (action === 'delete') {
      setTitle('Supprimer une unité');
      setActionType('delete')
    }
  }

  const handleAddOrUpdateUnity = () => {
    addOrUpdateUnity(selectedProperty?.id!, unity!, actionType!);
  };

  const handleChange = (objectKey: string, value: any) => {
    setUnity((prevState: any) => {
      return { ...prevState, [objectKey]: value };
    });
  };

  const initializeUnity = () => {
    setUnity({
      name: '',
      rentAmount: 0,
      assignedAgent: undefined,
      agentPercetange: 0,
      agentId: ''
    } as IUnity);
  }


  return (
    <>
      <div id="unity-section" className="content-section hidden">
        <div className="mb-8">
          <h3 className="mb-2">Unités</h3>
          <p>Configurez les unités de ce bien immobilier</p>
        </div>
        <div className="rounded-lg border border-gray-200 dark:border-gray-600">
          {selectedProperty?.units?.map((unity, index) => (
            <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-3 p-4 border-b border-gray-200 dark:border-gray-600">
              <div className="flex items-center gap-3">
                <div>
                  <div className="flex items-center">
                    <div className="text-gray-900 dark:text-gray-100 font-semibold">
                      Unité {index + 1}: {unity?.name} - Agent en charge:{" "}
                      {unity.assignedAgent?.firstName}{" "}
                      {unity.assignedAgent?.lastName}
                    </div>
                    <div>
                      {unity.isOccupied ? 
                      (<span className="capitalize"><span className="tag bg-sky-100 text-sky-600 dark:bg-sky-500/20 dark:text-sky-100 rounded-md border-0 mx-2">Occupé</span></span>) 
                      : (<span className="capitalize"> <span className="tag bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 rounded-md border-0 mx-2"> Disponible </span></span>)} 
                    </div>
                  </div>
                  <span>Montant du loyer: {unity.rentAmount} CFA</span>
                </div>
              </div>
              <div className="flex">
                <button
                  className="btn btn-plain btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#unit-dialog"
                  onClick={() => {
                    setUnity({ ...unity, agentId: unity.assignedAgent?.id });
                    openDialog("delete")
                  }}
                >
                  Delete
                </button>
                <button
                  className="btn btn-default btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#unit-dialog"
                  onClick={() => {
                    setUnity({ ...unity, agentId: unity.assignedAgent?.id, agentPercetange: unity.agentPercetange });
                    openDialog("update");
                  }}
                >
                  <span className="flex items-center justify-center">
                    <span className="ltr:ml-1 rtl:mr-1">Modifier</span>
                  </span>
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6">
          <button
            className="btn btn-plain"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#unit-dialog"
            onClick={() => openDialog("add")}
          >
            <span className="flex items-center justify-center gap-2">
              <span className="text-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m6-6H6"
                  ></path>
                </svg>
              </span>
              <span>Ajouter une unité</span>
            </span>
          </button>
        </div>
      </div>
      <UnityDialog
        unity={unity}
        actionType={actionType!}
        title={title!}
        dialogId="unit-dialog"
        handleChange={handleChange}
        initializeUnity={initializeUnity}
        handleAddOrUpdateUnity={handleAddOrUpdateUnity}
      />
    </>
  );
};

export default UnitySection;
