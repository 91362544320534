import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectIsLogged, signinAsync, selectUserStatus } from "./userSlice";

export function SigninPage() {
  const dispatch = useAppDispatch();
  const logged = useAppSelector(selectIsLogged);
  const actionStatus = useAppSelector(selectUserStatus);

  const [showPassWord, setShowPassWord] = useState<boolean>(false);
  const [signinData, setSigninData] = useState<ISigninData>({ email: "", password: "" });

  useEffect(() => {
    console.log("logged", logged);

    if (logged) {
      window.location.href = "/";
    }
  }, [logged]);

  const submit = () => {
    dispatch(signinAsync(signinData));
  };

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col">
          <div className="h-full">
            <div className="container mx-auto flex flex-col flex-auto items-center justify-center min-w-0 h-full">
              <div className="card min-w-[320px] md:min-w-[450px] card-shadow" role="presentation">
                <div className="card-body md:p-10">
                  <div className="text-center">
                    <div className="logo">
                      <img className="mx-auto signin-logo" src="/assets/images/logo-signin.png" alt="JIKKE Immobolière" />
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="mb-4 mt-4">
                      <p>Veuillez saisis vos informations pour vous connecter</p>
                    </div>
                    <div>
                      <form>
                        <div className="form-container vertical">
                          <div className="form-item vertical">
                            <label className="form-label mb-2">Email</label>
                            <div>
                              <input
                                className="input"
                                type="text"
                                name="userName"
                                autoComplete="off"
                                placeholder="Email"
                                value={signinData.email}
                                onChange={(event) =>
                                  setSigninData((prevState: ISigninData) => {
                                    return { ...prevState, email: event.target.value };
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="form-item vertical">
                            <label className="form-label mb-2">Mot de passe</label>
                            <div>
                              <span className="input-wrapper">
                                <input
                                  className="input pr-8"
                                  type={showPassWord ? "text" : "password"}
                                  name="password"
                                  autoComplete="off"
                                  placeholder="Mot de passe"
                                  value={signinData.password}
                                  onChange={(event) =>
                                    setSigninData((prevState: ISigninData) => {
                                      return { ...prevState, password: event.target.value };
                                    })
                                  }
                                />
                                <div className="input-suffix-end">
                                  <span className="cursor-pointer text-xl">
                                    {showPassWord ? (
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={(event) =>
                                          setShowPassWord((prevState: boolean) => {
                                            return !prevState;
                                          })
                                        }
                                      >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path>
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        width="1em"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                        strokeWidth="2"
                                        onClick={(event) =>
                                          setShowPassWord((prevState: boolean) => {
                                            return !prevState;
                                          })
                                        }
                                      >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                      </svg>
                                    )}
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-between mb-6">
                            <label className="checkbox-label mb-0">
                              <input className="checkbox" type="checkbox" value="true" checked />
                              <span className="ltr:ml-2 rtl:mr-2">Gardez ma session ouverte</span>
                            </label>
                            <a className="text-primary-600 hover:underline" href="forgot-password-simple.html">
                              Mot de passe oublié?
                            </a>
                          </div>
                          <button className="btn btn-solid w-full" onClick={submit} disabled={actionStatus === "pending"}>
                            <span className="flex items-center justify-center">
                              Connexion
                              {actionStatus === "pending" && (
                                <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" className="animate-spin mr-1 w-5 h-5 h-[30px] w-[30px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor"></path>
                                  <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="currentColor"></path>
                                </svg>
                              )}
                            </span>
                          </button>
                          {actionStatus === "failed" && (
                            <div className="alert alert-danger mt-2">
                              <div className="alert-content">
                                <span className="alert-icon">
                                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"></path>
                                  </svg>
                                </span>
                                <div>L'email et/ou le mot de passe incorrecte.</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
