import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Alerts from "../../helpers/Alerts";
import Spinners from "../../helpers/Spinners";
import { selectAccountList, selectAccountStatus } from "./accountOperationSlice";
import { displayAgentAccountOperationAsync } from "./accountOperationSlice";
import { getUsersAsync, selectUserList, selectAuthUser } from "../account/user/userSlice";
import { search } from "../../helpers/objectMapper";
import DataTable from "react-data-table-component";

export function AgentAccountOperationPage() {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccountList);
  const users = useAppSelector(selectUserList);
  const authUser = useAppSelector(selectAuthUser);
  const status = useAppSelector(selectAccountStatus);
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [isAgent, setIsAgent] = useState<boolean>(false);
  const [searchCriteria, setSearchCriteria] = useState<IAgentAccountOperation>({
    startDate: "",
    endDate: "",
    agentId: "",
  });
  const [filteredAccounts, setFilteredAccounts] = useState<IAccountOperation[]>();
  const [filterText, setFilterText] = useState<string>("");

  useEffect(() => {
    if (users && users.length === 0) {
      dispatch(getUsersAsync());
    }
    if (authUser && authUser.roles && authUser.roles.length === 1 && authUser.roles[0].name === "Agent") {
      handleChange("agentId", authUser.id);
      setIsAgent(true);
    }
  }, []);

  useEffect(() => {
    setFilteredAccounts(accounts);
  }, [accounts]);

  useEffect(() => {
    setLoading(status === "pending");
    setShowError(status === "failed");
  }, [status]);

  const getOperationTypeDescription = (type: any) => {
    return type === 0 ? "Crédit" : "Débit";
  };

  const handleChange = (objectKey: string, value: any) => {
    setSearchCriteria((prevState: any) => {
      return { ...prevState, [objectKey]: value };
    });
  };

  const submit = () => {
    dispatch(displayAgentAccountOperationAsync(searchCriteria!));
  };

  const validateForm = (): boolean => {
    return !searchCriteria || searchCriteria.endDate === undefined || searchCriteria.endDate === "" || searchCriteria.startDate === undefined || searchCriteria.agentId === undefined || searchCriteria.agentId === "";
  };

  const columns = useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) =>
          new Date(row.date).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        sortable: true,
        width: "150px",
      },
      {
        name: "Mode de paiement",
        selector: (row: any) => row.paymentMethod,
        sortable: true,
        width: "220px",
      },
      {
        name: "Type",
        selector: (row: any) => getOperationTypeDescription(row.type),
        sortable: true,
        width: "140px",
      },
      {
        name: "Montant",
        selector: (row: any) => new Intl.NumberFormat("fr-FR").format(row.amount),
        sortable: true,
        width: "120px",
      },
      {
        name: "Commentaires",
        selector: (row: any) => row.comments,
        sortable: true,
        width: "500px",
      },
    ],
    []
  );

  const dynamicSearch = (terme: any) => {
    setFilterText(terme);
    let result = search(accounts!, String(terme).toLocaleLowerCase());
    setFilteredAccounts(result as ITenant[]);
    console.log("result", result);
  };

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="container mx-auto">
            <div className="card adaptable-card">
              <div className="card-body">
                <div className="lg:flex items-center justify-between mb-4">
                  <h3 className="mb-4 lg:mb-0">Bilan par agent</h3>
                </div>
                <div className="my-auto">
                  <form>
                    <div className="grid grid-rows-1 grid-flow-col gap-4">
                      <div className="form-item vertical">
                        <label className="form-label mr-2">Date de debut *</label>
                        <div>
                          <input className="input" type="date" name="startDate" value={searchCriteria?.startDate} onChange={(e) => handleChange("startDate", e.target.value)} />
                        </div>
                      </div>
                      <div className="form-item vertical">
                        <label className="form-label mr-2">Date de fin *</label>
                        <div>
                          <input className="input" type="date" name="endDate" value={searchCriteria?.endDate} onChange={(e) => handleChange("endDate", e.target.value)} />
                        </div>
                      </div>
                      {!isAgent && (
                        <div className="form-item vertical">
                          <label className="form-label mr-2">Nom du l'agent *</label>
                          <div>
                            <select className="input" name="agentId" value={searchCriteria?.agentId!} onChange={(e) => handleChange("agentId", e.target.value)} disabled={isAgent}>
                              <option value="" label="Choisissez une option"></option>
                              {users
                                ?.filter((user) => user.roles?.some((role) => role.name === "Agent"))
                                .map((agent, index) => (
                                  <option key={agent.id} value={agent.id} label={`${agent.firstName} ${agent.lastName}`} />
                                ))}
                            </select>
                          </div>
                        </div>
                      )}

                      <div className="flex justify-center gap-4 mt-8">
                        <button className="btn btn-two-tune btn-sm" type="button" disabled={loading || status === "pending" || validateForm()} onClick={submit}>
                          <span className="flex items-center justify-center">
                            <span className="text-lg">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
                              </svg>
                            </span>
                            <span>Lancer</span>
                          </span>
                        </button>
                        <span className="cursor-pointer p-2 hover:text-indigo-600">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </form>
                  {/* Section de filtre */}
                  <div className="lg:flex items-center justify-start mb-4 gap-3">
                    <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                      <input id="date-query-input" className="input input-sm pr-8" placeholder="Rechercher..." value={filterText} onChange={(e: any) => dynamicSearch(e.target.value)} />
                    </div>
                  </div>
                </div>

                <>
                  <div className="overflow-x-auto">
                    {loading ? (
                      <div className="lg:flex items-center justify-center">
                        <Spinners sizeFromProps={50} />
                      </div>
                    ) : showError ? (
                      <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
                    ) : (
                      <>
                        <div className="overflow-x-auto">
                          <DataTable columns={columns} data={filteredAccounts!} striped={true} />
                        </div>
                      </>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
