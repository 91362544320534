import React, { useEffect, useState } from 'react';
import AddressInformationSection from './AddressInformationSection';
import IdentificationSection from './IdentificationSection';
import PersonalInformationSection from './PersonalInformationSection';
import TenantEditNav from './TenantEditNav';
import { createTenantAsync, getTenantsAsync, selectSelectedTenant, selectTenantList, selectTenantStatus, setSelectedTenant, updateTenantAsync } from '../tenantSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Alerts from '../../../helpers/Alerts';
import Spinners from '../../../helpers/Spinners';

interface TenantEditProps {
    TenantToEdit?: ITenant;
}

const TenantEdit: React.FC<TenantEditProps> = (props) => {

    const { id } = useParams();
    const status = useAppSelector(selectTenantStatus);
    const tenants = useAppSelector(selectTenantList);
    const [tenant, setTenant] = useState<ITenant>();
    const selectedTenant = useAppSelector(selectSelectedTenant);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();


    const [actionType, setActionType] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        if (selectedTenant) {
            setTenant(selectedTenant);
            setLoading(false);
            invalidForm();
        }
        else if (selectedTenant == null) {
            dispatch(getTenantsAsync());
        }
    }, []);

    useEffect(() => {
        if (location.pathname.includes('edit')) {
            setTenant(selectedTenant!);
            setActionType('edit');
            invalidForm();
        }
        else if (location.pathname.includes('create')) {
            setActionType('create');
        }
    }, [location]);

    useEffect(() => {
        if (!selectedTenant && tenants.length > 0) {
            let value = tenants.find((l) => l.id == id);
            setTenant(value);
            dispatch(setSelectedTenant(value!));
        }
    }, [tenants]);

    useEffect(() => {
        if (status === 'success') {
            setLoading(false);
        }
        if (status === 'failed' && loading === true) {
            setShowError(true);
            setLoading(false);
        }
        if (saving && status === 'success') {
            setSaving(false);
            navigate('/tenants/list')
        }
    }, [status]);

    const activeNav = (itemId: string, sectionId: string) => {

        /***** Hide other sections and show selected *****/
        hidSections();
        let selectedSection = document.getElementById(sectionId);
        selectedSection?.classList.remove('hidden');

        /***** Disable other nav and active selected *****/
        disableNavs();
        let selectedNav = document.getElementById(itemId);
        selectedNav?.classList.add('menu-item-active')
    }

    const disableNavs = () => {
        let navs = document.getElementsByClassName('section-menu-item');
        for (let index = 0; index < navs.length; index++) {
            const element = navs[index];
            element.classList.remove('menu-item-active');
        }
    }

    const hidSections = () => {
        let sections = document.getElementsByClassName('content-section')
        for (let index = 0; index < sections.length; index++) {
            const element = sections[index];
            element.classList.add('hidden');
        }
    }

    const handleChange = (objectKey: string, value: any) => {
        setTenant((prevState) => {
            return { ...prevState, [objectKey]: value }
        })
    }

    const submit = () => {
        setSaving(true);
        if (actionType === 'create') {
            dispatch(createTenantAsync(tenant!));
        }
        else if (actionType === 'edit') {
            dispatch(updateTenantAsync(tenant!));
        }
    };

    const invalidForm = (): boolean => {
        return !tenant ||
            tenant.firstName === "" ||
            tenant.firstName === null ||
            tenant.lastName === "" ||
            tenant.lastName === null ||
            tenant.phoneNumber1 === "" ||
            tenant.phoneNumber1 === null ||
            tenant.phoneNumber1 === undefined
    }


    return (
        <>
            <main className="h-full">
                <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
                    <div className="container mx-auto h-full">
                        <div className="card adaptable-card h-full">
                            <div className="card-body h-full">
                                <div id="form-section" className="grid lg:grid-cols-5 xl:grid-cols-3 2xl:grid-cols-5 gap-4 h-full">
                                    {loading ?
                                        (
                                            <div className="align-vertically-middle lg:flex items-center justify-center">
                                                <Spinners sizeFromProps={50} />
                                            </div>
                                        ) : showError ?
                                            (
                                                <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
                                            ) :
                                            (
                                                <>
                                                    <div className="2xl:col-span-1 xl:col-span-1 lg:col-span-2">
                                                        <TenantEditNav activeNav={activeNav} />
                                                    </div>
                                                    <div className="2xl:col-span-4 lg:col-span-3 xl:col-span-2">
                                                        <PersonalInformationSection
                                                            tenant={tenant!}
                                                            activeNav={activeNav}
                                                            handleChange={handleChange}
                                                        />
                                                        <IdentificationSection
                                                            tenant={tenant!}
                                                            activeNav={activeNav}
                                                            handleChange={handleChange}
                                                        />
                                                        <AddressInformationSection
                                                            tenant={tenant!}
                                                            saving={saving}
                                                            activeNav={activeNav}
                                                            handleChange={handleChange}
                                                            submit={submit}
                                                            invalidForm={invalidForm}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                </div>
                                <div id="complete-section" className="text-center h-full flex-col justify-center hidden">
                                    <img src="img/others/pending-approval.png" alt="" className="mb-6 mx-auto" />
                                    <h4 className="mb-4">Account application proccessing</h4>
                                    <p>Your account application is currently under review &amp; will be finalize shortly.
                                        <br />To fast track your account proccessing, you can try to contact our customer services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default TenantEdit;