import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../../app/store";
import { createRole, getRoles } from "./roleAPI";
import { sortedArray } from "../../../helpers/objectMapper";

export interface RoleState {
  roleList: IRole[];
  status: "pending" | "success" | "failed" | "";
}

const initialState: RoleState = {
  roleList: [],
  status: "",
};

export const getRolesAsync = createAsyncThunk("role/getRoles", async () => {
  const response = await getRoles();
  return response.data;
});

export const createRoleAsync = createAsyncThunk(
  "role/create",
  async (data: any) => {
    const response = await createRole(data);
    return response.data;
  }
);

export const userSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRolesAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getRolesAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.roleList = sortedArray(action.payload) as IRole[];
      })
      .addCase(getRolesAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createRoleAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createRoleAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.roleList = action.payload;
      })
      .addCase(createRoleAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// export const { signout } = userSlice.actions;

export const selectRoleList = (state: RootState) => state.role.roleList;
export const selectRoleStatus = (state: RootState) => state.role.status;

export default userSlice.reducer;
