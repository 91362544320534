import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuthUser, signout } from '../../features/account/user/userSlice';


export function Header() {

    const dispatch = useAppDispatch();
    const authUser = useAppSelector(selectAuthUser);

    const navigate = useNavigate();

    const handleSignin = () => {
        navigate('/users/signin');
    }

    const signOut_ = () => {
        localStorage.removeItem('user');
        dispatch(signout());
    }

    return (
        <>
            <header className="header border-b border-gray-200 dark:border-gray-700">
                <div className="header-wrapper h-16">
                    <div className="header-action header-action-start">
                        <div id="side-nav-toggle" className="side-nav-toggle header-action-item header-action-item-hoverable">
                            <div className="text-2xl">
                                <svg className="side-nav-toggle-icon-expand" stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7"></path>
                                </svg>
                                <svg className="side-nav-toggle-icon-collapsed hidden" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="side-nav-toggle-mobile header-action-item header-action-item-hoverable" data-bs-toggle="modal" data-bs-target="#mobile-nav-drawer">
                            <div className="text-2xl">
                                <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="header-action header-action-end">
                        <div className="text-2xl header-action-item header-action-item-hoverable" data-bs-toggle="modal" data-bs-target="#nav-config">
                            <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            </svg>
                        </div>
                        <div className="dropdown">
                            <div className="dropdown-toggle" id="user-dropdown" data-bs-toggle="dropdown">
                                <div className="header-action-item flex items-center gap-2">
                                    <span className="avatar avatar-circle" data-avatar-size="32">
                                        <span className="avatar-icon avatar-icon-md">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                                            </svg>
                                        </span>
                                    </span>
                                    <div className="hidden md:block">
                                        {authUser && (
                                            <>
                                                <div className="text-xs capitalize">{authUser?.roles![0].name}</div>
                                                <div className="font-bold">{`${authUser.firstName} ${authUser.lastName}`}</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <ul className="dropdown-menu bottom-end min-w-[240px]">
                                {authUser && (
                                    <>
                                        <li className="menu-item-header">
                                            <div className="py-2 px-3 flex items-center gap-2">
                                                <span className="avatar avatar-circle avatar-md">
                                                    <span className="avatar-icon avatar-icon-md">
                                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                                                        </svg>
                                                    </span>
                                                </span>
                                                <div>
                                                    <div className="font-bold text-gray-900 dark:text-gray-100">{`${authUser.firstName} ${authUser.lastName}`}</div>
                                                    <div className="text-xs">{authUser.email}</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="menu-item-divider"></li>
                                    </>
                                )}
                                <li className="menu-item menu-item-hoverable gap-2 h-[35px]">
                                    <span className="text-xl opacity-50">
                                        <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                                        </svg>
                                    </span>
                                    {authUser ?
                                        <span onClick={signOut_} >Se déconnecter</span> :
                                        <span onClick={handleSignin}>Se connecter</span>
                                    }

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};