import { rejects } from "assert";
import axios from "axios";
import { api } from "../../services/apiHelpers";

const axiosHeadersConfig = () => {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  if (user.accessToken) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + user.accessToken;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export function getLeases() {
  axiosHeadersConfig();
  return new Promise<{ data: ILease[]; status: number }>((resolve, reject) => {
    axios
      .get(api + "/Leases/list")
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function createLease(data: ILease) {
  return new Promise<{ data: ILease; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Leases/create", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function terminateLease(data: ILease) {
  return new Promise<{ data: string; status: number }>((resolve) => {
    axios
      .post(api + `/Leases/terminate`, data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}
