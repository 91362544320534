import React from "react";

const Footer = () => {
    return (
        <>
            <footer className="h-16 px-4 sm:px-6 md:px-8">
                <div className="align-center">
                    <span>
                        Copyright © {new Date().getFullYear()} <span className="font-semibold">D3K</span> Tous droits réservés.
                    </span>
                </div>
            </footer>
        </>
    );
};

export default Footer;
