import React, { createRef, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Alerts from "../../../helpers/Alerts";
import { selectLandlordStatus, initializeActionStatus } from "../landlordSlice";

interface DepositViwerDialogProps {
  depositAccountOperations: IAccountOperation[];
}

const DepositViwerDialog: React.FC<DepositViwerDialogProps> = (props) => {
  const { depositAccountOperations } = props;

  const dispatch = useAppDispatch();
  const status = useAppSelector(selectLandlordStatus);
  const closeDialogRef = createRef<HTMLSpanElement>();

  useEffect(() => {
    if (status === "success") {
      closeDialogRef.current?.click();
    }
  }, [status]);

  const depositOperationColumns = useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) =>
          new Date(row.date).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        sortable: true,
        width: "150px",
      },
      {
        name: "Montant",
        selector: (row: any) => new Intl.NumberFormat("fr-FR").format(row.amount),
        sortable: true,
        width: "120px",
      },
      {
        name: "Commentaires",
        selector: (row: any) => row.comments,
        sortable: true,
        width: "550px",
      },
    ],
    []
  );

  return (
    <div className="modal fade" id="tenantleasesdialog" aria-hidden="true">
      <div className="modal-dialog dialog" style={{ maxWidth: "900px" }}>
        <div className="dialog-content">
          <span className="close-btn absolute z-10 ltr:right-6 rtl:left-6" role="button" data-bs-dismiss="modal">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
            </svg>
          </span>
          <div>
            <div className="mb-8">
              <h3 className="mb-2">Cautions du bailleur</h3>
            </div>
            <div>
              <DataTable columns={depositOperationColumns} data={depositAccountOperations!} striped={true} paginationPerPage={25} paginationRowsPerPageOptions={[25, 50, 100]} />
            </div>
          </div>
          <div className="text-right mt-6">
            <button className="btn btn-solid" data-bs-dismiss="modal">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositViwerDialog;
