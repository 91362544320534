import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import {
  displayAgentAccountOperation,
  displayLandlordAccountOperation,
  displayAccountJournalOperations,
  getAgencyAccountOperationsBySearch,
  addAgencyExpense,
  getAgencyAccountOperations,
  getAgencyChartData,
} from "./accountOperationAPI";
import { jwtDecode, JwtPayload } from "jwt-decode";
import {
  addNewItemToObjectArray,
  sortedArray,
} from "../../helpers/objectMapper";

export interface AccountState {
  accountList: IAccountOperation[];
  landlordAccountList: ILandlordAccountOperation[];
  status: "pending" | "success" | "failed" | "";
  agencyChartData?: IChartData;
}

const initialState: AccountState = {
  accountList: [],
  landlordAccountList: [],
  status: "",
  agencyChartData: undefined
};

export const getAgencyAccountOperationsAsync = createAsyncThunk(
  "account/getAgencyAccountOperations",
  async () => {
    const response = await getAgencyAccountOperations();
    return response.data;
  }
);

export const getAgencyChartDataAsync = createAsyncThunk(
  "account/getAgencyChartDataAsync",
  async () => {
    const response = await getAgencyChartData();
    return response.data;
  }
);

export const displayAgencyAccountOperationsAsync = createAsyncThunk(
  "account/getAgencyAccountOperationsBySearch",
  async (data: IAgencyAccountOperation) => {
    const response = await getAgencyAccountOperationsBySearch(data);
    return response.data;
  }
);

export const displayLandlordAccountOperationAsync = createAsyncThunk(
  "account/displayLandlordAccount",
  async (data: ILandlordAccountOperation) => {
    const response = await displayLandlordAccountOperation(data);
    return response.data;
  }
);

export const displayAgentAccountOperationAsync = createAsyncThunk(
  "account/displayAgentAccount",
  async (data: IAgentAccountOperation) => {
    const response = await displayAgentAccountOperation(data);
    return response.data;
  }
);

export const displayAccountJournalOperationsAsync = createAsyncThunk(
  "account/displayAccountJournalOperations",
  async (data: IAccountJournal) => {
    const response = await displayAccountJournalOperations(data);
    return response.data;
  }
);

export const addAgencyExpenseAsync = createAsyncThunk(
  "account/addAgencyExpense",
  async (data: IAddAgencyExpense) => {
    const response = await addAgencyExpense(data);
    return response.data;
  }
);


export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    initializeActionStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(displayAgencyAccountOperationsAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        displayAgencyAccountOperationsAsync.fulfilled,
        (state, action) => {
          state.status = "success";
          state.accountList = sortedArray(
            action.payload
          ) as IAccountOperation[];
        }
      )
      .addCase(displayAgencyAccountOperationsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAgencyAccountOperationsAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        getAgencyAccountOperationsAsync.fulfilled,
        (state, action) => {
          state.status = "success";
          state.accountList = sortedArray(
            action.payload
          ) as IAccountOperation[];
        }
      )
      .addCase(getAgencyChartDataAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getAgencyChartDataAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.agencyChartData = action.payload;
      })
      .addCase(getAgencyChartDataAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAgencyAccountOperationsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(displayLandlordAccountOperationAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        displayLandlordAccountOperationAsync.fulfilled,
        (state, action) => {
          state.accountList = [...action.payload];
          state.status = "success";
        }
      )
      .addCase(displayLandlordAccountOperationAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(displayAgentAccountOperationAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(displayAgentAccountOperationAsync.fulfilled, (state, action) => {
        state.accountList = [...action.payload];
        state.status = "success";
      })
      .addCase(displayAgentAccountOperationAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(displayAccountJournalOperationsAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        displayAccountJournalOperationsAsync.fulfilled,
        (state, action) => {
          state.accountList = [...action.payload];
          state.status = "success";
        }
      )
      .addCase(displayAccountJournalOperationsAsync.rejected, (state) => {
        state.status = "failed";
      }).addCase(addAgencyExpenseAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        addAgencyExpenseAsync.fulfilled,
        (state, action) => {
          state.status = "success";
          // state.accountList = sortedArray(
          //   action.payload
          // ) as IAccountOperation[];
        }
      )
      .addCase(addAgencyExpenseAsync.rejected, (state) => {
        state.status = "failed";
      })
  },
});

export const { initializeActionStatus } = accountSlice.actions;

export const selectAccountList = (state: RootState) =>
  state.account.accountList;
export const selectAccountStatus = (state: RootState) => state.account.status;
export const selectAgencyChartData = (state: RootState) => state.account.agencyChartData;

export default accountSlice.reducer;
