import { data } from "jquery";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Parser } from "html-to-react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Alerts from "../../helpers/Alerts";
import Spinners from "../../helpers/Spinners";
import { getHomeChartDataAsync, selectHomeData, selectHomeStatus } from "./homeSlice";

export function HomePage() {
  const dispatch = useAppDispatch();
  const homeData = useAppSelector(selectHomeData);
  const status = useAppSelector(selectHomeStatus);
  const [chartOptions, setChartOptions] = useState<any>({ series: [] });
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (homeData === undefined) {
      dispatch(getHomeChartDataAsync());
    } else if (loading) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (status === "success") {
      setLoading(false);
      setChartOptions({
        series: homeData?.leasesChart,
        chart: {
          type: "bar",
          height: 425,
        } as ApexChart,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Jul", "Aout", "Sep", "Oct", "Nov", "Dec"],
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val;
            },
          },
        },
      });
    }
    if (status === "failed" && loading === true) {
      setShowError(true);
      setLoading(false);
    }
  }, [status]);

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="flex flex-col gap-4">
            {loading ? (
              <div className="lg:flex items-center justify-center">
                <Spinners sizeFromProps={50} />
              </div>
            ) : showError ? (
              <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
            ) : (
              <>
                {/* Tiles */}
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
                  {homeData?.tiles?.map((data, index) => (
                    <div className="card card-layout-frame">
                      <div className="card-body">
                        <div className="flex items-center gap-4">
                          {Parser().parse(data.iconHtml)}

                          <div>
                            <div className="flex gap-1.5 items-end mb-2">
                              <h3 className="font-bold leading-none">{data.value}</h3>
                              <p className="font-semibold">{data.name}</p>
                            </div>
                            <p className="flex items-center gap-1">
                              <span className="flex items-center rounded-full gap-1">
                                <span className="rounded-full p-1 bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100">
                                  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path>
                                  </svg>
                                </span>
                                <span className="font-semibold text-emerald-600">+ 2.6</span>
                              </span>
                              <span>ce mois</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Chart section*/}
                <div className="grid grid-cols-1 xl:grid-cols-7 gap-4">
                  {/* Chart Data section*/}
                  <div className="card card-layout-frame xl:col-span-5">
                    <div className="card-body">
                      <div className="flex sm:flex-row flex-col md:items-center justify-between mb-6 gap-4">
                        <h4>Aperçu des contrats</h4>
                      </div>
                      <div>
                        <div>
                          <ReactApexChart options={chartOptions} series={chartOptions?.series!} type="bar" height={425} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Donut chart */}
                  <div className="card card-layout-frame xl:col-span-2">
                    <div className="card-body">
                      <h4>Unités occupées</h4>
                      <div className="mt-6" style={{ marginTop: "80px" }}>
                        <div className="progress flex justify-center circle">
                          <div className="progress-circle" style={{ width: "200px" }}>
                            <span className="progress-circle-info">
                              <span className="progress-info circle">
                                <div>
                                  <h3 className="font-bold">{homeData?.unitStats?.occupiedPercentage}%</h3>
                                  <p>Occupation</p>
                                </div>
                              </span>
                            </span>
                            <svg viewBox="0 0 100 100">
                              <path d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96" stroke-width="4" fill-opacity="0" className="progress-circle-trail" style={{ strokeDasharray: "301.593px, 301.593px", strokeDashoffset: "0px" }}></path>
                              <path d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96" stroke-linecap="round" stroke-width="4" fill-opacity="0" className="progress-circle-stroke text-indigo-600" style={{ strokeDasharray: "220.163px, 301.593px", strokeDashoffset: "0px" }}></path>
                            </svg>
                          </div>
                        </div>
                        <div className="text-center mt-6">
                          <p className="font-semibold">Performace</p>
                          <h4 className="font-bold">Moyenne</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
}
