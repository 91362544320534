import React, { useEffect, useState } from "react";
import AddressInformationSection from "./AddressInformationSection";
import IdentificationSection from "./IdentificationSection";
import PersonalInformationSection from "./PersonalInformationSection";
import LandlordEditNav from "./LandlordEditNav";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { createLandlordAsync, getLandlordsAsync, selectLandlordList, selectLandlordStatus, selectSelectedLandlord, setSelectedLandlord, updateLandlordAsync } from "../landlordSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinners from "../../../helpers/Spinners";
import Alerts from "../../../helpers/Alerts";

interface LandlordEditProps {
  landlordToEdit?: ILandlord;
}

const LandlordEdit: React.FC<LandlordEditProps> = (props) => {

  const { landlordToEdit } = props;

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectLandlordStatus);
  const landlords = useAppSelector(selectLandlordList);
  const selectedLandlord = useAppSelector(selectSelectedLandlord);
  const location = useLocation();
  const navigate = useNavigate();


  const [landlord, setLandlord] = useState<ILandlord>();
  const [actionType, setActionType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);


  useEffect(() => {
    if (selectedLandlord) {
      setLandlord(selectedLandlord);
      setLoading(false);
      invalidForm();
    }
    else if (selectedLandlord == null) {
      dispatch(getLandlordsAsync());
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      setLandlord(selectedLandlord!);
      setActionType('edit');
      invalidForm();
    }
    else if (location.pathname.includes('create')) {
      setActionType('create');
    }
  }, [location]);

  useEffect(() => {
    if (!selectedLandlord && landlords.length > 0) {
      let value = landlords.find((l) => l.id == id);
      setLandlord(value);
      dispatch(setSelectedLandlord(value!));
    }
  }, [landlords]);

  useEffect(() => {
    if (status === 'success') {
      setLoading(false);
    }
    if (status === 'failed' && loading === true) {
      setShowError(true);
      setLoading(false);
    }
    if (saving && status === 'success') {
      setSaving(false);
      navigate('/landlords/list')
    }
  }, [status])

  const activeNav = (itemId: string, sectionId: string) => {
    /***** Hide other sections and show selected *****/
    hidSections();
    let selectedSection = document.getElementById(sectionId);
    selectedSection?.classList.remove("hidden");

    /***** Disable other nav and active selected *****/
    disableNavs();
    let selectedNav = document.getElementById(itemId);
    selectedNav?.classList.add("menu-item-active");
  };

  const disableNavs = () => {
    let navs = document.getElementsByClassName("section-menu-item");
    for (let index = 0; index < navs.length; index++) {
      const element = navs[index];
      element.classList.remove("menu-item-active");
    }
  };

  const hidSections = () => {
    let sections = document.getElementsByClassName("content-section");
    for (let index = 0; index < sections.length; index++) {
      const element = sections[index];
      element.classList.add("hidden");
    }
  };

  const handleChange = (objectKey: string, value: any) => {
    setLandlord((prevState) => {
      return { ...prevState, [objectKey]: value };
    });
  };

  const submit = () => {
    setSaving(true);
    if (actionType === 'create') {
      dispatch(createLandlordAsync(landlord!));
    }
    else if (actionType === 'edit') {
      dispatch(updateLandlordAsync(landlord!));
    }

  };

  const invalidForm = (): boolean => {
    return !landlord ||
      landlord.firstName === "" ||
      landlord.firstName === null ||
      landlord.lastName === "" ||
      landlord.lastName === null ||
      landlord.phoneNumber1 === "" ||
      landlord.phoneNumber1 === null ||
      landlord.phoneNumber1 === undefined
  }

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="container mx-auto h-full">
            <div className="card adaptable-card h-full">
              <div className="card-body h-full">
                <div
                  id="form-section"
                  className="grid lg:grid-cols-5 xl:grid-cols-3 2xl:grid-cols-5 gap-4 h-full"
                >
                  {loading ? (
                    <div className="align-vertically-middle lg:flex items-center justify-center">
                      <Spinners sizeFromProps={50} />
                    </div>
                  ) : showError ?
                    (
                      <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
                    ) :
                    (
                      <>
                        <div className="2xl:col-span-1 xl:col-span-1 lg:col-span-2">
                          <LandlordEditNav activeNav={activeNav} />
                        </div>
                        <div className="2xl:col-span-4 lg:col-span-3 xl:col-span-2">
                          <PersonalInformationSection
                            landlord={landlord!}
                            activeNav={activeNav}
                            handleChange={handleChange}
                          />
                          <IdentificationSection
                            landlord={landlord!}
                            activeNav={activeNav}
                            handleChange={handleChange}
                          />
                          <AddressInformationSection
                            landlord={landlord!}
                            saving={saving}
                            activeNav={activeNav}
                            handleChange={handleChange}
                            submit={submit}
                            invalidForm={invalidForm}
                          />
                        </div>
                      </>
                    )}
                </div>
                <div
                  id="complete-section"
                  className="text-center h-full flex-col justify-center hidden"
                >
                  <img
                    src="img/others/pending-approval.png"
                    alt=""
                    className="mb-6 mx-auto"
                  />
                  <h4 className="mb-4">Account application proccessing</h4>
                  <p>
                    Your account application is currently under review &amp;
                    will be finalize shortly.
                    <br />
                    To fast track your account proccessing, you can try to
                    contact our customer services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default LandlordEdit;
