import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Alerts from "../../../helpers/Alerts";
import Spinners from "../../../helpers/Spinners";
import { addPropertyExpenseAsync, getPropertiesAsync, selectPropertyList, selectPropertyStatus, selectSelectedProperty, setSelectedProperty, selectPropertiesChartData, selectPropertyAccountOperations, getPropertiesChartDataAsync, getPropertyAccountOperationDataAsync, selectTenants, getTenantsByPropertyIdAsync, selectAccountOperationsStatus, selecttenantStatus } from "../propertySlice";
import { selectAuthUser } from "../../account/user/userSlice";
import { getLandlordsAsync, selectLandlordList } from "../../landlords/landlordSlice";
import PropertyExpenseDialog from "./PropertyExpenseDialog";
import PaymentSummary from "../../commonComponent/PaymentSummary";
import AccountSummary from "../../commonComponent/AccountSummary";
import { selectAuthUserPermissions } from "../../account/user/userSlice";
import ReactApexChart from "react-apexcharts";
import { getTenantsAsync, selectTenantList, setSelectedTenant } from "../../tenant/tenantSlice";
import { search } from "../../../helpers/objectMapper";
import DataTable from "react-data-table-component";
import { getLeasesAsync, selectLeaseList } from "../../leases/leaseSlice";

export function PropertyDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectPropertyStatus);
  const accountOperationsStatus = useAppSelector(selectAccountOperationsStatus);
  const tenantStatus = useAppSelector(selecttenantStatus);
  const properties = useAppSelector(selectPropertyList);
  const landlords = useAppSelector(selectLandlordList);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const propertyChartData = useAppSelector(selectPropertiesChartData);
  const propertyAccountOperations = useAppSelector(selectPropertyAccountOperations);
  const propertyTenants = useAppSelector(selectTenants);
  const currentUser = useAppSelector(selectAuthUser);
  const AuthUserPermissions = useAppSelector(selectAuthUserPermissions);
  const [property, setProperty] = useState<IProperty>();
  const [loading, setLoading] = useState<boolean>(true);
  const [accountOperationLoading, setAccountOperationLoading] = useState<boolean>(true);
  const [tenantLoading, settenantLoading] = useState<boolean>(true);
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [expense, setExpense] = useState<IAddPropertyExpense>();
  const [chartOptions, setChartOptions] = useState<any>({ series: [] });
  const [filterText, setFilterText] = useState<string>("");
  const [OperationfilterText, setOperationFilterText] = useState<string>("");
  const [filteredTenants, setFilteredTenants] = useState<ILease[]>();
  const [filteredAccountOperations, setFilteredAccountOperations] = useState<IAccountOperation[]>();

  useEffect(() => {
    setFilteredTenants(propertyTenants);
  }, [propertyTenants]);

  useEffect(() => {
    setFilteredAccountOperations(propertyAccountOperations);
  }, [propertyAccountOperations]);

  useEffect(() => {
    if (selectedProperty) {
      setProperty(selectedProperty);
      if (propertyChartData == undefined) {
        dispatch(
          getPropertiesChartDataAsync({
            propertyId: id!,
            landlordId: selectedProperty?.landlord?.id!,
          })
        );
      }
      setLoading(false);
    } else if (selectedProperty == null) {
      dispatch(getPropertiesAsync());
    }
  }, []);

  useEffect(() => {
    if (!selectedProperty && properties.length > 0) {
      let value = properties.find((p) => p.id == id);
      setProperty(value);
      dispatch(setSelectedProperty(value!));
    }
    if (landlords && landlords.length === 0) {
      dispatch(getLandlordsAsync());
    }
  }, [properties, landlords]);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(
        getPropertiesChartDataAsync({
          propertyId: id!,
          landlordId: selectedProperty.landlord?.id!,
        })
      );
      dispatch(
        getPropertyAccountOperationDataAsync({
          propertyId: id!,
          landlordId: selectedProperty.landlord?.id!,
        })
      );
      dispatch(getTenantsByPropertyIdAsync(id!));
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (status === "success") {
      setLoading(false);
    }
    if (status === "failed" && loading === true) {
      setShowError(true);
      setLoading(false);
    }
    if (tenantStatus === "success") {
      settenantLoading(false);
    }
    if (accountOperationsStatus === "success") {
      setAccountOperationLoading(false);
    }
  }, [status, tenantStatus, accountOperationsStatus]);

  useEffect(() => {
    if (propertyChartData && chartLoading) {
      getChartOption();
      setChartLoading(false);
    }
  }, [propertyChartData]);

  const getChartOption = () => {
    setChartOptions({
      series: propertyChartData,
      chart: {
        type: "bar",
        height: 425,
      } as ApexChart,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Jul", "Aout", "Sep", "Oct", "Nov", "Dec"],
      },
      yaxis: {
        title: {
          text: "FCFA",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + " FCFA";
          },
        },
      },
    });
  };

  // const userHasPermission = (user: IUser, action: string) => {
  //   const userRoles = user.roles!; // Ensure user has roles
  //   for (const role of userRoles) {
  //     const permissions = role.permissions!;
  //     if (permissions.some(permission => permission.name === action)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const userHasPermission = (action: string) => {
    if (AuthUserPermissions.some((permission) => permission === action)) {
      return true;
    }
    return false;
  };

  const handleChange = (objectKey: string, value: any) => {
    setExpense((prevState: any) => {
      return { ...prevState, [objectKey]: value };
    });
  };

  const handleAddExpense = (expense: IAddPropertyExpense) => {
    dispatch(addPropertyExpenseAsync(expense!));
  };

  const initializePayment = () => {
    setExpense({
      amount: 0,
      comments: "",
      paymentMethod: "",
      propertyId: "",
      landlordId: "",
      unitId: "",
      date: "",
      categories: [],
    });
  };

  const getPaymentMethodDescription = (method: string) => {
    switch (method) {
      case "Cash":
        return "Comptant";
      case "OrangeMoney":
        return "Orange Money";
      case "MoovMoney":
        return "Moov Money";
      case "Wave":
        return "Wave";
      case "SamaMoney":
        return "Sama Money";
      case "Cheque":
        return "Chèque";
      default:
        return "";
    }
  };

  const getOperationTypeDescription = (type: any) => {
    return type === 0 ? "Crédit" : "Débit";
  };

  const columns = useMemo(
    () => [
      {
        name: "Prénom",
        selector: (row: any) => row.firstName,
        sortable: true,
      },
      {
        name: "Nom",
        selector: (row: any) => row.lastName,
        sortable: true,
      },
      {
        name: "Téléphone",
        selector: (row: any) => row.phoneNumber1,
        sortable: true,
      },
      {
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        cell: (row: any) => (
          <div className="flex justify-end text-lg">
            {userHasPermission("UpdateTenant") && (
              <span className="cursor-pointer p-2 hover:text-indigo-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  width="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  aria-hidden="true"
                  strokeWidth="2"
                  onClick={() => {
                    dispatch(setSelectedTenant(row!));
                    navigate(`/tenants/details/${row.id}`);
                  }}
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </span>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const operationColumns = useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) =>
          new Date(row.date).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        sortable: true,
      },
      {
        name: "Mode de paiement",
        selector: (row: any) => row.paymentMethod,
        sortable: true,
      },
      {
        name: "Type",
        selector: (row: any) => getOperationTypeDescription(row.type),
        sortable: true,
      },
      {
        name: "Montant",
        selector: (row: any) => new Intl.NumberFormat("fr-FR").format(row.amount),
        sortable: true,
      },
      {
        name: "Commentaires",
        selector: (row: any) => row.comments,
        sortable: true,
      },
    ],
    []
  );

  const OperationDynamicSearch = (terme: any) => {
    setOperationFilterText(terme);
    let result = search(propertyAccountOperations!, String(terme).toLocaleLowerCase());
    setFilteredAccountOperations(result as IAccountOperation[]);
    console.log("result", result);
  };

  const dynamicSearch = (terme: any) => {
    setFilterText(terme);
    let result = search(propertyTenants!, String(terme).toLocaleLowerCase());
    setFilteredTenants(result as ITenant[]);
    console.log("result", result);
  };

  return (
    <>
      <main className="h-full">
        <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6 md:px-8 py-4 sm:py-6">
          <div className="flex flex-col gap-4 h-full">
            <div className="lg:flex items-center justify-between mb-4 gap-3">
              <div className="mb-4 lg:mb-0">
                <h3>Détail du Bien - {property?.name}</h3>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <PaymentSummary
                payment={{
                  amount: property?.units?.filter((u) => u.isOccupied === false).length,
                  title: "Unités disponibles",
                }}
              />
              <PaymentSummary
                payment={{
                  amount: property?.units?.filter((u) => u.isOccupied === true).length,
                  title: "Unités occupés",
                }}
              />
              <PaymentSummary
                payment={{
                  amount: property?.units?.length,
                  title: "Unités total",
                }}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <AccountSummary person={property} propertyName={"properties"} deleteTarget={"delete-proporty"} />
              <div className="card card-layout-frame col-span-2">
                <div className="card-body">
                  <div className="flex items-center justify-between">
                    <h4>Rapport des opérations</h4>
                    {/* <button className="btn btn-sm btn-default">Export Report</button> */}
                    <div>
                      <select className="input input-md">
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <ReactApexChart options={chartOptions} series={chartOptions?.series!} type="bar" height={425} />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div className="card card-layout-frame col-span-2">
                <div className="card-body">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                      <h4>Liste des locataires</h4>
                    </div>
                    {/* Section de filtre */}
                    <div className="flex flex-col lg:flex-row lg:items-center justify-end gap-3">
                      <input id="date-query-input" className="input input-sm pr-8" placeholder="Rechercher..." value={filterText} onChange={(e: any) => dynamicSearch(e.target.value)} />
                    </div>
                  </div>
                  <br />
                  <div className="overflow-x-auto">
                    {loading ? (
                      <>
                        <div className="lg:flex items-center justify-center">
                          <Spinners sizeFromProps={50} />
                        </div>
                      </>
                    ) : showError ? (
                      <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
                    ) : (
                      <>
                        <div className="overflow-x-auto">
                          <DataTable columns={columns} data={filteredTenants!} striped={true} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div className="card card-layout-frame col-span-2">
                <div className="card-body">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                      <h4>Liste des opérations</h4>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:items-center justify-end gap-3">
                      <input id="date-query-input" className="input input-sm pr-8" placeholder="Rechercher..." value={OperationfilterText} onChange={(e: any) => OperationDynamicSearch(e.target.value)} />
                    </div>
                  </div>
                  <br />

                  <div className="overflow-x-auto">
                    {loading ? (
                      <>
                        <div className="lg:flex items-center justify-center">
                          <Spinners sizeFromProps={50} />
                        </div>
                      </>
                    ) : showError ? (
                      <Alerts type={"alert-danger"} message={"Erreur lors de cette opération. Veuillez reessayer!!!"} />
                    ) : (
                      <>
                        {/* Section de filtre */}
                        <div className="lg:flex items-center justify-end mb-4 gap-3"></div>
                        <div className="overflow-x-auto">
                          <DataTable columns={operationColumns} data={filteredAccountOperations!} striped={true} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PropertyExpenseDialog property={property!} expense={expense} dialogId={"addPayment"} title={"Faire un versement au bailleur"} handleChange={handleChange} initializePayment={initializePayment} handleAddExpense={handleAddExpense} />
      </main>
    </>
  );
}
