import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import {
  addNewItemToObjectArray,
  removeItemFromObjectArray,
  sortedArray,
  updateArrayByItemId,
} from "../../helpers/objectMapper";
import {
  createProperty,
  deleteProperty,
  getProperties,
  getPropertiesChartData,
  addUnite,
  deleteUnit,
  updateProperty,
  updateUnite,
  addPropertyExpense,
  getPropertyAccountOperations,
  getTenantsByPropertyId,
} from "./propertyAPI";

export interface PropertyState {
  propertyList: IProperty[];
  status: "pending" | "success" | "failed" | "";
  accountOperationsStatus: "pending" | "success" | "failed" | "";
  tenantStatus: "pending" | "success" | "failed" | "";
  selectedProperty: IProperty | null;
  propertyChartData?: IChartData;
  accountOperations?: IAccountOperation[];
  tenants?: ITenant[];
}

const initialState: PropertyState = {
  propertyList: [],
  status: "",
  tenantStatus: "",
  accountOperationsStatus: "",
  selectedProperty: null,
  propertyChartData: undefined,
  accountOperations: [],
  tenants: []
};

export const getPropertiesAsync = createAsyncThunk(
  "property/getProperties",
  async () => {
    const response = await getProperties();
    return response.data;
  }
);

export const createPropertyAsync = createAsyncThunk(
  "property/create",
  async (data: IProperty) => {
    const response = await createProperty(data);
    return response.data;
  }
);

export const updatePropertyAsync = createAsyncThunk(
  "landlord/update",
  async (data: IProperty) => {
    const response = await updateProperty(data);
    return response.data;
  }
);

export const deletePropertyAsync = createAsyncThunk(
  "property/delete",
  async (propertyId: string) => {
    const response = await deleteProperty(propertyId);
    return response.data;
  }
);

export const addUnitAsync = createAsyncThunk(
  "property/addUnit",
  async ({ data, propertyId }: { data: IUnity; propertyId: string }) => {
    const response = await addUnite(data, propertyId);
    return response.data;
  }
);

export const updateUnitAsync = createAsyncThunk(
  "property/updateUnit",
  async ({ data, propertyId }: { data: any; propertyId: string }) => {
    const response = await updateUnite(data, propertyId);
    return response.data;
  }
);

export const deleteUnitAsync = createAsyncThunk(
  "property/deleteUnit",
  async ({ propertyId, unitId }: { propertyId: string; unitId: string }) => {
    const response = await deleteUnit(propertyId, unitId);
    return response.data;
  }
);

export const addPropertyExpenseAsync = createAsyncThunk(
  "property/account/addPropertyExpense",
  async (data: IAddPropertyExpense) => {
    const response = await addPropertyExpense(data);
    return response.data;
  }
);

export const getPropertiesChartDataAsync = createAsyncThunk("property/getPropertyChartData", async ({ propertyId, landlordId }: { propertyId: string, landlordId: string }) => {
  const response = await getPropertiesChartData(propertyId, landlordId);
  return response.data;
});

export const getPropertyAccountOperationDataAsync = createAsyncThunk("property/getPropertyAccountOperations", async ({ propertyId, landlordId }: { propertyId: string, landlordId: string }) => {
  const response = await getPropertyAccountOperations(propertyId, landlordId);
  return response.data;
});

export const getTenantsByPropertyIdAsync = createAsyncThunk("property/getTenantsByPropertyId", async (propertyId: string) => {
  const response = await getTenantsByPropertyId(propertyId);
  return response.data;
});


export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    initializeActionStatus: (state) => {
      state.status = "";
    },
    setSelectedProperty: (state, action: PayloadAction<IProperty>) => {
      state.selectedProperty = action.payload;
    },
    setPropertyChartData: (state, action: PayloadAction<IChartData | undefined>) => {
      state.propertyChartData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /*************  Properties  **************/
      .addCase(getPropertiesAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getPropertiesAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.propertyList = sortedArray(action.payload) as IProperty[];
      })
      .addCase(getPropertiesAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createPropertyAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createPropertyAsync.fulfilled, (state, action) => {
        state.propertyList = updateArrayByItemId(
          [...state.propertyList],
          action.payload
        ) as IProperty[];
        state.status = "success";
      })
      .addCase(createPropertyAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updatePropertyAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updatePropertyAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.propertyList = updateArrayByItemId(
          [...state.propertyList],
          action.payload
        ) as IProperty[];
      })
      .addCase(updatePropertyAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deletePropertyAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(deletePropertyAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.propertyList = removeItemFromObjectArray(
          [...state.propertyList],
          action.payload
        ) as IProperty[];
      })
      .addCase(deletePropertyAsync.rejected, (state) => {
        state.status = "failed";
      })
      /*************  Units  **************/
      .addCase(addUnitAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(addUnitAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.propertyList = updateArrayByItemId(
          [...state.propertyList],
          action.payload
        ) as IProperty[];
      })
      .addCase(addUnitAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateUnitAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateUnitAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.propertyList = updateArrayByItemId(
          [...state.propertyList],
          action.payload
        ) as IProperty[];
      })
      .addCase(updateUnitAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteUnitAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(deleteUnitAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.propertyList = updateArrayByItemId(
          [...state.propertyList],
          action.payload
        ) as IProperty[];
      })
      .addCase(deleteUnitAsync.rejected, (state) => {
        state.status = "failed";
      })
      /*************  Expense  **************/
      .addCase(addPropertyExpenseAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(addPropertyExpenseAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.propertyList = updateArrayByItemId(
          [...state.propertyList],
          action.payload
        ) as IProperty[];
        state.selectedProperty = action.payload;
      })
      .addCase(addPropertyExpenseAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getPropertiesChartDataAsync.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getPropertiesChartDataAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.propertyChartData = action.payload;
      })
      .addCase(getPropertiesChartDataAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getPropertyAccountOperationDataAsync.pending, (state) => {
        state.accountOperationsStatus = "pending";
      })
      .addCase(getPropertyAccountOperationDataAsync.fulfilled, (state, action) => {
        state.accountOperationsStatus = "success";
        state.accountOperations = action.payload;
      })
      .addCase(getPropertyAccountOperationDataAsync.rejected, (state) => {
        state.accountOperationsStatus = "failed";
      })
      .addCase(getTenantsByPropertyIdAsync.pending, (state) => {
        state.tenantStatus = "pending";
      })
      .addCase(getTenantsByPropertyIdAsync.fulfilled, (state, action) => {
        state.tenantStatus = "success";
        state.tenants = action.payload;
      })
      .addCase(getTenantsByPropertyIdAsync.rejected, (state) => {
        state.tenantStatus = "failed";
      });
  },
});

export const { initializeActionStatus, setSelectedProperty, setPropertyChartData } = propertySlice.actions;

export const selectPropertyList = (state: RootState) => state.property.propertyList;
export const selectSelectedProperty = (state: RootState) => state.property.selectedProperty;
export const selectPropertiesChartData = (state: RootState) => state.property.propertyChartData;
export const selectPropertyAccountOperations = (state: RootState) => state.property.accountOperations;
export const selectTenants = (state: RootState) => state.property.tenants;
export const selectPropertyStatus = (state: RootState) => state.property.status;
export const selectAccountOperationsStatus = (state: RootState) => state.property.accountOperationsStatus;
export const selecttenantStatus = (state: RootState) => state.property.tenantStatus;

export default propertySlice.reducer;
