import React, { useEffect, useState } from 'react';
import Spinners from '../../../helpers/Spinners';


interface AddressInformationSectionProps {
    tenant: ITenant;
    saving: boolean;
    activeNav?: (itemId: string, sectionId: string) => void;
    handleChange: (objectKey: string, value: any) => void;
    submit: () => void;
    invalidForm: () => void;
}


const AddressInformationSection: React.FC<AddressInformationSectionProps> = (props) => {

    const {
        tenant,
        saving,
        handleChange,
        activeNav,
        submit,
        invalidForm
    } = props;

    return (
        <>
            <div id="address-section" className="content-section hidden">
                <div className="mb-8">
                    <h3 className="mb-2">Address Information</h3>
                    <p>Enter your address information help us to speed up the verication process.</p>
                </div>
                <form action="#">
                    <div className="form-container vertical">
                        <h5 className="mb-4">Permanent Address</h5>
                        <div className="md:grid grid-cols-2 gap-4">
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Adresse</label>
                                <div>
                                    <div className="input-group">
                                        <input
                                            className="input"
                                            type="text"
                                            name="addressLine"
                                            placeholder="Adresse"
                                            value={tenant?.addressLine}
                                            onChange={(e) => handleChange('addressLine', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Commune</label>
                                <div>
                                    <div className="input-group">
                                        <input
                                            className="input"
                                            type="text"
                                            name="town"
                                            placeholder="Commune"
                                            value={tenant?.town}
                                            onChange={(e) => handleChange('town', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:grid grid-cols-2 gap-4">
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Ville</label>
                                <div>
                                    <div className="input-group">
                                        <input
                                            className="input"
                                            type="text"
                                            name="city"
                                            placeholder="Ville"
                                            value={tenant?.city}
                                            onChange={(e) => handleChange('city', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-item vertical">
                                <label className="form-label mb-2">Pays</label>
                                <div>
                                    <div className="input-group">
                                        <input
                                            className="input"
                                            type="text"
                                            name="country"
                                            placeholder="Pays"
                                            value={tenant?.country}
                                            onChange={(e) => handleChange('country', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="flex justify-end gap-2">
                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={(handleChange) => activeNav!('menu-item-1', 'identification-section')}
                            >
                                Précédent
                            </button>
                            <button
                                className="btn btn-solid"
                                type="button"
                                onClick={submit}
                                disabled={invalidForm()!}
                            >
                                <span className="flex items-center justify-center">
                                    Sauvegarder
                                    {saving && (
                                        <Spinners sizeFromProps={30} />
                                    )}
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddressInformationSection;