import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import userReducer from "../features/account/user/userSlice";
import roleReducer from "../features/account/role/roleSlice";
import tenantReducer from "../features/tenant/tenantSlice";
import landlordReducer from "../features/landlords/landlordSlice";
import propertyReducer from "../features/properties/propertySlice";
import leaseReducer from "../features/leases/leaseSlice";
import accountReducer from "../features/accountOperation/accountOperationSlice";
import homeReducer from "../features/home/homeSlice";

export const store = configureStore({
  reducer: {
    role: roleReducer,
    user: userReducer,
    tenant: tenantReducer,
    landlord: landlordReducer,
    property: propertyReducer,
    lease: leaseReducer,
    counter: counterReducer,
    account: accountReducer,
    home: homeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
