import { rejects } from "assert";
import axios from "axios";
import { api } from "../../../services/apiHelpers";

const axiosHeadersConfig = () => {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  if (user.accessToken) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + user.accessToken;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export function getUsers() {
  axiosHeadersConfig();
  return new Promise<{ data: IUser[]; status: number }>((resolve, reject) => {
    axios
      .get(api + "/Users/list")
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function signin(data: ISigninData) {
  return new Promise<{ data: IUser; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Users/signin", data)
      .then((res: any) => {
        if (res.data && res.data !== "") {
          localStorage.setItem("user", JSON.stringify(res.data));
          resolve({ data: res.data, status: res.status });
        }
        if (res.status === 204) {
          reject({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}

export function createUser(data: ICreateOrUpdateUserData) {
  return new Promise<{ data: IUser; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Users/create", data)
      .then((res: any) => {
        if (res.data) {
          // let test: new IUser;
          // console.log('roles', roles);
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function updateUser(data: ICreateOrUpdateUserData) {
  return new Promise<{ data: IUser[]; status: number }>((resolve, reject) => {
    axios
      .post(api + "/Users/update", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function deleteUser(userId: string) {
  return new Promise<{ data: IUser[]; status: number }>((resolve, reject) => {
    axios
      .delete(api + `/Users/${userId}`)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function makeDeposit(data: IMakeDeposit) {
  return new Promise<{ data: IUser; status: number }>((resolve, reject) => {
    axios
      .post(api + "/users/makeDeposit", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}

export function getUserChartData(id: string) {
  axiosHeadersConfig();
  return new Promise<{ data: IChartData; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Users/charts/accountOperations/${id}`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function getUserAccountOperations(id: string) {
  axiosHeadersConfig();
  return new Promise<{ data: IAccountOperation[]; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Users/accountOperations/${id}`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}