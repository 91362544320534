import { rejects } from "assert";
import axios from "axios";
import { api } from "../../services/apiHelpers";

const axiosHeadersConfig = () => {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  if (user.accessToken) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + user.accessToken;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export function getProperties() {
  axiosHeadersConfig();
  return new Promise<{ data: IProperty[]; status: number }>((resolve) => {
    axios
      .get(api + "/Properties/list")
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}

export function createProperty(data: IProperty) {
  return new Promise<{ data: IProperty; status: number }>((resolve) => {
    axios
      .post(api + "/Properties/create", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}
export function updateProperty(data: IProperty) {
  return new Promise<{ data: IProperty; status: number }>((resolve) => {
    axios
      .post(api + "/Properties/update", data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}

export function deleteProperty(propertyId: string) {
  return new Promise<{ data: string; status: number }>((resolve) => {
    axios
      .delete(api + `/Properties/${propertyId}`)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: propertyId, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}

/********************  Units  ************************ */

export function addUnite(data: IUnity, propertyId: string) {
  return new Promise<{ data: IProperty; status: number }>((resolve) => {
    axios
      .post(api + `/Properties/${propertyId}/units/add`, data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}

export function updateUnite(data: IUnity, propertyId: string) {
  return new Promise<{ data: IProperty; status: number }>((resolve) => {
    axios
      .post(api + `/Properties/${propertyId}/units/update`, data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}

export function deleteUnit(propertyId: string, unitId: string) {
  return new Promise<{ data: IProperty; status: number }>((resolve) => {
    axios
      .delete(api + `/Properties/${propertyId}/units/${unitId}`)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}

export function addPropertyExpense(data: IAddPropertyExpense) {
  return new Promise<{ data: IProperty; status: number }>((resolve) => {
    axios
      .post(api + `/Accounting/property/expenses/add`, data)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  });
}

export function getPropertiesChartData(id: string, landlordId: string) {
  axiosHeadersConfig();
  return new Promise<{ data: IChartData; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Properties/charts/accountOperations/${landlordId}/${id}`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function getPropertyAccountOperations(id: string, landlordId: string) {
  axiosHeadersConfig();
  return new Promise<{ data: IAccountOperation[]; status: number }>(
    (resolve, reject) => {
      axios
        .get(api + `/Properties/accountOperations/${landlordId}/${id}`)
        .then((res: any) => {
          if (res.data) {
            resolve({ data: res.data, status: res.status });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          reject(error);
        });
    }
  );
}

export function getTenantsByPropertyId(propertyId: string) {
  axiosHeadersConfig();
  return new Promise<{ data: ITenant[]; status: number }>((resolve, reject) => {
    axios
      .get(api + `/Properties/tenants/list/${propertyId}`)
      .then((res: any) => {
        if (res.data) {
          resolve({ data: res.data, status: res.status });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        reject(error);
      });
  });
}